import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { buildTopReasonsData } from './helpers'
import { StyledListItem, StyledTopReasonsTeasableItem } from './TopReasons.style'

export interface TopReasonItem {
  id?: string
  icon: JSX.Element | null
  content: ReactNode | null
}

export interface TopReasonsData {
  title: string
  items: TopReasonItem[]
}

export interface TopReasonsProps {
  /** Custom rendering for the title */
  renderTitle?: (defaultHeader: string) => ReactNode

  /** Custom rendering for the wrapper of the list items */
  renderItemsWrapper?: (children: ReactNode) => ReactNode

  /** Custom rendering for individual items */
  renderItem?: (item: TopReasonItem, index: number) => ReactNode

  /** The data for top reasons, normalized */
  topReasonsCMCollection: ICMCollection | null
}

const TopReasons = ({
  renderTitle,
  renderItemsWrapper,
  renderItem,
  topReasonsCMCollection,
  ...rest
}: TopReasonsProps) => {
  if (!topReasonsCMCollection) return null

  const { title, items } = buildTopReasonsData(topReasonsCMCollection)

  const defaultRenderItem = (item: TopReasonItem) => (
    <StyledListItem key={item.id} disablePadding>
      {item.icon}
      <StyledTopReasonsTeasableItem>{item.content}</StyledTopReasonsTeasableItem>
    </StyledListItem>
  )

  const defaultRenderItemsWrapper = (children: ReactNode) => <List>{children}</List>

  return (
    <Box component="div" {...rest}>
      {renderTitle ? renderTitle(title) : <Typography variant="subtitle2">{title}</Typography>}
      {renderItemsWrapper
        ? renderItemsWrapper(items.map((item, i) => (renderItem ? renderItem(item, i) : defaultRenderItem(item))))
        : defaultRenderItemsWrapper(
            items.map((item, i) => (renderItem ? renderItem(item, i) : defaultRenderItem(item)))
          )}
    </Box>
  )
}

export default TopReasons

import React from 'react'
import IconWrapper from '../IconWrapper'
import type { CustomSVGIconProps } from '../icon.type'

export const CheckmarkFilledIcon: React.FC<CustomSVGIconProps> = ({
  primaryFill = 'none',
  secondaryFill = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <circle cx={8.069} cy={8} r={8} fill={primaryFill} />
      <path d="M6.535 12.009 2.37 8.12l.737-.689 3.428 3.2 6.45-6.04.747.679-7.197 6.738z" fill={secondaryFill} />
    </IconWrapper>
  )
}

export const CheckmarkIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 24, height = 24, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="m16.505 9.28-5 6c-.184.222-.768.86-.768.86s-.542-.611-.737-.824l-3-3.273 1.474-1.351 2.227 2.43L14.97 8l1.536 1.28z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CheckmarkRoundedIcon: React.FC<CustomSVGIconProps> = ({
  primaryFill = 'none',
  htmlColor = 'none',
  width = 8,
  height = 8,
  ...rest
}) => (
  <IconWrapper
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill={htmlColor}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      stroke={primaryFill}
      strokeWidth="9"
      d="M7.333 9.057 5.471 7.195l-.942.943 2.804 2.805 4.472-4.472-.943-.942-3.529 3.528z"
      fill="none"
    />
    <path d="M7.333 9.057 5.471 7.195l-.942.943 2.804 2.805 4.472-4.472-.943-.942-3.529 3.528z" fill={htmlColor} />
    <path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
      fill={htmlColor}
    />
  </IconWrapper>
)

export const CheckmarkRoundedValidIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 8,
  height = 8,
  ...rest
}) => (
  <IconWrapper
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill={htmlColor}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M7.333 9.057 5.471 7.195l-.942.943 2.804 2.805 4.472-4.472-.943-.942-3.529 3.528z" fill={htmlColor} />
    <path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
      fill={htmlColor}
    />
  </IconWrapper>
)

export const CheckmarkRoundedErrorIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 8,
  height = 8,
  ...rest
}) => (
  <IconWrapper
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill={htmlColor}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
      fill={htmlColor}
    />
    <path
      d="M10.195 4.861 8 7.057 5.805 4.86l-.943.943 2.195 2.195-2.195 2.196.943.942L8 8.942l2.195 2.195.943-.942-2.195-2.196 2.195-2.195-.943-.943z"
      fill={htmlColor}
    />
  </IconWrapper>
)

export const IndeterminateCheckBoxIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path fill={htmlColor} d="M8 11h8v2H8z" />
    </IconWrapper>
  )
}

export const CheckedRadioWithBorderIcon: React.FC<CustomSVGIconProps> = ({
  fill = '#000',
  width = 16,
  height = 16,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none">
      <path d="M4 12a8 8 0 1 1 16 0 8 8 0 1 1-16 0z" stroke={fill} />
      <circle cx="12" cy="12" r="5" fill={fill} />
    </svg>
  )
}

export const CheckmarkOutlinedIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <g clipPath="url(#clip0_4653_201)">
        <path
          d="M24 0C10.767 0 0 10.767 0 24C0 37.233 10.767 48 24 48C37.233 48 48 37.233 48 24C48 10.767 37.233 0 24 0ZM24 46C11.869 46 2 36.131 2 24C2 11.869 11.869 2 24 2C36.131 2 46 11.869 46 24C46 36.131 36.131 46 24 46Z"
          fill={htmlColor}
        />
        <path
          d="M20.44 30.586L11.2 21.346L9.786 22.76L20.438 33.414L38.183 15.708L36.77 14.292L20.44 30.586Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_201">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const TickMarkIcon: React.FC<CustomSVGIconProps> = ({ fill = '#0137EF', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.09621 15.9988C4.94519 15.9988 4.80395 15.9249 4.71812 15.8L0.147447 9.15744C0.00403607 8.94884 0.0561855 8.66311 0.264783 8.5197C0.473381 8.37629 0.759116 8.42844 0.902527 8.63703L5.09621 14.7309L15.0969 0.198609C15.2404 -0.00998899 15.5261 -0.063225 15.7347 0.0812723C15.9433 0.224683 15.9965 0.510419 15.852 0.719016L5.47321 15.8011C5.38738 15.9249 5.24614 15.9999 5.09512 15.9999L5.09621 15.9988Z"
        fill={fill}
      />
    </svg>
  )
}

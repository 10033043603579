import { PRODUCT_TYPES_MAP } from '@constants/product'
import { IOrderItem } from '@typesApp/order'
import { IProduct, IProductAttribute, IProductAttributes } from '@typesApp/product'

import { TFunction } from 'next-i18next'

import { IBadges } from '../redux/reducers'
import { pickBy } from './helpers'
import Log from '@services/Log'

export const PRODUCT_ATTRIBUTE_TRUE_VALUE = 'True' as const

export interface ProductBadges {
  isNew: boolean
  isBadge: boolean
  isLimitedEdition: boolean
  isExclusive: boolean
  isAvantPremiere: boolean
  isOnlineExclusive: boolean
  isSustainable: boolean
  isCustomizable: boolean
  isPolarized: boolean
  isMostPopular: boolean
  isRoxable: boolean
}

//TODO insert more attr as needed to prevent magic strings
export const PRODUCT_ATTRIBUTES_IDENTIFIERS = {
  CL_BADGE: 'CL_BADGE',
  CL_ANNUAL_SUPPLY_BADGES: 'CL_ANNUAL_SUPPLY_BADGES',
  CL_COLOR_RANGE: 'CL_COLOR_RANGE',
  CL_CORRECTION_TYPE: 'CL_CORRECTION_TYPE',
  CL_PACKAGE_DETAILS: 'CL_PACKAGE_DETAILS',
  CL_MATERIAL_PATENTED: 'CL_MATERIAL_PATENTED',
  CL_BASE_CURVE: 'CL_BASE_CURVE',
  CL_BRAND: 'CL_BRAND',
  LX_CANONICAL_CATEGORY: 'LX_CANONICAL_CATEGORY',
  CL_DOMINANCE: 'CL_DOMINANCE',
  CL_MATERIAL_SEG_FACET: 'CL_MATERIAL_SEG_FACET',
  CL_DIAMETER: 'CL_DIAMETER',
  CL_MATERIAL_SEG: 'CL_MATERIAL_SEG',
  CL_AXIS_MAX: 'CL_AXIS_MAX',
  CL_AXIS_STEPS: 'CL_AXIS_STEPS',
  CL_PHOTOCROMIC: 'CL_PHOTOCROMIC',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  CL_SPHERE_POWER: 'CL_SPHERE_POWER',
  CL_PACK_SIZE_FACET: 'CL_PACK_SIZE_FACET',
  CL_SOLUTION_CONTENT: 'CL_SOLUTION_CONTENT',
  CL_MODALITY_FACET: 'CL_MODALITY_FACET',
  CL_MODALITY: 'CL_MODALITY',
  CL_PACK_SIZE: 'CL_PACK_SIZE',
  CL_MONTH_EQUIV: 'CL_MONTH_EQUIV',
  CL_SPHERE_MAX: 'CL_SPHERE_MAX',
  CL_SUPPLY_DURATION: 'CL_SUPPLY_DURATION',
  CL_SPHERE_STEPS: 'CL_SPHERE_STEPS',
  LX_SEARCH_PAGE_PRODUCT_TYPE: 'LX_SEARCH_PAGE_PRODUCT_TYPE',
  CL_ADD_STEPS: 'CL_ADD_STEPS',
  CL_ADD_MIN: 'CL_ADD_MIN',
  CL_UV_PROTRECTION: 'CL_UV_PROTRECTION',
  CL_SPHERE_MIN: 'CL_SPHERE_MIN',
  CL_LENS_RANGE: 'CL_LENS_RANGE',
  CL_SUPPLIER: 'CL_SUPPLIER',
  CL_GAS_PERMEABILITY: 'CL_GAS_PERMEABILITY',
  CL_BRAND_LINE: 'CL_BRAND_LINE',
  CL_AXIS_MIN: 'CL_AXIS_MIN',
  CL_BLU_PROTECTION: 'CL_BLU_PROTECTION',
  CL_HD: 'CL_HD',
  CL_CYL_MIN: 'CL_CYL_MIN',
  CL_ADD_MAX: 'CL_ADD_MAX',
  CL_WATER_CONTENT: 'CL_WATER_CONTENT',
  CL_BRAND_FAMILY: 'CL_BRAND_FAMILY',
  CL_CYL_MAX: 'CL_CYL_MAX',
  CL_CYL_STEPS: 'CL_CYL_STEPS',
  MODEL_NAME: 'MODEL_NAME',
  CL_PRESERVATIVES: 'CL_PRESERVATIVES',
  CL_PRODUCT_CATEGORY: 'CL_PRODUCT_CATEGORY',
  CL_LENSES_CASES: 'CL_LENSES_CASES',
  CL_VOLUME: 'CL_VOLUME',
  LX_EXCLUDE_FROM_SEARCH: 'LX_EXCLUDE_FROM_SEARCH',
  LX_SOLDOUT: 'LX_SOLDOUT',
  CL_IS_TRAVEL: 'CL_IS_TRAVEL',
  CL_USAGE: 'CL_USAGE',
  CL_PRODUCT_SUB_CATEGORY: 'CL_PRODUCT_SUB_CATEGORY',
  CL_CYLINDER_RANGE: 'CL_CYLINDER_RANGE',
  CL_AXIS_RANGE: 'CL_AXIS_RANGE',
  CL_ADDITION_RANGE: 'CL_ADDITION_RANGE',
  CL_COLOR: 'CL_COLOR',
  FRAME_SHAPE: 'FRAME_SHAPE',
  FRAME_MATERIAL_CLASS: 'FRAME_MATERIAL_CLASS',
  FRAME_MATERIAL_FACET: 'FRAME_MATERIAL_FACET',
  TEMPLE_COLOR_FACET: 'TEMPLE_COLOR_FACET',
  COLOR_CODE: 'COLOR_CODE',
  POLARIZED: 'POLARIZED',
  LX_IS_MOST_POPULAR: 'LX_IS_MOST_POPULAR',
  ROXABLE: 'ROXABLE',
  MODEL_CODE_DISPLAY: 'MODEL_CODE_DISPLAY',
  SUSTAINABILITY_CLAIM: 'SUSTAINABILITY_CLAIM',
  CUSTOMIZABLE: 'CUSTOMIZABLE',
  WEB_EXCLUSIVE: 'WEB EXCLUSIVE',
  EXCLUSIVE: 'EXCLUSIVE',
  AVANT_PREMIERE: 'AVANT_PREMIERE',
  LIMITED_EDITION: 'LIMITED_EDITION',
  IS_NEW: 'IS_NEW',
  FRONT_COLOR: 'FRONT_COLOR',
  LENS_COLOR: 'LENS_COLOR',
  LENS_COLOR_FACET: 'LENS_COLOR_FACET',
  TEMPLE_COLOR: 'TEMPLE_COLOR',
  LENS_TREATMENT_FACET: 'LENS_TREATMENT_FACET',
  LENS_MATERIAL_MACRO_CLASS: 'LENS_MATERIAL_MACRO_CLASS',
  INCLUDED_ITEMS: 'INCLUDED_ITEMS',
  BADGE: 'BADGE',
  INCLUDED_ITEMS_IMAGE_URL: 'INCLUDED_ITEMS_IMAGE_URL',
  FRAME_SIZE_DISPLAY: 'FRAME_SIZE_DISPLAY',
  FRAME_SIZE: 'FRAME_SIZE',
  DISPLAYSKU: 'DISPLAYSKU',
  BRAND: 'BRAND',
  LENS_WIDTH: 'LENS_WIDTH',
} as const

export const getAllProductAttributes = (attributes: IProductAttribute[] = []): IProductAttributes => {
  return attributes.reduce((acc: IProductAttributes, attr: IProductAttribute) => {
    const value = attr?.values?.[0]?.value || ''
    return {
      ...acc,
      [attr.identifier]: value,
    }
  }, {})
}

const getPriorityBadgeText = (badges: ProductBadges, t: TFunction): string => {
  switch (true) {
    case badges.isExclusive:
      return t('ProductTile.Labels.exclusive')
    case badges.isOnlineExclusive:
      return t('ProductTile.Labels.onlineExclusive')
    case badges.isAvantPremiere:
      return t('ProductTile.Labels.avantPremiere')
    case badges.isNew:
      return t('ProductTile.Labels.new')
    case badges.isMostPopular:
      return t('ProductTile.Labels.mostPopular')
    default:
      return ''
  }
}

//GETTERS

export const getProductType = (p: IProduct | IOrderItem) => {
  return p?.productAttributes['PRODUCT_TYPE']
}

export const getModelName = (p: IProduct | IOrderItem) => {
  return p.productAttributes['MODEL_NAME']
}

export const getBrand = (p: IProduct | IOrderItem) => {
  return p.productAttributes['BRAND']
}

export const getModelCode = (p: IProduct | IOrderItem) => {
  return p.productAttributes['DISPLAYSKU']
}

export const getModelCodeDisplay = (p: IProduct) => {
  return p.productAttributes['MODEL_CODE_DISPLAY']
}

export const getBadge = (p: IProduct) => {
  return p.productAttributes['BADGE']
}

export const getIsAvantPremiere = (p: IProduct) => {
  return p.productAttributes['AVANT_PREMIERE']
}

export const getIsCustomizable = (p: IProduct) => {
  return p.productAttributes['CUSTOMIZABLE']
}

export const getIsExclusive = (p: IProduct) => {
  return p.productAttributes['EXCLUSIVE']
}

export const getIsLimitedEdition = (p: IProduct) => {
  return p.productAttributes['LIMITED_EDITION']
}

export const getIsOnlineExclusive = (p: IProduct) => {
  return p.productAttributes['WEB EXCLUSIVE']
}

export const getIsPolarized = (p: IProduct) => {
  return p.productAttributes['POLARIZED']
}

export const getIsGeoFit = (p: IProduct) => {
  return p.productAttributes['GEOFIT']
}

export const getIsPhotochromic = (p: IProduct) => {
  return p.productAttributes['PHOTOCHROMIC']
}

export const getIsSustainable = (p: IProduct) => {
  return p.productAttributes['SUSTAINABILITY_CLAIM']
}

export const getNew = (p: IProduct) => {
  return p.productAttributes['IS_NEW']
}

export const getIsRoxable = (p: Pick<IProduct, 'productAttributes'>) => {
  return p.productAttributes['ROXABLE'] === PRODUCT_ATTRIBUTE_TRUE_VALUE
}

export const getFrameMaterial = (p: IProduct | IOrderItem) => {
  return p.productAttributes['FRAME_MATERIAL_CLASS']
}

export const getFrameShape = (p: IProduct) => {
  return p.productAttributes['FRAME_SHAPE']
}

export const getFrameType = (p: IProduct) => {
  return p.productAttributes['FRAME_TYPE']
}

export const getLensesColor = (p: IProduct | IOrderItem) => {
  return p.productAttributes['LENS_COLOR']
}

export const getFrontColor = (p: IProduct | IOrderItem) => {
  return p.productAttributes['FRONT_COLOR']
}

export const getLensesTreatment = (p: IProduct) => {
  return p.productAttributes['LENS_TREATMENT_FACET']
}

export const getSoldOut = (p: IProduct) => {
  return p.productAttributes['LX_SOLDOUT']
}

export const getSearchPageProductType = (p: IProduct) => {
  return p.productAttributes['LX_SEARCH_PAGE_PRODUCT_TYPE']
}

export const getClVolume = (p: IProduct) => {
  return p?.productAttributes['CL_VOLUME']
}

export const getClPackSize = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_PACK_SIZE']
}
export const getClMaterialPatented = (p: IProduct) => {
  return p.productAttributes['CL_MATERIAL_PATENTED']
}

export const getLensesColorFacet = (p: IProduct) => {
  return p.productAttributes['LENS_COLOR_FACET']
}

export const getTempleColor = (p: IProduct) => {
  return p.productAttributes['TEMPLE_COLOR']
}

export const getLensTreatment = (p: IProduct) => {
  return p.productAttributes['LENS_TREATMENT']
}

export const getCorrectionType = (p: IProduct) => {
  return p.productAttributes['CORRECTION_TYPE']
}

export const getAntiBlue = (p: IProduct) => {
  return p.productAttributes['ANTI_BLUE']
}

export const getTransitionType = (p: IProduct) => {
  return p.productAttributes['TRANSITION_TYPE']
}

export const getLensThickness = (p: IProduct) => {
  return p.productAttributes['LENS_THICKNESS']
}

export const getHingeDistance = (p: IProduct) => {
  return p.productAttributes['HINGE_DISTANCE']
}

export const getFrameSize = (p: IProduct | IOrderItem) => {
  return p.productAttributes['FRAME_SIZE_DISPLAY']
}
export const getBadges = (
  product: Pick<IProduct, 'productAttributes'>,
  t: TFunction,
  plpBadges: IBadges = {} as IBadges,
  isRXEnabled = false
): {
  // `badges: IBadges` can also be exported in case of need
  primaryBadge: string
  secondaryBadges: string
} => {
  const getAttribute = (attribute: string) => {
    return product.productAttributes[attribute]
  }
  const controlBadge = (plpBadge, attribute: string) => {
    return !!plpBadge && !!getAttribute(attribute) && plpBadge === getAttribute(attribute)
  }

  const badges: ProductBadges = {
    isBadge: controlBadge(plpBadges.BADGE, 'BADGE'),
    isNew: controlBadge(plpBadges.IS_NEW, 'IS_NEW'),
    isLimitedEdition: controlBadge(plpBadges.LIMITED_EDITION, 'LIMITED_EDITION'),
    isOnlineExclusive: controlBadge(plpBadges['WEB EXCLUSIVE'], 'WEB EXCLUSIVE'),
    isExclusive: controlBadge(plpBadges.EXCLUSIVE, 'EXCLUSIVE'),
    isAvantPremiere: controlBadge(plpBadges.AVANT_PREMIERE, 'AVANT_PREMIERE'),
    isRoxable: controlBadge(plpBadges.ROXABLE, 'ROXABLE') && isRXEnabled,
    isCustomizable: controlBadge(plpBadges.CUSTOMIZABLE, 'CUSTOMIZABLE'),
    isPolarized: controlBadge(plpBadges.POLARIZED, 'POLARIZED'),
    isMostPopular: controlBadge(plpBadges.LX_IS_MOST_POPULAR, 'LX_IS_MOST_POPULAR'),
    isSustainable: controlBadge(plpBadges.SUSTAINABILITY_CLAIM, 'SUSTAINABILITY_CLAIM'),
  }

  const primaryBadge = getPriorityBadgeText(badges, t)

  const currentLensTreatment = getAttribute('LENS_TREATMENT_FACET')
  const activeBadges = Object.keys(
    pickBy(
      {
        Polarised: badges.isPolarized,
        Sustainable: badges.isSustainable,
        isLensTreatment: currentLensTreatment === 'Photochromic' || currentLensTreatment === 'Transitions®',
      },
      cb => cb
    )
  )

  const secondaryBadges = (activeBadges.length > 2 ? activeBadges.slice(0, 2) : activeBadges)
    .map(el => (el === 'isLensTreatment' ? 'Light-Adaptive' : el))
    .join(' | ')

  // `badges` can also be exported in case of need
  return { primaryBadge, secondaryBadges }
}

export const getNormalizedProductType = (p: IProduct | IOrderItem) => {
  const pt: string = getProductType(p)
  const res = pt && PRODUCT_TYPES_MAP[pt.toLowerCase()]
  return res
}

export const PRODUCT_SIZES_NLS_BASE = 'ProductTile.Sizes.'
/** Returns formatted product size if TFunction from react-i18n was passed as argument.
 * Otherwise returns value of FRAME_SIZE_DISPLAY attribute */
export const getSize = (p: IProduct | IOrderItem, t?: TFunction): string => {
  const frameSizeDisplay = getFrameSize(p)
  const productSizeNls = PRODUCT_SIZES_NLS_BASE + frameSizeDisplay

  return t && productSizeNls ? t(productSizeNls) : frameSizeDisplay
}

export const getLensMacroMaterial = (p: IProduct | IOrderItem) => {
  return p.productAttributes['LENS_MATERIAL_MACRO_CLASS']
}

export const getFrameMaterialFacet = (p: IProduct | IOrderItem) => {
  return p.productAttributes['FRAME_MATERIAL_FACET']
}

export const getModelFamily = (p: IProduct | IOrderItem) => {
  return p?.productAttributes['PROD_HIERARCHY_3']
}

export const getCLBrand = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_BRAND']
}

export const getAntiReflective = (p: IProduct | IOrderItem) => {
  return p.productAttributes['ANTI_REFLECTIVE']
}

export const getAnnualSupplyBadge = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_ANNUAL_SUPPLY_BADGES']
}

export const getClAxisRange = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_AXIS_RANGE']
}

export const getClLensRange = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_LENS_RANGE']
}

export const getClColorRange = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_COLOR_RANGE']
}

export const getClDominance = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_DOMINANCE']
}

export const getClAdditionRange = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_ADDITION_RANGE']
}

export const getClCorrectionType = (p: IProduct | IOrderItem) => {
  return p.productAttributes[PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_CORRECTION_TYPE]
}
export const getClCylinderRange = (p: IProduct | IOrderItem) => {
  return p.productAttributes['CL_CYLINDER_RANGE']
}

export const getClBaseCurve = (p: IProduct | IOrderItem) => {
  return p.productAttributes[PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_BASE_CURVE]
}

export const getIsMostPopular = (p: IProduct) => {
  return p.productAttributes['LX_IS_MOST_POPULAR']
}

export const getCanonicalCategory = (p: IProduct) => {
  return p?.productAttributes[PRODUCT_ATTRIBUTES_IDENTIFIERS.LX_CANONICAL_CATEGORY] ?? ''
}

export const getSupplyDuration = (p: IProduct) => {
  return Number(p?.productAttributes[PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_SUPPLY_DURATION] ?? '')
}

export const getPackSize = (p: IProduct) => {
  return Number(p?.productAttributes[PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_PACK_SIZE] ?? '')
}

export const getIsTrialPack = (p: IProduct) => {
  return p?.productAttributes[PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_BADGE] === PRODUCT_BADGE_VALUES.TRIAL_PACK
}

export const getNaturalAttribute = (
  product?: IOrderItem | null,
  key?: keyof typeof PRODUCT_ATTRIBUTES_IDENTIFIERS
): string => {
  try {
    let attribute = ''

    const legacyAttribute = product?.attributes?.find(attr => attr.identifier === key)
    attribute = legacyAttribute?.['attribute.natural'] || ''

    return attribute
  } catch (e) {
    Log.error(`Could not retrieve attribute details: ${e}`)
    return ''
  }
}

/**
 * potential values for CL_BADGE attribute
 */
export const PRODUCT_BADGE_VALUES = {
  BEST_FOR_COMFORT: 'Best for comfort',
  BEST_FOR_VALUE: 'Best for value',
  TRIAL_PACK: 'Trial pack',
}

export const attributesKeyToDisplay = [
  'FRAME_MATERIAL_CLASS',
  'FRAME_SHAPE', // MISSING
  'FRONT_COLOR',
  'LENS_COLOR',
  'FRAME_FITTING', // MISSING
  'LENS_TREATMENT_FACET', //'LENS_TREATMENT',
  'GENDER',
  // CL attributes
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_SUPPLIER,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_BRAND_FAMILY,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_CORRECTION_TYPE,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_MATERIAL_PATENTED,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_WATER_CONTENT,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_GAS_PERMEABILITY,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_DIAMETER,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_BASE_CURVE,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_SPHERE_POWER,
  PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_UV_PROTRECTION,
]

export const MEASURES_UNITS = {
  MM: 'mm',
  DKT: 'Dk/t',
} as const

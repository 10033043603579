import { StyledCheckbox } from '@components/UI/Checkbox'
import { StyledFormControlLabel } from '@components/UI/Checkbox/StyledFormControlLabel'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isFetchingSelector } from '../../../../features/order/selector'
import { addContactLensesToCartErrorSelector } from '../../../../features/product/selector'
import {
  toggleAddContactLensesToCartError,
  toggleAddToCart,
  updateContactLensData,
} from '../../../../features/product/slice'
import Log from '../../../../services/Log'
import { OrderItemContactLensData } from '../../../../types/cart'
import { IOrderItem } from '../../../../types/order'
import {
  ContactLensesData,
  EyeClFieldConfig,
  EyeContactLensAttribute,
  EyeContactLensOverrideMap,
  IProduct,
} from '../../../../types/product'
import { handleDefaultQuantityBySupplyDuration } from '../../../../utils/supplyDurationUtils'
import { ContactLensesSelect } from '../ContactLensesSelect'
import {
  StyledContactLensesDataColumn,
  StyledContactLensesDataWrapper,
  StyledContactLensesErrorContainer,
  StyledContactLensesListHeadingContainer,
  StyledContactLensesListHeadingItem,
  StyledContactLensesSeparator,
  StyledDivider,
  StyledTextField,
} from './ContactLensSelection.style'
import { useContactLensSelection } from './useContactLensSelection'
import NumberInput from './NumberInput'
import { useSite } from '@foundation/hooks/useSite'
import { getIsTrialPack, getPackSize, getSupplyDuration } from '@utils/productAttributes'
import { DEFAULT_PACKS_MAX, DEFAULT_PACKS_PER_EYE_FALLBACK } from '@constants/product'
import { Typography } from '@mui/material'

const LENS_PRODUCT_TYPE = {
  TRIALS: 'Trials',
  DAILY: 'Daily',
  BIWEEKLY: 'BiWeekly',
  MONTHLY: 'Monthly',
}

export const ContactLensSelection: React.FC<{
  pdpData?: IProduct
  currentProduct: IProduct
  ctaRef: React.RefObject<HTMLDivElement>
  totalBoxes?: (e: number) => void
  productInCart?: IOrderItem
  isEditingContactLens?: boolean
  orderItemClData?: OrderItemContactLensData | null
}> = ({ ctaRef, currentProduct, pdpData, isEditingContactLens, totalBoxes, orderItemClData }) => {
  const { t } = useTranslation()
  let labelRefMap = {}
  const { mySite } = useSite()
  const dataRef = useRef<ContactLensesData>()
  const [defaultClValues, setDefaultClValues] = useState<EyeContactLensOverrideMap>()
  const dispatch = useDispatch()
  const addContactLensesToCartError = useSelector(addContactLensesToCartErrorSelector)

  const {
    formatDataForCart,
    defaultEmptyFields,
    contactLensesData,
    setContactLensData,
    contanctLensFieldsConfig,
    fieldsHaveErrors,
    setBothEyesDisabled,
    bothEyesDisabled,
    areBothEyesDisabled,
    fieldsAreNotValid,
    getTotalBoxesCount,
  } = useContactLensSelection(currentProduct, pdpData, defaultClValues)

  const isColorContacts =
    (contanctLensFieldsConfig &&
      (contanctLensFieldsConfig['left']?.find(c => c.id === 'x_color')?.options?.length ?? 0) > 1) ||
    false

  const isFetchingCart = useSelector(isFetchingSelector)

  const labels = contanctLensFieldsConfig
    ? contanctLensFieldsConfig['left']
        ?.filter(fieldConf => (fieldConf.visible || false) && fieldConf.id !== 'quantity' && fieldConf.id !== 'x_color')
        .map(fieldConf => {
          return (
            <Typography
              key={`${fieldConf.id}_${fieldConf.label}`}
              ref={element => {
                // Callback ref is assigned null to clear
                // We don't want to assign it
                if (element) {
                  labelRefMap[fieldConf.id] = element
                }
              }}
            >
              {fieldConf.label}{' '}
            </Typography>
          )
        })
    : null

  const updateFieldValue = (
    eye: string,
    id: EyeContactLensAttribute,
    val?: string | null
  ): Promise<ContactLensesData> => {
    return new Promise(resolve => {
      const eyeContactLensesData = !!contactLensesData ? contactLensesData[eye] : null

      const data: ContactLensesData = {
        ...contactLensesData,
        [eye]: {
          ...eyeContactLensesData,
          [id]: val?.toString() || '',
        },
      }
      resolve(data)
    })
  }

  const getPacksPerEyeQuantity = (product: IProduct, pdpPackPerEyeDefaults: Record<string, number>) => {
    const supplyDuration = getSupplyDuration(product)
    const packSize = getPackSize(product)
    const durationPerPack = supplyDuration / packSize

    let lensProductType = ''
    switch (true) {
      case getIsTrialPack(product):
        lensProductType = LENS_PRODUCT_TYPE.TRIALS
        break
      case durationPerPack === 1:
        lensProductType = LENS_PRODUCT_TYPE.DAILY
        break
      case durationPerPack === 14:
        lensProductType = LENS_PRODUCT_TYPE.BIWEEKLY
        break
      case durationPerPack === 30:
        lensProductType = LENS_PRODUCT_TYPE.MONTHLY
        break
    }

    return lensProductType in pdpPackPerEyeDefaults
      ? pdpPackPerEyeDefaults[lensProductType]
      : DEFAULT_PACKS_PER_EYE_FALLBACK
  }

  const updateFieldsStatus = (
    eye: string,
    id: string,
    fieldsContactLensesData: ContactLensesData,
    valid?: boolean,
    val?: string | null
  ): ContactLensesData => {
    let fieldsData: ContactLensesData = {}
    try {
      const fieldStatus = fieldsContactLensesData[eye].fieldsStatus
      const { errors, emptyFields } = fieldStatus || {}
      const updatedEmptyFields =
        val !== null
          ? emptyFields?.filter(field => field !== id)
          : !emptyFields?.includes(id)
            ? [...(emptyFields || []), id]
            : emptyFields

      const updatedErrors =
        !valid || updatedEmptyFields?.includes(id)
          ? !fieldStatus?.errors?.includes(id)
            ? [...(fieldStatus?.errors || []), id]
            : fieldStatus?.errors
          : errors?.filter(error => error !== id)

      fieldsData = {
        ...fieldsContactLensesData,
        [eye]: {
          ...fieldsContactLensesData[eye],
          x_productId: pdpData?.id,
          fieldsStatus: {
            ...fieldStatus,
            errors: updatedErrors,
            emptyFields: updatedEmptyFields,
            valid: updatedEmptyFields && updatedEmptyFields.length <= 0 && updatedErrors && updatedErrors.length <= 0,
          },
        },
      }
      return fieldsData
    } catch (e) {
      Log.error('error on updating contact lenses data ' + e)
    }

    return fieldsData
  }
  const validateAllFields = (): Promise<ContactLensesData> => {
    const updatedState: ContactLensesData = { ...contactLensesData }

    Object.keys(updatedState).map(eye => {
      const eyeStatus = { ...updatedState[eye] }
      Object.keys(eyeStatus)
        .map(fieldId => {
          const { isValid } = validateField(eye, fieldId, updatedState)
          return { isValid, eye, fieldId }
        })
        .map(item => {
          const { isValid, eye, fieldId } = item
          const data = updateFieldsStatus(eye, fieldId, updatedState, isValid)
          updatedState[eye] = data[eye]
        })
    })
    return new Promise(resolve => {
      resolve(updatedState)
    })
  }

  const validateField = (eye: string, fieldId: string, fielsData: ContactLensesData): { isValid: boolean } => {
    const eyeContactLensesData = !!fielsData ? fielsData[eye] : null
    const fieldIsRequired = contanctLensFieldsConfig
      ? contanctLensFieldsConfig[eye]?.find(field => field.id === fieldId)?.required
      : false

    const fieldHasError = !!eyeContactLensesData
      ? fieldIsRequired && eyeContactLensesData[fieldId] === '' && eyeContactLensesData.fieldsStatus?.enabled
      : false

    return { isValid: !fieldHasError || false }
  }
  const onEyeCheckBoxValueChanged = (value: boolean, eye: string): Promise<ContactLensesData> => {
    return new Promise(resolve => {
      const data: ContactLensesData = {
        ...contactLensesData,
        [eye]: {
          ...contactLensesData[eye],
          //...defaultFieldsValues[eye],
          fieldsStatus: {
            emptyFields: defaultEmptyFields[eye] || [],
            dirtyFields: [],
            errors: [],
            enabled: value,
            valid: false,
          },
        },
      }

      resolve(data)
    })
  }
  const renderField = (
    key: string,
    id: EyeContactLensAttribute,
    eye: string,
    onClSelectChanged: (eye: string, id: EyeContactLensAttribute, val?: string | null) => Promise<ContactLensesData>,
    isSelect: boolean,
    options: EyeClFieldConfig['options'],
    isMultifield?: boolean
  ) => {
    const fieldErrors = !!contactLensesData ? contactLensesData[eye]?.fieldsStatus?.errors : []
    const eyeContactLensesData = !!contactLensesData ? contactLensesData[eye] : null
    const eyeFieldsEnabled = !!eyeContactLensesData ? eyeContactLensesData.fieldsStatus?.enabled : false
    if (isSelect)
      return (
        <ContactLensesSelect
          listheading={
            isMultifield ? (
              <StyledContactLensesListHeadingContainer>
                <StyledContactLensesListHeadingItem>
                  <Typography variant="body1">-</Typography>
                </StyledContactLensesListHeadingItem>
                <StyledContactLensesListHeadingItem>
                  <Typography variant="body1">+</Typography>
                </StyledContactLensesListHeadingItem>
              </StyledContactLensesListHeadingContainer>
            ) : null
          }
          id={id}
          eye={eye}
          onSelectValueChange={(eye, id, val) => {
            onClSelectChanged(eye, id, val).then(data => {
              const { isValid } = validateField(eye, id, data)
              const updatedData = updateFieldsStatus(eye, id, data, isValid, val)
              setContactLensData({ ...contactLensesData, ...updatedData })
            })
          }}
          error={eyeFieldsEnabled ? (fieldErrors?.length ? (fieldErrors.includes(id) ? true : false) : false) : false}
          value={!!eyeContactLensesData ? (!!eyeContactLensesData[id] ? eyeContactLensesData[id] || '' : null) : null}
          disabled={!eyeFieldsEnabled}
          key={key}
          options={options}
          multifield={isMultifield}
        />
      )
    if (['left-quantity', 'right-quantity'].includes(key))
      return (
        <NumberInput
          eye={eye}
          id={id}
          disabled={!eyeFieldsEnabled}
          value={!!eyeContactLensesData ? (!!eyeContactLensesData[id] ? eyeContactLensesData[id] || '' : 1) : 1}
          max={DEFAULT_PACKS_MAX}
          onChange={(eye, id, val) => {
            onClSelectChanged(eye, id, val).then(data => {
              const { isValid } = validateField(eye, id, data)
              const updatedData = updateFieldsStatus(eye, id, data, isValid, val)
              setContactLensData({ ...contactLensesData, ...updatedData })
            })
          }}
        />
      )
    return (
      <StyledTextField
        disabled={!eyeFieldsEnabled}
        type="text"
        inputProps={{
          readOnly: true,
        }}
        name={id}
        value={!!eyeContactLensesData ? (!!eyeContactLensesData[id] ? eyeContactLensesData[id] : '') : ''}
      />
    )
  }

  const renderCheckbox = (
    eye: string,
    onCheckboxChanged: (value: boolean, eye: string) => Promise<ContactLensesData>,
    label?: string
  ) => {
    return (
      <StyledFormControlLabel
        data-name={eye + 'EyeChk'}
        control={
          <StyledCheckbox
            key={eye}
            checked={!!contactLensesData ? contactLensesData[eye]?.fieldsStatus?.enabled : true}
            onChange={e =>
              onCheckboxChanged(e.target.checked, eye).then(data => {
                setContactLensData({
                  ...data,
                })
              })
            }
          />
        }
        label={label}
      />
    )
  }

  const onClick = useCallback(() => {
    validateAllFields().then(data => {
      setBothEyesDisabled(areBothEyesDisabled(data))
      setContactLensData(data)

      let fieldErrors: string[] = []
      ;['left', 'right'].forEach(eye => {
        fieldErrors.push(...(data[eye].fieldsStatus?.errors || []))
      })
      if (fieldErrors.length > 0) {
        // Scroll to first element with error
        labelRefMap[fieldErrors[0]].scrollIntoView({ block: 'center' })
      }
    })
  }, [contactLensesData])

  useEffect(() => {
    dataRef.current = contactLensesData
    ctaRef.current?.addEventListener('click', onClick)
    totalBoxes && totalBoxes(getTotalBoxesCount(contactLensesData))
    if (!fieldsAreNotValid(contactLensesData) && !areBothEyesDisabled(contactLensesData)) {
      const formattedPayload = formatDataForCart(contactLensesData)
      dispatch(toggleAddToCart(true))
      dispatch(updateContactLensData(formattedPayload))
    } else {
      dispatch(toggleAddToCart(false))
    }
    dispatch(toggleAddContactLensesToCartError(false))
    return () => {
      ctaRef.current?.removeEventListener('click', onClick)
    }
  }, [contactLensesData])

  const getOrderitemDefaultValues = (defaultQuantity: string): EyeContactLensOverrideMap => {
    try {
      const contactLensDefaultValuesMap: EyeContactLensOverrideMap = {
        left: [],
        right: [],
      }
      if (orderItemClData) {
        for (const eye in orderItemClData) {
          if (orderItemClData.hasOwnProperty(eye)) {
            for (const key in orderItemClData[eye].data) {
              if (orderItemClData[eye]?.data?.hasOwnProperty(key)) {
                contactLensDefaultValuesMap[eye].push({
                  attribute: key as EyeContactLensAttribute,
                  value: orderItemClData[eye]?.data?.[key],
                })
              }
            }
            contactLensDefaultValuesMap[eye].push({
              attribute: 'quantity',
              value: parseFloat(orderItemClData[eye].quantity || defaultQuantity).toString(),
            })
          }
        }
      }
      if (isEditingContactLens) {
        return contactLensDefaultValuesMap
      } else {
        return {
          left: [
            {
              attribute: 'quantity',
              value: defaultQuantity,
            },
          ],
          right: [
            {
              attribute: 'quantity',
              value: defaultQuantity,
            },
          ],
        }
      }
    } catch (e) {
      Log.error('error on getting contact lenses default data ' + e)
      return {
        left: [],
        right: [],
      }
    }
  }

  useEffect(() => {
    const defaultQuantity = handleDefaultQuantityBySupplyDuration(currentProduct)

    const result = getPacksPerEyeQuantity(currentProduct, mySite?.xStoreCfg?.pdpPackPerEyeDefaults)

    !isFetchingCart &&
      (getOrderitemDefaultValues(defaultQuantity)?.left.length > 0 ||
        getOrderitemDefaultValues(defaultQuantity)?.right.length > 0) &&
      setDefaultClValues({
        left: [...getOrderitemDefaultValues(result.toString())?.left],
        right: [...getOrderitemDefaultValues(result.toString())?.right],
      })
  }, [currentProduct, orderItemClData, mySite?.xStoreCfg?.pdpPackPerEyeDefaults, isFetchingCart])

  useEffect(() => {
    return () => {
      dispatch(toggleAddContactLensesToCartError(false))
    }
  }, [])

  return (
    <>
      <Typography variant="subtitle2">{t('ContactLenses.Labels.SelectPrescriptionTitle')}</Typography>
      <StyledDivider />
      <StyledContactLensesDataWrapper>
        <StyledContactLensesDataColumn>
          <Typography variant="body1">{t('ContactLenses.Labels.Eye')}</Typography>
          {labels}
        </StyledContactLensesDataColumn>
        {['left', 'right'].map((eye, i) => {
          return (
            <StyledContactLensesDataColumn key={`${eye}_${i}`}>
              {renderCheckbox(eye, onEyeCheckBoxValueChanged, t(`ContactLenses.Labels.${eye.toLowerCase()}Eye`))}

              {contanctLensFieldsConfig
                ? contanctLensFieldsConfig[eye]
                    ?.filter(fieldConf => fieldConf.id !== 'quantity' && fieldConf.id !== 'x_color')
                    .map(fieldConf => {
                      const clFields: JSX.Element[] = []
                      const { id, select, options, multifield, visible = true } = fieldConf
                      const clField = renderField(
                        `${eye}-${id}`,
                        id,
                        eye,
                        updateFieldValue,
                        select,
                        options,
                        multifield
                      )
                      visible && clFields.push(clField)
                      return clFields
                    })
                : null}
            </StyledContactLensesDataColumn>
          )
        })}
        {isColorContacts && (
          <>
            <StyledContactLensesDataColumn>
              <Typography>{t('ContactLenses.Labels.Color')}</Typography>
            </StyledContactLensesDataColumn>
            <StyledContactLensesDataColumn>
              {renderField(
                'left-color',
                'x_color',
                'left',
                updateFieldValue,
                true,
                contanctLensFieldsConfig?.['left']?.find(fieldConf => fieldConf.id === 'x_color')?.options || [],
                false
              )}
            </StyledContactLensesDataColumn>
            <StyledContactLensesDataColumn>
              {renderField(
                'right-color',
                'x_color',
                'right',
                updateFieldValue,
                true,
                contanctLensFieldsConfig?.['right']?.find(fieldConf => fieldConf.id === 'x_color')?.options || [],
                false
              )}
            </StyledContactLensesDataColumn>
          </>
        )}
        <StyledContactLensesSeparator />
        <StyledContactLensesSeparator>
          <StyledDivider />
        </StyledContactLensesSeparator>
        <StyledContactLensesSeparator />

        <StyledContactLensesDataColumn>
          <Typography>{t('ContactLenses.Labels.Boxes')}</Typography>
        </StyledContactLensesDataColumn>
        <StyledContactLensesDataColumn>
          {renderField(
            'left-quantity',
            'quantity',
            'left',
            updateFieldValue,
            false,
            contanctLensFieldsConfig
              ? contanctLensFieldsConfig['left']?.find(fieldConf => fieldConf.id === 'quantity')?.options || []
              : [],
            false
          )}
        </StyledContactLensesDataColumn>
        <StyledContactLensesDataColumn>
          {renderField(
            'right-quantity',
            'quantity',
            'right',
            updateFieldValue,
            false,
            contanctLensFieldsConfig
              ? contanctLensFieldsConfig['right']?.find(fieldConf => fieldConf.id === 'quantity')?.options || []
              : [],
            false
          )}
        </StyledContactLensesDataColumn>
      </StyledContactLensesDataWrapper>
      <StyledContactLensesSeparator />
      <StyledContactLensesSeparator>
        <StyledDivider />
      </StyledContactLensesSeparator>

      {(bothEyesDisabled || fieldsHaveErrors || addContactLensesToCartError) && (
        <StyledContactLensesErrorContainer variant="body2" id="prescription-not-available">
          {bothEyesDisabled && t('ContactLenses.Errors.BothEyesDisabled')}
          {fieldsHaveErrors && t('ContactLenses.Errors.FieldsWithErrors')}
          {addContactLensesToCartError && t('ContactLenses.Errors.PrescriptionNotAvailable')}
        </StyledContactLensesErrorContainer>
      )}
    </>
  )
}

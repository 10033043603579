// custom imports
//UI
import { ArrowUpIcon } from '@components/UI/Icons/arrows'
import { CloseCircleIcon } from '@components/UI/Icons/close'
import { IFacet, IPlpProduct, IPlpProductArgs, SelectedFacets } from '@features/plp/query'
import {
  ClearAllContainer,
  ClearAllLink,
  FilterCTASContainer,
  FilterCTASWrapper,
  FiltersAppliedContainer,
  ResultsNumberContainer,
  StyledFade,
  StyledFilterWrapper,
  StyledPLPFiltersBar,
  StyledPLPFiltersBarButtonsWrapper,
  StyledPLPHeaderTitleWrapper,
  StyledPLPHeaderWrapper,
  StyledPLPSeparator,
  StyledPLPTitleSeparator,
  StyledResultsFor,
  StyledScrollToTopButton,
} from './PlpHeader.style'
// commons
// standard imports
import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
// Utils
import { formattedParams, fromUrlParamsToObject } from '@utils/url'

import { Pill } from '@components/UI/Pill'
import useBreakpoints from '@hooks/useBreakpoints'
import useScrollTo from '@hooks/useScrollTo'
import { isEmpty } from '@utils/helpers'
import { TransitionGroup } from 'react-transition-group'
import PLPHeaderFilter from './components/PlpHeaderFilter'
import PlpHeaderSuggested from './components/PlpHeaderSuggested'
import PlpHeaderTitle from './components/PlpHeaderTitle'
// Custom hooks
import useScrollingUp from '@hooks/useScrollingUp/useScrollingUp'
import useStickyScroll from '@hooks/useStickyScroll'
// redux
import { plpFacetsKeysSelector } from '@features/plp/selector'
import { sendFilterSelectedEvent } from '@foundation/analytics/tealium/lib'
import useTheme from '@mui/material/styles/useTheme'
import { plpRadioButtonFacetsSelector } from '@redux/selectors/site'
import SizeAdvisorUtil from '@utils/FrameGenius/SizeAdvisorUtil'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import PlpOffersFilters from './components/PlpOffersFilters'

import { useRouter } from 'next/router'
import { ICategory } from '@features/category/query'

interface PLPHeaderProps {
  title?: string
  catalogLoading?: boolean
  searchTerm?: string
  category: ICategory
  productData?: IPlpProduct
  isPlacementsBetween: boolean
}

export const getSelectedFacetsForSearch = (selectedFacets: SelectedFacets, plpFacetsKeys): SelectedFacets => {
  return Object.keys(selectedFacets)
    .filter(facetKey => {
      const facetValue = selectedFacets[facetKey].value
      return !plpFacetsKeys.includes(facetValue)
    })
    .reduce((acc, curr) => {
      const selectedFacet = {
        [curr]: selectedFacets[curr],
      }
      return { ...acc, ...selectedFacet }
    }, {})
}

const getCurrentSelectedFacets = (selectedFacets: string | string[]) => {
  return selectedFacets ? (Array.isArray(selectedFacets) ? selectedFacets : [selectedFacets]) : []
}

const getLxDiscountFacets = (facets: IFacet[]) => {
  return facets.filter(facet => facet.name === 'LX_DISCOUNTED_PRICE_BADGES') || []
}

const PlpHeader: FC<PLPHeaderProps> = ({
  title,
  catalogLoading,
  searchTerm,
  category,
  productData,
  isPlacementsBetween,
}) => {
  const categoryIdentifier = category?.identifier
  const { t } = useTranslation()
  const router = useRouter()
  const basePath = router.asPath.split('?')[0]
  const searchParams = router.asPath.split('?')[1]
  const theme = useTheme()
  const { isDesktop } = useBreakpoints()
  const scrolled = useScrollingUp()
  const scrollTo = useScrollTo()
  const radioButtonFacets = useSelector(plpRadioButtonFacetsSelector)
  const plpFacetsKeys = useSelector(plpFacetsKeysSelector)

  const queryParams: IPlpProductArgs = fromUrlParamsToObject(searchParams)

  const [isFiltersBarStuck, setFiltersBarStuck] = useState<boolean>(false)
  const plpHeaderWrapperRef = useRef<HTMLDivElement | null>(null)
  const plpDesktopHeaderRef = useRef<HTMLDivElement | null>(null)
  const plpMobileHeaderRef = useRef<HTMLDivElement | null>(null)
  const filtersBarRef = useRef<HTMLDivElement>(null)

  const isSearchTerm = searchTerm !== ''
  const isSticky = useStickyScroll(isDesktop ? plpDesktopHeaderRef : plpMobileHeaderRef, plpHeaderWrapperRef)

  const selectedFacets = productData?.selectedFacets || {}
  const productTotal = productData?.productListTotal || 0
  const facets = productData?.facets || []
  const lxDiscountFacets = getLxDiscountFacets(facets)
  const currentSelectedFacets = getCurrentSelectedFacets(queryParams.facet || [])

  const currentSelectedOfferRegex = /value.raw%3A%22(\d.*?)%25/

  const discountedFacets = currentSelectedFacets.filter(facet => currentSelectedOfferRegex.test(facet))

  const nonDiscountedFacets = currentSelectedFacets.filter(facet => !currentSelectedOfferRegex.test(facet))

  const selectedFacetsForSearch = getSelectedFacetsForSearch(selectedFacets, plpFacetsKeys)

  /*
  const appliedFiltersNumber = selectedFacets
    ? Object.keys(selectedFacets).length
    : 0
*/

  let showOffersFilters = false
  if (categoryIdentifier && radioButtonFacets) {
    const result: string | undefined = radioButtonFacets.find(n =>
      n.toLowerCase().endsWith(categoryIdentifier.toLowerCase())
    )
    showOffersFilters = !!result
  }

  const appliedFiltersNumberForSearch = showOffersFilters
    ? Object.values(selectedFacetsForSearch).filter(el => el.facetName !== 'LX_DISCOUNTED_PRICE_BADGES').length
    : selectedFacetsForSearch
      ? Object.keys(selectedFacetsForSearch).length
      : 0

  const onClearAll = (event: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()

    const getFacetSearch = currentSelectedFacets.filter(
      facet => !Object.keys(selectedFacetsForSearch).includes(facet),
      []
    )
    SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(false)

    const params: IPlpProductArgs = {
      sortBy: queryParams.sortBy || undefined,
      searchTerm: searchTerm || undefined,
      facet: searchTerm ? getFacetSearch : undefined,
    }
    const newParams = formattedParams(params)
    const url = `${basePath}${newParams}`
    router.push(url, undefined, { shallow: true })
  }

  const handleRemoveFacet = (selection: string) => {
    const index = currentSelectedFacets.indexOf(selection)
    if (index > -1) {
      const controlFacets = [...currentSelectedFacets]
      controlFacets.splice(index, 1)

      const params: IPlpProductArgs = {
        searchTerm: searchTerm || undefined,
        sortBy: queryParams.sortBy || undefined,
        facet: controlFacets,
      }

      SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(false)
      const newParams = formattedParams(params)
      const url = `${basePath}${newParams}`
      router.push(url, undefined, { shallow: true })
    }
  }

  useEffect(() => {
    if (!filtersBarRef?.current) return
    const observer = new IntersectionObserver(([e]) => setFiltersBarStuck(e.intersectionRatio < 1), { threshold: 1 })
    observer.observe(filtersBarRef.current)
    return () => {
      if (filtersBarRef?.current) {
        observer.unobserve(filtersBarRef.current)
      }
    }
  }, [filtersBarRef])
  /* TODO
check remove all offers
*/

  /*  const suggestedProductFacet = getFacetFromSuggestedProducts(
    facets
    //plpFacets!
  )*/

  const onOffersFilterApply = (offer: string) => {
    const offerFiltered = currentSelectedFacets.filter(facet => {
      return !Object.values(discountedFacets).includes(facet)
    })
    const newParams = formattedParams({
      searchTerm: undefined,
      orderBy: queryParams.sortBy || undefined,
      facet: [...offerFiltered, offer],
    })

    const url = `${basePath}${newParams}`
    router.push(url, undefined, { shallow: true })
    //analytics
    sendFilterSelectedEvent(selectedFacets, productTotal)
  }
  const onClearAllOffers = () => {
    const offerFiltered = currentSelectedFacets.filter(facet => {
      return !Object.values(discountedFacets).includes(facet)
    })
    const params: IPlpProductArgs = {
      sortBy: queryParams.sortBy || undefined,
      searchTerm: searchTerm || undefined,
      facet: offerFiltered,
    }
    const newParams = formattedParams(params)
    const url = `${basePath}${newParams}`
    router.push(url, undefined, { shallow: true })
  }

  /*  const showFiltersWithToggle =
    suggestedProductFacet.length > 1
      ? appliedFiltersNumber === 0
      : appliedFiltersNumberForSearch === 0*/

  return (
    <StyledPLPHeaderWrapper ref={plpHeaderWrapperRef}>
      {!isDesktop ? (
        <>
          <StyledPLPHeaderTitleWrapper
            isSticky={isSticky}
            isScrolled={!!scrolled}
            width={plpHeaderWrapperRef.current?.clientWidth}
            withFilters={appliedFiltersNumberForSearch > 0}
          >
            {isPlacementsBetween && (isDesktop || !isSticky) && (
              <PlpHeaderTitle
                isSticky={isSticky}
                text={
                  searchTerm
                    ? t('ProductGrid.Labels.searchFor', {
                        searchTerm: searchTerm.replace('*', ''),
                      })
                    : title
                }
              />
            )}
            {showOffersFilters && !isEmpty(lxDiscountFacets) && !isSticky && (
              <PlpOffersFilters
                facets={lxDiscountFacets}
                onOffersFilterApply={onOffersFilterApply}
                removeAll={onClearAllOffers}
                selectedFacets={selectedFacets}
              />
            )}
            {!isSticky && <StyledPLPTitleSeparator />}
            {isSearchTerm && !isSticky && <PlpHeaderSuggested facets={facets} />}

            <PLPHeaderFilter
              ref={plpMobileHeaderRef}
              appliedFiltersNumber={appliedFiltersNumberForSearch}
              facets={facets}
              catalogLoading={catalogLoading}
            />
          </StyledPLPHeaderTitleWrapper>

          {appliedFiltersNumberForSearch > 0 && (
            <>
              <StyledPLPSeparator />
              <StyledPLPFiltersBar isStuck={isFiltersBarStuck} ref={filtersBarRef}>
                {(!lxDiscountFacets || !isEmpty(lxDiscountFacets)) && (
                  <StyledPLPFiltersBarButtonsWrapper>
                    <FilterCTASWrapper activeFilter={appliedFiltersNumberForSearch > 0}>
                      {appliedFiltersNumberForSearch > 0 && (
                        <ResultsNumberContainer>
                          {!catalogLoading && !isFiltersBarStuck ? (
                            <StyledResultsFor>
                              {productTotal} {t('ProductGrid.Labels.resultFor')}
                            </StyledResultsFor>
                          ) : null}
                        </ResultsNumberContainer>
                      )}
                      <FilterCTASContainer>
                        <FiltersAppliedContainer>
                          <TransitionGroup component={null}>
                            {Object.keys(selectedFacets).map(facetKey => {
                              const facetValue = selectedFacets[facetKey].value
                              const facetLabel = selectedFacets[facetKey].label

                              if (facetValue === 'True') return

                              if (plpFacetsKeys.includes(facetValue)) return

                              return (
                                <StyledFade key={facetKey} timeout={{ exit: 500 }}>
                                  <div>
                                    <Pill
                                      id={facetKey}
                                      key={facetKey}
                                      labelText={facetLabel || facetValue}
                                      deleteIcon={<CloseCircleIcon onClick={() => handleRemoveFacet(facetKey)} />}
                                    />
                                  </div>
                                </StyledFade>
                              )
                            })}
                          </TransitionGroup>
                          {appliedFiltersNumberForSearch > 0 && (
                            <ClearAllContainer>
                              <ClearAllLink onClick={event => onClearAll(event)}>
                                {t('ProductGrid.Actions.clearAll')}{' '}
                              </ClearAllLink>
                            </ClearAllContainer>
                          )}
                        </FiltersAppliedContainer>
                      </FilterCTASContainer>
                    </FilterCTASWrapper>
                  </StyledPLPFiltersBarButtonsWrapper>
                )}
              </StyledPLPFiltersBar>
            </>
          )}
        </>
      ) : (
        <>
          <StyledPLPHeaderTitleWrapper
            isSticky={isSticky}
            isScrolled={!!scrolled}
            width={plpHeaderWrapperRef.current?.clientWidth}
            ref={plpDesktopHeaderRef}
          >
            {(!isSticky || isDesktop) && (
              <PlpHeaderTitle
                isSticky={isSticky}
                catalogLoading={catalogLoading}
                text={
                  searchTerm
                    ? t('ProductGrid.Labels.searchFor', {
                        searchTerm: searchTerm.replace('*', ''),
                      })
                    : title
                }
              />
            )}
            <StyledFilterWrapper>
              {((!isSearchTerm && appliedFiltersNumberForSearch === 0) || isSticky) &&
                productData &&
                productData.productList?.length > 0 && (
                  <PLPHeaderFilter
                    catalogLoading={catalogLoading}
                    appliedFiltersNumber={appliedFiltersNumberForSearch}
                    facets={facets}
                  />
                )}

              {isSearchTerm && !isSticky && <PlpHeaderSuggested facets={facets} />}

              {isSticky && isDesktop && (
                <StyledScrollToTopButton aria-label="ScrollToTop" onClick={() => scrollTo(0, 0)}>
                  <ArrowUpIcon htmlColor={theme.palette.custom.cyprus} />
                </StyledScrollToTopButton>
              )}
            </StyledFilterWrapper>
          </StyledPLPHeaderTitleWrapper>
          {showOffersFilters && !isEmpty(lxDiscountFacets) && (
            <PlpOffersFilters
              facets={lxDiscountFacets}
              onOffersFilterApply={onOffersFilterApply}
              removeAll={onClearAllOffers}
              selectedFacets={selectedFacets}
            />
          )}
          <StyledPLPSeparator />

          <StyledPLPFiltersBar isStuck={isFiltersBarStuck} ref={filtersBarRef}>
            {(searchTerm !== '' || appliedFiltersNumberForSearch > 0) && (
              <StyledPLPFiltersBarButtonsWrapper>
                {
                  <FilterCTASWrapper activeFilter={appliedFiltersNumberForSearch > 0}>
                    {appliedFiltersNumberForSearch > 0 && (
                      <ResultsNumberContainer>
                        {(!catalogLoading &&
                          !isFiltersBarStuck &&
                          appliedFiltersNumberForSearch > 0 &&
                          nonDiscountedFacets.length > 0) ||
                        !discountedFacets ? (
                          <StyledResultsFor>
                            {productTotal} {t('ProductGrid.Labels.resultFor')}
                          </StyledResultsFor>
                        ) : null}
                      </ResultsNumberContainer>
                    )}
                    <FilterCTASContainer>
                      {appliedFiltersNumberForSearch > 0 && (
                        <FiltersAppliedContainer>
                          <TransitionGroup component={null}>
                            {Object.keys(selectedFacets).map(facetKey => {
                              const facetType = selectedFacets[facetKey].facetName
                              const facetValue = selectedFacets[facetKey].value
                              const facetLabel = selectedFacets[facetKey].label

                              if (facetValue === 'True') return
                              if (facetType === 'LX_DISCOUNTED_PRICE_BADGES') return

                              if (plpFacetsKeys.includes(facetValue)) return

                              return (
                                <StyledFade key={facetKey} timeout={{ exit: 500 }}>
                                  <div>
                                    <Pill
                                      id={facetKey}
                                      key={facetKey}
                                      labelText={facetLabel || facetValue}
                                      deleteIcon={<CloseCircleIcon onClick={() => handleRemoveFacet(facetKey)} />}
                                    />
                                  </div>
                                </StyledFade>
                              )
                            })}
                          </TransitionGroup>
                          {appliedFiltersNumberForSearch > 0 && (
                            <ClearAllContainer>
                              <ClearAllLink onClick={event => onClearAll(event)}>
                                {t('ProductGrid.Actions.clearAll')}
                              </ClearAllLink>
                            </ClearAllContainer>
                          )}
                        </FiltersAppliedContainer>
                      )}
                      {(isSearchTerm || appliedFiltersNumberForSearch > 0) && (
                        <PLPHeaderFilter
                          appliedFiltersNumber={appliedFiltersNumberForSearch}
                          facets={facets}
                          catalogLoading={catalogLoading}
                        />
                      )}
                    </FilterCTASContainer>
                  </FilterCTASWrapper>
                }
              </StyledPLPFiltersBarButtonsWrapper>
            )}
          </StyledPLPFiltersBar>
        </>
      )}
    </StyledPLPHeaderWrapper>
  )
}

export default PlpHeader

import { USER_TYPE } from '@foundation/analytics/tealium/types'
import { IUserSliceState } from './IUserSliceState'

export const userInitState: IUserSliceState = {
  initiatedFromStorage: false,
  WCToken: '',
  WCTrustedToken: '',
  details: null,
  areDetailsLoading: false,
  userIsLoggingIn: false,
  wallet: {
    wallet: [],
    selectedWallet: null,
    recordTotalCount: 0,
    resourseName: '',
  },
  isReturningCustomerButtonClicked: false,
  passwordResetRequired: false,
  isReorderIn3ClicksButtonClicked: false,
  userType: USER_TYPE.UNKNOWN,
  activeSubscriptionsQuantity: 0,
}

import { IconMinus, IconMinusThree, IconPlus, IconPlusTwo } from '@components/UI/Icons/VD/General'
import {
  TextField as MuiTextField,
  inputBaseClasses as MuiInputBaseClasses,
  outlinedInputClasses as MuiOutlinedInputClasses,
  inputLabelClasses as MuiInputLabelClasses,
  Divider,
  Typography,
  dividerClasses,
  styled,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { StyledInputAdornmentProps } from './NumberInput'

export const StyledContactLensesDataWrapper = styled('div', {
  name: 'ContactLensesDataWrapper',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1.5fr 2fr 2fr',
  gap: theme.spacing(7),
  rowGap: theme.spacing(4),
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
    rowGap: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}))

export const ContactLensesSelectPrescriptionTitle = styled(Typography, {
  name: 'ContactLensesData',
  slot: 'SelectPrescriptionTitle',
})(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  [`&.${dividerClasses.root}`]: {
    color: theme.palette.neutral.tones[80],
  },
}))

export const ContactLensesInput = styled('input', {
  name: 'ContactLensesDataInput',
})<{
  disabled: boolean
}>(({ theme, disabled }) => ({
  height: '33px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '12px',
  backgroundColor: theme.palette.background.light.primary,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.custom.boulder}`,
  opacity: disabled ? '0.5' : '1',
}))

export const StyledContactLensesDataColumn = styled('div', {
  name: 'ContactLensesDataColumn',
})(({ theme }) => ({
  gridAutoRows: '1fr',
  display: 'grid',
  alignItems: 'center',
  rowGap: theme.spacing(6),
  fieldset: {
    top: 0,
  },
}))

export const StyledContactLensesListHeadingContainer = styled('div', {
  name: 'ContactLensesDataListHeadingContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.dark.secondary,
  display: 'flex',
  justifyContent: 'space-evenly',
  position: 'relative',
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const StyledContactLensesListHeadingItem = styled('span', {
  name: 'ContactLensesDataListHeading',
})(({ theme }) => ({
  width: '100%',
  listStyle: 'none',
  textAlign: 'center',
  lineHeight: '33px',
  margin: '0',
  padding: '0',
  backgroundColor: theme.palette.background[2],
}))

export const StyledContactLensesSeparator = styled('div', {
  name: 'ContactLensesDataSeparator',
})(() => ({
  gridColumn: 'span 3',
  height: '1px',
}))

export const StyledContactLensesErrorContainer = styled(Typography, {
  name: 'ContactLensesData',
  slot: 'ErrorContainer',
})(({ theme }) => ({
  color: theme.palette.color.error,
}))

export const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  [`& .${MuiInputBaseClasses.root}`]: {
    ...theme.typography.body1,
    height: 'auto',
    padding: theme.spacing(4.75, 8),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 8),
    },
  },

  [`& .${MuiInputBaseClasses.input}`]: {
    color: theme.palette.neutral.main,
    height: 'auto',
    padding: theme.spacing(0),
  },

  [`& .${MuiOutlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.neutral.tones[80],
    height: 40,
    top: 0,

    '&:focus': {
      borderColor: theme.palette.neutral.tones[60],
    },

    '> legend': {
      display: 'none',
    },
  },

  //  Targets prefix and suffix's
  [`& .${MuiOutlinedInputClasses.inputAdornedStart}`]: {
    paddingLeft: theme.spacing(0),
  },

  [`.${MuiInputLabelClasses.root}`]: {
    lineHeight: 1,
    fontWeight: 'normal',
    [`&.${MuiInputLabelClasses.shrink}`]: {
      ...theme.typography.caption,
      color: theme.palette.text.primary,
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
    '&[data-shrink="true"]': {
      transform: 'translate(12px, -19px) scale(1)',
      backgroundColor: 'transparent',
      left: 6,
    },
  },

  // this disable the autofill background color
  'input:-webkit-autofill': {
    transition: 'background-color 9999s ease-in-out 0s',
  },
  'input:-webkit-autofill:hover': {
    transition: 'background-color 9999s ease-in-out 0s',
  },

  'input:-webkit-autofill:focus': {
    transition: 'background-color 9999s ease-in-out 0s',
  },
  'input:-webkit-autofill:active': {
    transition: 'background-color 9999s ease-in-out 0s',
  },
}))

export const StyledIconMinus = styled(IconMinus, {
  name: 'IconMinus',
})(() => ({
  width: '1.5rem',
  height: '1.5rem',
}))

export const StyledIconPlus = styled(IconPlus, {
  name: 'IconPlus',
})(() => ({
  width: '1.5rem',
  height: '1.5rem',
}))

export const StyledIconButton = styled(IconButton, {
  name: 'IconButton',
})(() => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '1.5rem',
  height: '1.5rem',

  ':hover': {
    background: 'none',
  },
}))

export const StyledOutlinedInput = styled(OutlinedInput, {
  name: 'OutlinedInput',
})(({ theme }) => ({
  borderColor: theme.palette.neutral.tones[80],
  height: theme.spacing(19),
  padding: 0,
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    textAlign: 'center',
    padding: 0,
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  span: {
    display: 'none',
  },
}))

export const StyledInputAdornment = styled(InputAdornment, {
  name: 'InputAdornment',
})<StyledInputAdornmentProps>(({ theme, increment, disabled }) => ({
  backgroundColor: disabled ? theme.palette.neutral.tones[98] : theme.palette.secondary.tones[90],
  borderRight: !increment ? `1px solid ${theme.palette.neutral.tones[80]}` : 'none',
  borderLeft: increment ? `1px solid ${theme.palette.neutral.tones[80]}` : 'none',
  borderRadius: increment ? theme.spacing(0, 4, 4, 0) : theme.spacing(4, 0, 0, 4),
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'space-around',
}))

export const StyledIconPlusTwo = styled(IconPlusTwo, {
  name: 'IconPlusTwo',
})(() => ({
  width: '1.5rem',
  height: '1.5rem',
}))

export const StyledIconMinusThree = styled(IconMinusThree, {
  name: 'IconMinusTwo',
})(() => ({
  width: '1.5rem',
  height: '1.5rem',
}))

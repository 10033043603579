import { useEffect, useCallback } from 'react'
import { guestStatusSelector, canGuestUserCheckoutSelector } from '@features/user/selector'
import { useSelector, useDispatch } from 'react-redux'
import { setCanGuestUserCheckout } from '@features/user/slice'
import { isGuestCheckoutEnabledSelector } from '@redux/selectors/site'

export const useGuestUserSession = () => {
  const dispatch = useDispatch()

  const userIsGuest = useSelector(guestStatusSelector)
  const canGuestUserCheckout = useSelector(canGuestUserCheckoutSelector)
  const isGuestCheckoutEnabled = useSelector(isGuestCheckoutEnabledSelector)

  const handleGuestCheckoutStatus = useCallback((): void => {
    if (isGuestCheckoutEnabled && userIsGuest) {
      dispatch(setCanGuestUserCheckout(true))
    } else {
      dispatch(setCanGuestUserCheckout(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsGuest, isGuestCheckoutEnabled])

  useEffect(() => {
    handleGuestCheckoutStatus()
  }, [handleGuestCheckoutStatus])

  return {
    canGuestUserCheckout,
  }
}

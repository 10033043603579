import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMinus2 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M4.85001 12.0001C4.85001 11.6411 5.14102 11.3501 5.50001 11.3501H18C18.359 11.3501 18.65 11.6411 18.65 12.0001C18.65 12.3591 18.359 12.6501 18 12.6501H5.50001C5.14102 12.6501 4.85001 12.3591 4.85001 12.0001Z"
      />
    </SvgIcon>
  )
}
export default SvgMinus2

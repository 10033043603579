import React from 'react'
import styled from '@mui/material/styles/styled'
import {
  cmsModuleLateralMargin,
  cmsModuleVerticalMargin,
  teaserBackGroundColorToBg,
} from '../../../../../utils/placements'
import CmsCta from '../../CmsCta'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'

const WrapperCollectionCta = styled('div', {
  name: 'CollectionCta',
  slot: 'Wrapper',
  shouldForwardProp: prop =>
    prop !== 'marginVertical' && prop !== 'backgroundColor' && prop !== 'paddingTopEnabled' && prop !== 'marginLateral',
})<{
  backgroundColor: string
  marginVertical: string
  marginLateral: boolean
  paddingTopEnabled?: boolean
}>(({ backgroundColor, marginVertical, marginLateral, paddingTopEnabled, theme }) => ({
  ...cmsModuleVerticalMargin(marginVertical, theme),
  ...teaserBackGroundColorToBg(backgroundColor, theme),
  ...cmsModuleLateralMargin(marginLateral, theme),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(4),
  padding: theme.spacing(paddingTopEnabled ? 6 : 0, 8, 10),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',

    '.MuiButtonBase-root': {
      width: 'calc(100% / 3 - 8px)',
    },
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(paddingTopEnabled ? 6 : 0, 11, 16),
  },
}))

const CollectionCta: React.FC<{
  backgroundColor: string
  item: ICMCollection | ICMPlaceholder
  marginVertical: string
  marginLateral: boolean
}> = ({ backgroundColor, marginVertical, marginLateral, item }) => {
  if (!item) return null
  const collectionCta = item.teaserLXCallToActionSettings || []

  if (collectionCta.length === 0) return null

  const isProductBanner = ['dcw-products'].includes(item.viewtype || '')
  const collectionBg = isProductBanner ? 'bg-light-secondary' : backgroundColor
  const paddingTopEnabled = collectionCta.length > 0
  return (
    <WrapperCollectionCta
      backgroundColor={collectionBg}
      marginVertical={marginVertical}
      marginLateral={marginLateral}
      paddingTopEnabled={paddingTopEnabled}
    >
      {collectionCta?.map((actionSettings, index) => (
        <CmsCta
          actionSettings={actionSettings}
          data-element-id={`X_X_${index}Placement_Collection_CTA`}
          key={`cms-content__collection--cta-${index}`}
        />
      ))}
    </WrapperCollectionCta>
  )
}

export default CollectionCta

import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'
import { useEffect, useState } from 'react'
import { DomNode, isClient, parseHtmlToDomTree, renderDomTree } from './helpers'

export interface HtmlRendererProps {
  htmlString?: string
  variant: string
  linkTarget?: string
  dataNamePrefix?: string
}

const HtmlRenderer = ({ htmlString, variant, linkTarget, dataNamePrefix }: HtmlRendererProps) => {
  const [domTree, setDomTree] = useState<DomNode[]>([])
  const [error, setError] = useState(false)
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()

  useEffect(() => {
    async function parseHtml() {
      try {
        if (isClient) {
          const parsedTree = parseHtmlToDomTree(replaceLocaleInCmsUrls(htmlString))
          setDomTree(parsedTree)
        }
      } catch (error) {
        console.error('Error rendering HTML:', error)
        setError(true)
      }
    }

    parseHtml()
  }, [htmlString])

  if (error && !domTree.length) {
    return <div>Error: Failed to render content.</div>
  }

  return renderDomTree(domTree, variant, linkTarget, dataNamePrefix)
}

export default HtmlRenderer

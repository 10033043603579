import { HOME } from '@constants/routes'
import Head from 'next/head'
import { FC } from 'react'

const SCHEMA_ORG_CONTEXT = 'https://schema.org'

interface BreadcrumbListItem {
  label: string
  href?: string
}

interface BreadcrumbsSeoHeadProps {
  breadcrumbList: BreadcrumbListItem[]
  homepage: string
}

export const BreadcrumbsSeoHeader: FC<BreadcrumbsSeoHeadProps> = ({ breadcrumbList, homepage }) => {
  if (breadcrumbList.length === 0) {
    return null
  }

  const breadcrumbJson = breadcrumbList.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: item.label,
    item: item.href === HOME ? homepage : item.href,
  }))

  return (
    <Head>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': SCHEMA_ORG_CONTEXT,
          '@type': 'BreadcrumbList',
          itemListElement: breadcrumbJson,
        })}
      </script>
    </Head>
  )
}

import { useSite } from '@foundation/hooks/useSite'
import React, { useState } from 'react'
import useCmsTeaserBanner from '../../../../../hooks/useCmsBanner'
import { ICMArticle } from '../../../../../types/cmsPlacement/CMArticle'
import { IPlacement, isCMArticle, isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { replaceTextFromXStore } from '../../../../../utils/placements'
import { AddCircleIcon, RemoveCircleIcon } from '../../../../UI/Icons'
import {
  StyledContentFaqs,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTitleFaqs,
  StyledWrapperFaqs,
} from './Faqs.style'
import HtmlRenderer from '@components/UI/HtmlRender'

const Faqs: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const articles = placement?.items?.filter(isCMArticle) || []
  const teaser = placement?.items?.find(isLXTeaser)
  const viewType = placement?.viewtype || 'default'
  const { teaserTitleValue, teaserTitle } = useCmsTeaserBanner({ item: teaser, viewType }) || {}

  return (
    <StyledWrapperFaqs>
      <StyledTitleFaqs data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`} variant={'h6'}>
        {teaserTitleValue}
      </StyledTitleFaqs>
      <StyledContentFaqs>
        {articles.map(article => (
          <React.Fragment key={article.id}>
            <AccordionArticle article={article} />
          </React.Fragment>
        ))}
      </StyledContentFaqs>
    </StyledWrapperFaqs>
  )
}

export const AccordionArticle: React.FC<{ article: ICMArticle }> = ({ article }) => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const { mySite } = useSite()

  const detailText = replaceTextFromXStore(article.detailText || '', mySite?.xStoreCfg.shortCodes)
  const handleChange = (id: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : false)
  }

  return (
    <StyledAccordion expanded={expanded === article.id} onChange={handleChange(article?.id!)}>
      <StyledAccordionSummary expandIcon={expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}>
        {article.title}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <HtmlRenderer htmlString={detailText} variant="body1" />
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default Faqs

import Link from 'next/link'
import { componentMap } from '@utils/htmlElements'

export interface DomNode {
  tagName: string | null
  attributes: { name: string; value: string }[]
  textContent: string | null
  children: DomNode[]
}

export const isClient = typeof window !== 'undefined'
const selfClosingElements = ['br', 'hr', 'img', 'input', 'meta', 'link']

export const parseHtmlToDomTree = (htmlString: string): DomNode[] => {
  try {
    if (!isClient) {
      return []
    }

    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')

    const traverseNode = (node: Node): DomNode | null => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement
        const attributes = Array.from(element.attributes).map(attr => ({
          name: attr.name === 'class' ? 'className' : attr.name,
          value: attr.value,
        }))

        const children = Array.from(node.childNodes).map(traverseNode).filter(Boolean) as DomNode[]

        return {
          tagName: element.tagName.toLowerCase(),
          attributes,
          textContent: null,
          children,
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        return {
          tagName: null,
          attributes: [],
          textContent: node.textContent || null,
          children: [],
        }
      }
      return null
    }

    return Array.from(doc.body.childNodes).map(traverseNode).filter(Boolean) as DomNode[]
  } catch (error) {
    console.error('Error parsing HTML string:', error)
    return []
  }
}

export const overrideVariant = (tagName: string, componentProps: Record<string, any>, dynamicVariant: string) => {
  switch (tagName) {
    case 'p':
      componentProps['variant'] = dynamicVariant
      break
    case 'h2':
      componentProps['variant'] = 'h6'
      break
    case 'h3':
      componentProps['variant'] = 'subtitle1'
      break
    default:
      break
  }
}

export const renderDomTree = (
  nodes: DomNode[],
  dynamicVariant: string,
  linkTarget?: string,
  dataNamePrefix?: string
): React.ReactNode => {
  let counter = 1

  return nodes.map((node, index) => {
    if (!node.tagName) {
      return node.textContent
    }

    const Component = componentMap[node.tagName]

    if (!Component) {
      return (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: `<${node.tagName}>${node.textContent}</${node.tagName}>` }}
        />
      )
    }

    const componentProps = node.attributes.reduce(
      (acc, attr) => {
        acc[attr.name] = attr.value
        return acc
      },
      {} as { [key: string]: string }
    )

    overrideVariant(node.tagName, componentProps, dynamicVariant)

    if (node.tagName === 'a') {
      const href = node.attributes.find(attr => attr.name === 'href')?.value || '#'
      return (
        <Link
          key={index}
          href={href}
          target={linkTarget}
          data-name={dataNamePrefix ? `${dataNamePrefix}${counter++}` : ''}
        >
          {node.textContent || renderDomTree(node.children, dynamicVariant, linkTarget)}
        </Link>
      )
    }

    if (selfClosingElements.includes(node.tagName)) {
      return <Component key={index} {...componentProps} />
    }

    return (
      <Component key={index} {...componentProps}>
        {node.textContent || renderDomTree(node.children, dynamicVariant, linkTarget, dataNamePrefix)}
      </Component>
    )
  })
}

import styled from '@mui/material/styles/styled'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { IProduct } from '../../types/product'
import BreadCrumbs from '../UI/BreadCrumbs'
import { Breadcrumbs } from '@components/PagesSeo/product/Product'
import { replaceLocaleInUrl } from '@utils/locale'
import { BreadcrumbsSeoHeader } from './BreadcrumbsSeoHeader'

interface BreadcrumbProps {
  cid: string
  className?: string
  searchTermText: string
  product?: IProduct
  breadcrumbsList?: any[]
  langCode: string
  locale: string
  isLocaleDomain: boolean
  locationOrigin: string
}

export const BreadcrumbsWrapper = styled('div', {
  name: 'BreadcrumbsWrapper',
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4, 0),
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
  },
}))

/**
 * Breadcrumb component
 * displays breadcrumb trail
 * @param props
 */
const BreadcrumbLayout: React.FC<BreadcrumbProps> = ({
  cid,
  className,
  searchTermText,
  breadcrumbsList,
  isLocaleDomain,
  locale,
  locationOrigin,
}) => {
  const { t } = useTranslation()

  const breadcrumbs: Breadcrumbs[] = cid.includes('search-results')
    ? [{ label: searchTermText.replace('*', '') }]
    : breadcrumbsList || []

  const items = [
    {
      href: '/',
      label: t('CommerceEnvironment.breadcrumbs.Home'),
    },
    ...breadcrumbs.map((breadcrumb, index) => {
      const isLast = index === breadcrumbs.length - 1
      const href =
        isLast || !breadcrumb.seo?.href
          ? undefined
          : replaceLocaleInUrl({
              isLocaleDomain,
              locationOrigin,
              locale: locale.replace('_', '-').toLowerCase(),
              href: breadcrumb.seo.href,
            })
      return {
        href,
        label: breadcrumb.label,
      }
    }),
  ]

  return (
    <>
      <BreadcrumbsSeoHeader breadcrumbList={items} homepage={locationOrigin} />
      <BreadcrumbsWrapper className={className || ''}>
        <BreadCrumbs items={items} />
      </BreadcrumbsWrapper>
    </>
  )
}

export default BreadcrumbLayout

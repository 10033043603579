import userContextService from '@foundation/apis/transaction/userContext.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoginIdentityResponse } from '@typesApp/account'
import { IPayloadBasePromoCode } from '@typesApp/common'
import jsonBig from 'json-bigint'
import Log from '../../../services/Log'
import { IContextSliceState } from '../IContextSliceState'
import { evaluateIsPartiallyAuthenticated, currentUserHasWcCookies } from '@utils/user'
import { ILogoutArgs, logout } from '@features/user/thunks/logout'
import Axios, { Canceler } from 'axios'
import { RootReducerState } from '@redux/reducers'
import { isRememberMeEnabledSelector } from '@redux/selectors/site'
import { loginStatusSelector } from '@features/user/selector'
import router from 'next/router'
import { SIGNIN } from '@constants/routes'

export type IFetchUserContextArgs = IPayloadBasePromoCode | LoginIdentityResponse | undefined

const createLogoutPayload = (isRememberMeEnabled: boolean): ILogoutArgs => {
  const CancelToken = Axios.CancelToken
  const cancels: Canceler[] = []
  return {
    cancelToken: new CancelToken(c => cancels.push(c)),
    skipRedirect: true,
    ...(isRememberMeEnabled && { rememberMe: false, updateCookies: true }),
  }
}

const userIdMatchesRunAsId = (userPayload: IContextSliceState, isUserLoggedIn: boolean): boolean => {
  const runAsId = userPayload?.basicInfo?.runAsId?.toString()
  const callerId = userPayload?.basicInfo?.callerId?.toString()
  return currentUserHasWcCookies(runAsId, callerId, isUserLoggedIn)
}

export const fetchUserContext = createAsyncThunk<IContextSliceState, IFetchUserContextArgs>(
  'context/fetchUserContext',
  async (args, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootReducerState
      const isRememberMeEnabled = isRememberMeEnabledSelector(state) ?? false
      const isUserLoggedIn = loginStatusSelector(state) ?? false

      const logoutPayload = createLogoutPayload(isRememberMeEnabled)

      const response = await userContextService.get(args)
      const userPayload = jsonBig.parse(response?.request.response || '{}')
      userPayload.entitlement.currentTradingAgreementIds[0] =
        userPayload.entitlement.currentTradingAgreementIds[0].toString()

      if (!userIdMatchesRunAsId(userPayload, isUserLoggedIn)) {
        await dispatch(logout(logoutPayload))
        router.push({
          pathname: `/${SIGNIN}`,
          query: { sessionExpired: 'true' },
        })
        return rejectWithValue({ error: true })
      }

      return {
        ...userPayload,
        isPartiallyAuthenticated: evaluateIsPartiallyAuthenticated(userPayload),
      }
    } catch (e) {
      Log.error('Error in retrieving context: ' + e)
      return rejectWithValue(e)
    }
  }
)

import React from 'react'
import { ToggleButtons } from '@components/UI/Toggle'
import { IPlpProductResponse, IPlpProduct, IPlpProductArgs } from '../../../../../features/plp/query'
import { fromUrlParamsToObject, formattedParams } from '@utils/url'
import { LX_SEARCH_PAGE_PRODUCT_TYPE } from '@constants/product'
import { useRouter } from 'next/router'

export const getFacetFromSuggestedProducts = (facets: IPlpProduct['facets'] = []) => {
  const suggestedProductFacet = facets.find(facet => facet.name === LX_SEARCH_PAGE_PRODUCT_TYPE)
  return suggestedProductFacet
    ? suggestedProductFacet.entry.map(entry => {
        return {
          value: entry.value,
          label: entry.label,
        }
      })
    : []
}

/*const getFacetFromSuggestedProducts = (
  facets: IPlpProduct['facets'] = [],
  plpFacets: IPlpFacets
) => {
  const suggestedProductFacet = facets.find(
    (facet) => facet.name === LX_SEARCH_PAGE_PRODUCT_TYPE
  )

  const plpList = Object.keys(plpFacets)
  if (suggestedProductFacet)
    return suggestedProductFacet.entry.filter((entry) => {
      if (plpList.includes(entry.label)) {
        return {
          value: entry.value,
          text: entry.label,
        }
      } else return
    })
  return []
}*/
const PlpHeaderSuggested: React.FC<{ facets: IPlpProductResponse['facets'] }> = ({ facets }) => {
  const router = useRouter()
  const basePath = router.asPath.split('?')[0]
  const searchParams = router.asPath.split('?')[1]
  const queryParams: IPlpProductArgs = fromUrlParamsToObject(searchParams)
  /* const plpFacets = useSelector(plpFacetsSelector)*/
  if (facets.length < 2) return null
  const suggestedProductFacet = getFacetFromSuggestedProducts(
    facets
    //plpFacets!
  )

  const searchTerm = queryParams.searchTerm ?? undefined
  const queryFacets = queryParams.facet
    ? (queryParams.facet = Array.isArray(queryParams.facet) ? queryParams.facet : [queryParams.facet])
    : []

  const controlQueryFacetSelected =
    suggestedProductFacet.find(item => queryFacets?.includes(item?.value))?.value ?? null

  const defaultValue = controlQueryFacetSelected ? controlQueryFacetSelected : null

  const onChangeFilter = (_event: React.MouseEvent<HTMLElement>, facet: string | null) => {
    const currentSelectedFacets = queryFacets
    const index = currentSelectedFacets.indexOf(facet ?? '')

    if (index > -1) {
      const controlFacets = [...currentSelectedFacets]
      controlFacets.splice(index, 1)

      const params: IPlpProductArgs = {
        searchTerm: searchTerm || undefined,
        sortBy: queryParams.sortBy || undefined,
        facet: controlFacets,
      }

      const newParams = formattedParams(params)
      const url = `${basePath}${newParams}`
      router.push(url, undefined, { shallow: true })
    } else {
      const newParams = formattedParams({
        searchTerm,
        sortBy: queryParams.sortBy || undefined,
        facet,
      })
      const url = `${basePath}${newParams}`
      router.push(url, undefined, { shallow: true })
    }
  }
  return suggestedProductFacet.length > 1 ? (
    <ToggleButtons
      variant={'primary'}
      togglesData={suggestedProductFacet}
      defaultValue={defaultValue}
      onChangeValue={onChangeFilter}
    />
  ) : null
}

export default PlpHeaderSuggested

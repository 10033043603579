import config from '@configs/index'
import { SEO_PAGE_TYPES } from '@foundation/hooks/useSeoComponent'
import { ServerResponse } from 'http'
type Header = [string, string]
type Headers = Header[]

export const PAGE_TYPES = {
  ...SEO_PAGE_TYPES,
  HOME: 'Home',
  STATIC_PAGE: 'StaticPage',
  SITEMAP: 'Sitemap',
  SIGNIN: 'Signin',
  CHECKOUT: 'Checkout',
  ORDER_CONFIRMATION: 'OrderConfirmation',
}

const getEdgeCacheTag = ({
  pageType,
  locale,
  plpIdentifier,
  pdpPartnumber,
}: {
  pageType: string
  locale: string | undefined
  plpIdentifier?: string
  pdpPartnumber?: string
}): Header => {
  const valueBase = `${config.name}_${process.env.NODE_ENV}_next`

  const cacheTagKeys = {
    [PAGE_TYPES.HOME]: [pageType, locale],
    [PAGE_TYPES.STATIC_PAGE]: [pageType, locale],
    [PAGE_TYPES.PRODUCT_LIST_PAGE]: [pageType, locale, plpIdentifier],
    [PAGE_TYPES.CATEGORY_PAGE]: [pageType, locale, plpIdentifier],
    [PAGE_TYPES.ITEM_PAGE]: [pageType, locale, pdpPartnumber],
    [PAGE_TYPES.PRODUCT_PAGE]: [pageType, locale, pdpPartnumber],
    [PAGE_TYPES.ORDER_CONFIRMATION]: [pageType, locale],
  }

  const value = cacheTagKeys[pageType].reduce(
    (acc, curr, index) => {
      if (curr) {
        const prev = acc[Math.max(index, 0)]
        if (prev) {
          acc.push(`${prev}_${curr}`)
        }
      }
      return acc
    },
    [valueBase]
  )
  return ['Edge-Cache-Tag', value.join(',')]
}

type getPageHeadersProps = {
  locale: string | undefined
  pdpPartnumber?: string
  plpIdentifier?: string
}

const pageHeaders: Record<string, (options: getPageHeadersProps) => Headers> = {
  [PAGE_TYPES.HOME]: ({ locale }) => [getEdgeCacheTag({ pageType: PAGE_TYPES.HOME, locale })],
  [PAGE_TYPES.STATIC_PAGE]: ({ locale }) => [getEdgeCacheTag({ pageType: PAGE_TYPES.STATIC_PAGE, locale })],
  [PAGE_TYPES.PRODUCT_LIST_PAGE]: ({ locale, plpIdentifier }) => [
    getEdgeCacheTag({ pageType: PAGE_TYPES.PRODUCT_LIST_PAGE, locale, plpIdentifier }),
  ],
  [PAGE_TYPES.CATEGORY_PAGE]: ({ locale, plpIdentifier }) => [
    getEdgeCacheTag({ pageType: PAGE_TYPES.CATEGORY_PAGE, locale, plpIdentifier }),
  ],
  [PAGE_TYPES.ITEM_PAGE]: ({ locale, pdpPartnumber }) => [
    getEdgeCacheTag({ pageType: PAGE_TYPES.ITEM_PAGE, locale, pdpPartnumber }),
  ],
  [PAGE_TYPES.PRODUCT_PAGE]: ({ locale, pdpPartnumber }) => [
    getEdgeCacheTag({ pageType: PAGE_TYPES.PRODUCT_PAGE, locale, pdpPartnumber }),
  ],
  [PAGE_TYPES.ORDER_CONFIRMATION]: ({ locale }) => [
    getEdgeCacheTag({ pageType: PAGE_TYPES.ORDER_CONFIRMATION, locale }),
  ],
}

export const getHeaders = ({
  pageType,
  locale,
  pdpPartnumber,
  plpIdentifier,
}: {
  pageType: string | undefined
  locale: string | undefined
  pdpPartnumber?: string
  plpIdentifier?: string
}): Headers => {
  if (!pageType) return []

  return pageHeaders[pageType]?.({ locale, pdpPartnumber, plpIdentifier }) || []
}

export const setHeaders = (res: ServerResponse, headers: Headers) => {
  headers.forEach(h => res.setHeader(h[0], h[1]))
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SubscriptionModuleValue } from '@components/UI/SubscriptionModule/types/SubscriptionModule'
export interface CheckoutState {
  billingAddressId: string | null
  shippingAddressId: string | null
  paymentMethodId: string | null
  walletId: string | null
  orderType: SubscriptionModuleValue | null
}

export const initialState: CheckoutState = {
  billingAddressId: '',
  shippingAddressId: '',
  paymentMethodId: '',
  walletId: '',
  orderType: null,
}

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateBillingAddressId(state, action: PayloadAction<string>) {
      state.billingAddressId = action.payload
    },
    updateShippingAddressId(state, action: PayloadAction<string>) {
      state.shippingAddressId = action.payload
    },
    updateWalletId(state, action: PayloadAction<string>) {
      state.walletId = action.payload
    },
    updatePaymentMethodId(state, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload
    },
    setOrderType(state, action: PayloadAction<SubscriptionModuleValue>) {
      state.orderType = action.payload
    },
    resetCheckout: () => initialState,
  },
})

export const {
  updateBillingAddressId,
  updateShippingAddressId,
  updatePaymentMethodId,
  updateWalletId,
  resetCheckout,
  setOrderType,
} = checkoutSlice.actions

export default checkoutSlice.reducer

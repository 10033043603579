import { CrossOriginAnonymous } from '@typesApp/product'
import Head from 'next/head'
import React from 'react'

interface SeoProps {
  deskL?: string
  deskS?: string
  tabletL?: string
  tabletP?: string
  mobile?: string
}

export const Seo: React.FC<SeoProps> = ({ deskL, deskS, tabletL, tabletP, mobile }) => {
  return (
    <Head>
      {deskL && (
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          media="(min-width: 1440px)"
          href={deskL}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {deskS && (
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          media="(min-width: 1280px) and (max-width: 1439px)"
          href={deskS}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {tabletL && (
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          media="(min-width: 1024px) and (max-width: 1279px)"
          href={tabletL}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {tabletP && (
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          media="(min-width: 601px) and (max-width: 1023px)"
          href={tabletP}
          crossOrigin={CrossOriginAnonymous}
        />
      )}
      {mobile && (
        <link rel="preload" fetchpriority="high" as="image" href={mobile} crossOrigin={CrossOriginAnonymous} />
      )}
    </Head>
  )
}

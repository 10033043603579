import { Button } from '@components/UI/Button'
import { IconButton } from '@components/UI/IconButton'

import { Trans, useTranslation } from 'next-i18next'
import { ChangeEvent, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  StyledCloseWrapper,
  StyledFormButtons,
  StyledNewsLetterRow,
  StyledHeader,
  StyledNewsletterContainer,
  StyledNewsletterPrivacyText,
  StyledNewsletterTypographyTitle,
  StyledThankYouContainer,
  StyledNewsletterSignupSuccess,
  StyledNewsLetterInput,
  StyledNewsletterDescriptionWrapper,
} from './NewsLetterDrawerContent.style'

import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { isLXTeaser, isPictureMedia } from '@typesApp/teaser'
import { isEmpty, normalizedTeaserText } from '@utils/helpers'
import config from '../../../../configs'
import { setOpenDrawerNewsLetter } from '../../../../features/ui/action'
import { sendNewsletterSubscriptionEvent } from '../../../../foundation/analytics/tealium/lib'
import { NewsletterService } from '../../../../foundation/apis/newsletter/newsletter.service'
import { useSite } from '../../../../foundation/hooks/useSite'
import { newsletterPromoCodeSelector } from '../../../../redux/selectors/site'
import { Typography } from '@mui/material'
import { IconCross } from '@components/UI/Icons/VD/General'
import addressUtil from '@utils/addressUtil'
import { useRouter } from 'next/router'
import { replaceLocaleInUrl } from '@utils/locale'
import { CrossOriginAnonymous } from '@typesApp/product'
import Link from '@components/UI/Link'

interface NewsletterDrawerContentProps {
  newsLetterPopup: IPlacement
}

type ResultObject = {
  error?: boolean
  text?: string
}

const NewsletterImage: FC<{ src: string; alt: string }> = props => {
  const [src, setSrc] = useState<string>(props.src)
  const { alt } = props
  const handleError = () => setSrc(process.env.PUBLIC_URL + '/images/common/404.svg')

  return <img {...props} alt={alt} src={src} width={'100%'} onError={handleError} crossOrigin={CrossOriginAnonymous} />
}

const NewsletterDrawerContent: FC<NewsletterDrawerContentProps> = ({ newsLetterPopup }) => {
  const { t } = useTranslation()
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const [error, setError] = useState(false)
  const [result, setResult] = useState<ResultObject>({})
  const [email, setEmail] = useState('')

  const newsletterItems = newsLetterPopup.items?.find(isLXTeaser)
  const title = newsletterItems?.teaserTitle1 || ''
  const description = normalizedTeaserText(newsletterItems?.teaserText1)
  const media = newsletterItems?.media?.find(isPictureMedia)
  const newsletterPromoCode = useSelector(newsletterPromoCodeSelector)

  if (!media) return null

  const image = `${config.cmsImageServerUrl}${media.uriTemplate.replace('{cropName}/{width}/', '')}`

  const closeDrawer = () => {
    dispatch(setOpenDrawerNewsLetter(false))
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError(!addressUtil.validateEmail(e.target.value))
    setResult({})
    setEmail(e.target.value)
    setSubmitEnabled(addressUtil.validateEmail(e.target.value))
  }

  const subscribe = async () => {
    if (addressUtil.validateEmail(email)) {
      try {
        const data = await NewsletterService.subscribeToNewsletter({
          email,
          newsletter_id: mySite.xStoreCfg?.newsletterSubscrType ?? 'NEWSLETTER',
          from: 'footer',
          storeId: mySite.storeID,
        })
        if (data.code === '200') {
          setResult({
            error: false,
            text: t('Newsletter.Success'),
          })
          sendNewsletterSubscriptionEvent(email)
        } else if (data.code === '409') {
          setResult({
            error: true,
            text: t('NewsLetterDrawerContent.Errors.Duplicate'),
          })
          sendNewsletterSubscriptionEvent(email)
        } else
          setResult({
            error: true,
            text: t('NewsLetterDrawerContent.Errors.Generic'),
          })
      } catch (e: any) {
        setResult({
          error: true,
          text: t('NewsLetterDrawerContent.Errors.Generic'),
        })
      }
    } else {
      setError(true)
    }
  }

  return (
    <StyledNewsletterContainer>
      <StyledHeader>
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton data-analytics_available_call="0" onClick={closeDrawer}>
          <StyledCloseWrapper>
            <IconCross color="inherit" />
          </StyledCloseWrapper>
        </IconButton>
      </StyledHeader>
      {isEmpty(result) || result.error ? (
        <>
          <StyledNewsletterDescriptionWrapper>
            <Typography variant="body2">{description}</Typography>
          </StyledNewsletterDescriptionWrapper>
          <NewsletterImage src={image} alt={t('NewsLetterDrawerContent.SignUpCTA')} />
          <StyledNewsLetterRow>
            <StyledNewsLetterInput
              error={error || result.error}
              helperText={
                error && isEmpty(result) ? t('NewsLetterDrawerContent.Errors.Input') : result.error ? result.text : null
              }
              variant="outlined"
              margin="none"
              fullWidth
              name="email"
              label={t('NewsLetterDrawerContent.EmailAddress')}
              type="email"
              id="email"
              value={email}
              required
              showvalidationstatus
              onChange={onChange}
            />
            <Typography variant="body2">
              <StyledNewsletterPrivacyText>
                <Trans
                  t={t}
                  i18nKey={'NewsLetterDrawerContent.PrivacyText'}
                  components={[
                    <Link href={t('NewsLetterDrawerContent.PrivacyPolicyUrl')} key={0} onClick={closeDrawer}>
                      {t('RegistrationLayout.PrivacyPolicyUrlText')}
                    </Link>,
                  ]}
                />
              </StyledNewsletterPrivacyText>
            </Typography>
            <StyledFormButtons>
              <Button
                data-element-id={'Newsletter_Subscribe'}
                fullWidth
                fillType={'fill'}
                onClick={subscribe}
                disabled={!submitEnabled}
              >
                {t('NewsLetterDrawerContent.SubscribeCTA')}
              </Button>
            </StyledFormButtons>
          </StyledNewsLetterRow>
        </>
      ) : (
        <StyledThankYouContainer>
          <NewsletterImage src={image} alt={t('NewsLetterDrawerContent.SignUpSuccess')} />
          <StyledNewsLetterRow>
            <StyledNewsletterTypographyTitle>
              <Typography variant="h6">{t('NewsLetterDrawerContent.SignUpSuccess')}</Typography>
            </StyledNewsletterTypographyTitle>

            {newsletterPromoCode && (
              <StyledNewsletterSignupSuccess>
                <Typography variant="body2">{t('NewsLetterDrawerContent.SignUpSuccessSubtitle')}</Typography>
              </StyledNewsletterSignupSuccess>
            )}
          </StyledNewsLetterRow>
        </StyledThankYouContainer>
      )}
    </StyledNewsletterContainer>
  )
}

export default NewsletterDrawerContent

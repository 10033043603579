import { FormControl, InputAdornmentProps, OutlinedInputProps } from '@mui/material'
import { EyeContactLensAttribute } from '@typesApp/prescription'
import { useTranslation } from 'next-i18next'
import {
  StyledIconButton,
  StyledIconMinusThree,
  StyledIconPlusTwo,
  StyledInputAdornment,
  StyledOutlinedInput,
} from './ContactLensSelection.style'

interface NumberInputProps extends Omit<OutlinedInputProps, 'value' | 'onChange'> {
  value: number | string
  onChange: (eye: string, id: EyeContactLensAttribute, val: string) => void
  min?: number
  max?: number
  eye: string
  id: EyeContactLensAttribute
  disabled?: boolean
}

export interface StyledInputAdornmentProps extends InputAdornmentProps {
  increment?: boolean
  disabled?: boolean
}

const NumberInput: React.FC<NumberInputProps> = ({ onChange, value = 1, min = 1, max, id, eye, disabled }) => {
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value
    if (Number(v) >= (min || 1) && Number(v) <= (max || Infinity)) onChange(eye, id, v)
  }
  return (
    <FormControl variant="outlined">
      <StyledOutlinedInput
        type="number"
        disabled={disabled}
        readOnly={true}
        inputProps={{
          style: {
            textAlign: 'center',
          },
        }}
        value={value}
        onChange={handleChange}
        id="number-input"
        data-name={eye + '_quantity'}
        startAdornment={
          <StyledInputAdornment position="start" disabled={disabled || Number(value) - 1 < (min || 1) || disabled}>
            <StyledIconButton
              aria-label={t('ContactLenses.Labels.Increase')}
              edge="end"
              onClick={() => {
                if (Number(value) - 1 < (min || 1) || disabled) return
                onChange(eye, id, String(Number(value) - 1))
              }}
            >
              <StyledIconMinusThree />
            </StyledIconButton>
          </StyledInputAdornment>
        }
        endAdornment={
          <StyledInputAdornment
            position="end"
            increment
            disabled={disabled || Number(value) + 1 > (max || Infinity) || disabled}
          >
            <StyledIconButton
              aria-label={t('ContactLenses.Labels.Decrease')}
              edge="start"
              onClick={() => {
                if (Number(value) + 1 > (max || Infinity) || disabled) return
                onChange(eye, id, String(Number(value) + 1))
              }}
            >
              <StyledIconPlusTwo />
            </StyledIconButton>
          </StyledInputAdornment>
        }
      />
    </FormControl>
  )
}

export default NumberInput

import React from 'react'
import { ITeaserOverlayStyle } from '../../../../../types/cmsPlacement/LXTeaser'
import styled from '@mui/material/styles/styled'
import { cmsModuleLateralMargin, teaserBackGroundColorToBg, teaserOverlayStyleToColor } from '@utils/placements'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import { isCMCollection } from '@typesApp/cmsPlacement/Placement'
import HtmlRenderer from '@components/UI/HtmlRender'
import { Typography } from '@mui/material'

const StyledWrapperCollectionText = styled('div', {
  name: 'CollectionText',
  slot: 'Wrapper',
  shouldForwardProp: prop =>
    prop !== 'backgroundColor' &&
    prop !== 'textOverlayStyle' &&
    prop !== 'paddingBottomEnabled' &&
    prop !== 'marginLateral',
})<{
  backgroundColor: string
  textOverlayStyle: ITeaserOverlayStyle
  paddingBottomEnabled?: boolean
  marginLateral: boolean
}>(({ backgroundColor, textOverlayStyle, paddingBottomEnabled, marginLateral, theme }) => ({
  ...teaserBackGroundColorToBg(backgroundColor, theme),
  ...teaserOverlayStyleToColor(theme, textOverlayStyle),
  ...cmsModuleLateralMargin(marginLateral, theme),

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(16, 8, paddingBottomEnabled ? 6 : 0),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(4),
    padding: theme.spacing(16, 8, paddingBottomEnabled ? 6 : 0),
  },
}))

const CollectionContentText: React.FC<{
  item: ICMCollection | ICMPlaceholder
  backgroundColor: string
  marginLateral: boolean
}> = ({ item, backgroundColor, marginLateral }) => {
  if (!item) return null

  const collectionTitle = item.collectionTitle || ''
  const collectionSubTitle = item.collectionSubTitle || ''
  const collectionText = item.collectionText || ''

  if (!collectionTitle && !collectionSubTitle && !collectionText) return null

  const collectionTextOverlayStyle = isCMCollection(item)
    ? item.collectionTextOverlayStyle
      ? item.collectionTextOverlayStyle
      : ''
    : ''
  const isProductBanner = ['dcw-products'].includes(item.viewtype)
  const collectionBg = isProductBanner ? 'bg-light-secondary' : backgroundColor

  const paddingBottomEnabled = !!(collectionTitle || collectionSubTitle || collectionText)
  return (
    <StyledWrapperCollectionText
      backgroundColor={collectionBg}
      textOverlayStyle={collectionTextOverlayStyle}
      paddingBottomEnabled={paddingBottomEnabled}
      marginLateral={marginLateral}
    >
      {collectionTitle && (
        <Typography variant="h6" component="h2">
          {collectionTitle}
        </Typography>
      )}
      {collectionSubTitle && <Typography variant="body1">{collectionSubTitle}</Typography>}
      {collectionText && <HtmlRenderer htmlString={collectionText} variant="body1" />}
    </StyledWrapperCollectionText>
  )
}

export default CollectionContentText

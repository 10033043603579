import { Wallet, GeneralUserFields } from './user'
import { GeneralFormFields } from './form'

export interface SubscriptionData {
  recordSetComplete: boolean
  recordSetCount: number
  recordSetTotal: number
  resourceId: string
  resourceName: string
  resultList: SubscriptionResult[]
}

export interface SubscriptionResult {
  subscriptionInfo: SubscriptionInfo
  state: SUBSCRIPTION_STATES
  purchaseDetails: PurchaseDetails
  subscriptionIdentifier: SubscriptionIdentifier
}

export interface SubscriptionInfo {
  userData: UserData
  fulfillmentSchedule: FulfillmentSchedule
  paymentSchedule: PaymentSchedule
  paymentInfo: PaymentInfo
}

export interface UserData {
  userDataField: UserDataField[]
}

export interface UserDataField {
  value: string
  key: string
}

export interface FulfillmentSchedule {
  startInfo: StartInfo
  userData: null | Record<string, unknown>
  frequencyInfo: FrequencyInfo
  endInfo: EndInfo
}

export interface StartInfo {
  userData: null | Record<string, unknown>
  startDate: string
}

export interface FrequencyInfo {
  nextOccurence: string
  userData: null | Record<string, unknown>
  remainingOccurrences: null | number
  completedOccurrences: null | number
  frequency: Frequency
}

export interface Frequency {
  uom: string
  value: number
}

export interface EndInfo {
  duration: null | number
  totalOccurrences: null | number
  userData: null | Record<string, unknown>
  endDate: string
}

export interface PaymentSchedule {
  startInfo: null | Record<string, unknown>
  userData: UserData
  frequencyInfo: FrequencyInfo
  endInfo: null | Record<string, unknown>
}

export interface PaymentInfo {
  userData: UserData
  amountPaid: null | number
  amountToCharge: null | number
  totalCost: TotalCost
}

export interface TotalCost {
  currency: string
  value: number
}

export interface PurchaseDetails {
  userData: UserData
  parentOrderIdentifier: ParentOrderIdentifier
}

export interface ParentOrderIdentifier {
  parentOrderId: string
}

export interface SubscriptionIdentifier {
  subscriptionId: string
}

export interface ProductData {
  productImgUrl: string
  productName: string
  productUrl: string
  x_contactLens: Record<string, unknown>
}

export interface SubscriptionCardData {
  subscriptionId: string
  status: SubscriptionStatus
  frequency: string
  estimatedDeliveryDate: string | null
  manageSubscriptionLabel: string
  productImageUrl: string
  orderItemsCount: number
}

export interface SubscriptionStatus {
  status: SUBSCRIPTION_STATES | null
  statusLabelText: SUBSCRIPTION_STATES_LABELS | null
  statusLabelColor: string
  isEditable: boolean
  badgeColor: string
  message: string
  Icon: any
  color: string
}

export enum SUBSCRIPTION_STATES {
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
  CANCELLED = 'Cancelled',
}

export enum SUBSCRIPTION_STATES_LABELS {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  CANCELLED = 'Cancelled',
}

export enum SUBSCRIPTION_FREQUENCY_UNITS {
  WEEK = 'WEEK',
  MONTH = 'MON',
}

export enum ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS {
  INACTIVE = '0',
  ACTIVE = '1',
  CANCELLED = '3',
}

export interface SubscriptionStateToggleResponse {
  subscriptionType: [string]
  resourceName: string
  state: [SUBSCRIPTION_STATES]
  subscriptionId: [string]
}

type SubscriptionWallet = Pick<Wallet, 'issuer' | 'payMethodId' | 'protectedCCNumber' | 'expireDate'> & {
  walletId: string
}

export type Address = Required<
  Pick<GeneralFormFields, 'addressId' | 'city' | 'country' | 'addressType' | 'state' | 'zipCode' | 'addressLine'>
> &
  Pick<GeneralUserFields, 'firstName' | 'lastName' | 'phone1' | 'personTitle'> & {
    buzzerCode: string
  }

export interface SubscriptionDetails extends SubscriptionData {
  x_data: SubscriptionDetailXData
}

export interface SubscriptionDetailXData {
  wallet: SubscriptionWallet
  prescriptionId: string
  statusReason: string
  nextDeliveryDate: string
  products: string
  addresses: Address[]
}

export interface DeliveryDateUpdateResponse {
  nextFulfillmentDate: string
  resourceName: string
  subscriptionId: number
}

export interface DeliveryFrequencyUpdateResponse {
  orderId: number
  resourceName: string
}

import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV3 } from '../../constants/ui'

export const WrapperHeader = styled('header', {
  shouldForwardProp: prop => prop !== 'isSticky',
  name: 'Header',
  slot: 'Wrapper',
})<{ isSticky: boolean | null }>(({ theme, isSticky }) => ({
  position: isSticky ? 'sticky' : 'fixed',
  visibility: 'visible',
  top: 0,
  left: 0,
  zIndex: Z_INDEX_LV3,
  transform: isSticky || isSticky === null ? 'translateY(0%)' : 'translateY(-100%)',
  transition: `all ${theme.transitions.duration.short}ms ease-in-out`,
  backgroundColor: theme.palette.common.white,
  height: 'max-content',
  minHeight: 'max-content',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    transform: 'translateY(0)',
  },

  [theme.breakpoints.down('md')]: {
    transform: 'translateY(0)',
  },
}))

export const BenefitContainer = styled('div', {
  name: 'Header',
  slot: 'BenefitContainer',
})(() => ({}))

export const HamburgerMenuGreyBackground = styled('div', {
  name: 'HamburgerMenu',
  slot: 'OpacityLayer',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV3,
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  background: theme.palette.common.white,
  opacity: 0.5,
  top: 0,
}))

export const StyledKeysellingBarWrapper = styled('div', {
  name: 'KeysellingBarWrapper',
  shouldForwardProp: prop => prop !== 'marginTop',
})<{ marginTop: number }>(({ marginTop, theme }) => ({
  marginTop: `${marginTop}px`,

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}))

import { memo } from 'react'
import { IPlacement } from '../../../types/cmsPlacement/Placement'
import { IViewType } from '../../../types/cmsPlacement/ViewType'
import { AssociatedProduct, IProduct } from '../../../types/product'
import LoadingSkeleton from '../CmsComponents/CmsCommonMedia/LoadingSkeleton'
import PlacementsSwitch from '../PlacementsSwitch'
import clsx from 'clsx'

const activePlacements: IViewType[] = [
  'full-width-banner',
  'landscape-banner',
  'boards-with-fields-below',
  'text-module',
  'squat-banner',
  'top-page-banner',
  'box-with-margin',
  'grid-of-boards-two-columns',
  'grid-of-boards-three-columns',
  'square-boards-with-split',
  'square-boards-without-split',
  'faqs',
  'grid-of-products',
  'dcw-products',
  'combo-mini-slider-plus-box-all-fields',
  'query-list',
  'wall-of-brands',
  'you-may-also-like',
  'size-guide',
  'Algolia Recommendations',
  'anchor-button',
  'm-04.1-grid-of-cards',
  'box-and-2-products',
  'box-and-4-products',
  'single-banner-with-side-fields',
  'm-social-video',
  'plain-slider',
  'registration-form-module-1',
  'registration-form-module-2',
  'trust-pilot',
]

const PlacementLayout: React.FC<{
  placements: IPlacement[]
  currentProduct?: IProduct
  suggestedProducts?: AssociatedProduct[]
  loading?: boolean
  isPLP?: boolean
  contentVisibilityStart?: number
  className?: string
}> = ({
  placements,
  currentProduct,
  suggestedProducts,
  loading,
  isPLP = false,
  contentVisibilityStart = 1,
  className,
}) => {
  return (
    <div className={clsx('placements-container', className)}>
      {loading ? (
        <LoadingSkeleton height={'100vh'} />
      ) : placements && placements.length === 0 ? null : (
        <PlacementsSwitch
          placements={placements}
          currentProduct={currentProduct}
          suggestedProducts={suggestedProducts}
          activePlacements={activePlacements}
          isPLP={isPLP}
          contentVisibilityStart={contentVisibilityStart}
        />
      )}
    </div>
  )
}

export default memo(PlacementLayout)

import KlarnaOnSiteMessage from '@components/Klarna/KlarnaOnSiteMessage'
import { useTranslation } from 'next-i18next'
import {
  KlarnaMessageContainerPDP,
  ProductCurrentPriceContainer,
  ProductPriceContainer,
  ProductPriceContainerPDP,
  ProductPriceCurrentPrice,
  ProductPriceDetails,
  ProductPriceInitialPrice,
  ProductPriceInitialPricePDP,
  ProductPriceLabel,
  ProductPricePricePerBox,
  ProductPricePricePerBoxContainer,
  ProductPricePricePerBoxCurrentPrice,
  ProductPricePricePerBoxInitialPrice,
  ProductPricePricePerBoxPrices,
  ProductPriceWrapper,
  StyledProductTilePriceContainer,
  StyledProductPricePerLitre,
} from './ProductPrice.style'
import ProductTilePrice from './ProductTilePrice'
import { TPrice } from '@components/common/UI/ProductPrice/index.types'
import { getFinalPrice, getPriceLabel } from '@components/common/UI/ProductPrice/utils/utils'
import Price from './ProductPrice/Price'
import { StyledPricePerLiter } from '@components/ProductTile/components/ProductTile.style'
import { useSite } from '@foundation/hooks/useSite'

export interface ProductPriceNew {
  price: TPrice | null
  isPDP?: boolean
  totalBoxes?: number
  isCL?: boolean
  productQuantity?: string[]
  isCLAccessories?: boolean
  isCompact?: boolean
  isRoxable?: boolean
  productType: string
  isForSuggestedProduct?: boolean
  showPricePerBox?: boolean
  showTotalPrice?: boolean
  showSalePrice?: boolean
  locale?: string
}

const ProductPriceNew: React.FC<ProductPriceNew> = ({
  price,
  isPDP = false,
  totalBoxes = 2,
  isCL,
  productQuantity,
  isCLAccessories,
  isCompact = false,
  productType,
  isForSuggestedProduct = false,
  showPricePerBox = false,
  showTotalPrice = false,
  showSalePrice = true,
  locale,
}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const isDE = mySite.country === 'de'

  if (!price) return null

  const initialPrice = price.listPrice ? +price.listPrice : null
  const currentPrice = price.offerPrice ? +price.offerPrice : null
  const hasInitialPriceDiscount = 'showListPrice' in price ? price.showListPrice : false

  const quantity = (productQuantity && parseInt(productQuantity[0])) ?? totalBoxes
  const currency = price.currency
  const pricePerLtr = price.pricePerLtr || price.priceperltr
  const perBoxText = isCLAccessories ? t('CLA.Labels.PerBox') : t('ContactLenses.Labels.PerBox')

  const hasPricePerLitre = isCLAccessories && pricePerLtr && isDE

  return (
    <ProductPriceWrapper
      className={`${initialPrice !== undefined ? 'product-price' : 'product-price no-discount'} ${isPDP ? 'pdp' : 'plp'}`}
    >
      {isPDP ? (
        <>
          {(isCL || isCLAccessories) && showPricePerBox && (
            <ProductPricePricePerBoxContainer>
              <ProductPricePricePerBox variant="body1">{perBoxText}</ProductPricePricePerBox>
              <ProductPricePricePerBoxPrices>
                {hasInitialPriceDiscount && (
                  <ProductPricePricePerBoxInitialPrice variant="body2">
                    <Price price={initialPrice} currency={currency} />
                  </ProductPricePricePerBoxInitialPrice>
                )}
                {currentPrice && (
                  <ProductPricePricePerBoxCurrentPrice variant="body1">
                    <Price price={currentPrice} currency={currency} />
                  </ProductPricePricePerBoxCurrentPrice>
                )}
              </ProductPricePricePerBoxPrices>
            </ProductPricePricePerBoxContainer>
          )}
          {showTotalPrice && (
            <ProductPriceContainerPDP isForSuggestedProduct={isForSuggestedProduct}>
              <ProductPriceLabel>{!isForSuggestedProduct && t(getPriceLabel(productType))}</ProductPriceLabel>
              <ProductPriceDetails>
                {hasInitialPriceDiscount && (
                  <ProductPriceInitialPricePDP>
                    <Price
                      price={getFinalPrice(initialPrice, totalBoxes, isCL, isCLAccessories, quantity)}
                      currency={currency}
                    />
                  </ProductPriceInitialPricePDP>
                )}
                {currentPrice && (
                  <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact}>
                    <Price
                      price={getFinalPrice(currentPrice, totalBoxes, isCL, isCLAccessories, quantity)}
                      currency={currency}
                    />
                  </ProductPriceCurrentPrice>
                )}
              </ProductPriceDetails>
            </ProductPriceContainerPDP>
          )}
          {showSalePrice && price && (
            <StyledProductTilePriceContainer>
              <ProductTilePrice
                offerPrice={<Price price={currentPrice} currency={price.currency} />}
                rrp={hasInitialPriceDiscount ? <Price price={initialPrice} currency={price.currency} /> : <></>}
              />
              {hasPricePerLitre && (
                <StyledProductPricePerLitre variant="caption">
                  (1 l = <Price price={pricePerLtr} currency={price.currency} />)
                </StyledProductPricePerLitre>
              )}
            </StyledProductTilePriceContainer>
          )}
          {!isForSuggestedProduct && (
            <KlarnaMessageContainerPDP>
              <KlarnaOnSiteMessage
                variant={'credit-promotion-auto-size'}
                grandTotal={
                  !!currentPrice ? getFinalPrice(currentPrice, totalBoxes, isCL, isCLAccessories, quantity) || '' : ''
                }
              />
            </KlarnaMessageContainerPDP>
          )}
        </>
      ) : (
        <>
          <ProductPriceContainer isPDP={isPDP} isCompact={isCompact} isForSuggestedProduct={isForSuggestedProduct}>
            <ProductPriceDetails>
              {hasInitialPriceDiscount && (
                <ProductPriceInitialPrice>
                  <Price price={initialPrice} currency={currency} />
                </ProductPriceInitialPrice>
              )}
              {currentPrice && (
                <ProductCurrentPriceContainer isPDP={isPDP}>
                  <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact || false}>
                    <Price price={currentPrice} currency={currency} />
                  </ProductPriceCurrentPrice>
                </ProductCurrentPriceContainer>
              )}
            </ProductPriceDetails>
          </ProductPriceContainer>
          {pricePerLtr && isDE && (
            <StyledPricePerLiter variant="caption">
              (1 l = <Price price={pricePerLtr} currency={currency} />)
            </StyledPricePerLiter>
          )}
        </>
      )}
    </ProductPriceWrapper>
  )
}

export default ProductPriceNew

import { useSite } from '@foundation/hooks/useSite'
import Log from '@services/Log'
import {
  ContactLensesData,
  EyeClFieldConfig,
  EyeClFieldConfigMap,
  EyeContactLensAttribute,
  EyeContactLensAttributeMapping,
  EyeContactLensOverrideMap,
  EyeContanctLensOption,
} from '@typesApp/prescription'
import { IProduct } from '@typesApp/product'
import { sum, uniq, uniqBy } from '@utils/helpers'
import { generateQuantityOptions } from '@utils/order'
import {
  getClAdditionRange,
  getClAxisRange,
  getClBaseCurve,
  getClColorRange,
  getClCylinderRange,
  getClDominance,
  getClLensRange,
} from '@utils/productAttributes'
import { TFunction } from 'i18next'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'

/**
 * @param { IProduct } product current selected product object
 */

export const attributesList = [
  'CL_LENS_RANGE',
  'CL_CYLINDER_RANGE',
  'CL_AXIS_RANGE',
  'CL_ADDITION_RANGE',
  'CL_DOMINANCE',
  'CL_COLOR_RANGE',
]

const NEGATIVE_SIGN = '-'
const POSITIVE_SIGN = '+'
const POSITIVE = 'positive'
const NEGATIVE = 'negative'

const POWER_SPHERE_STEP = 0.25
const AXIS_STEP = 5
const CYLINDER_STEP = 0.25
const DOMINANCE_OPTION_VALUES_ORDER = ['', 'NO', 'YES']

const StringToNumberValuesMap: Record<string, number> = {
  low: 555,
  medium: 556,
  high: 557,
  '-': 777,
}

const NumberToStringsValuesMap: Record<number, string> = {
  555: 'low',
  556: 'medium',
  557: 'high',
  777: '-',
}

const isNullableOption = (value: number): boolean => {
  return value === 777
}

const formatOptionValue = (value: number, originalValue: string, multifield?: boolean): string => {
  const isPositiveValue = originalValue?.startsWith(POSITIVE_SIGN)

  return value === 0
    ? isPositiveValue
      ? `+${value.toFixed(2)}`
      : `-${value.toFixed(2)}`
    : multifield
      ? value > 0
        ? `+${value.toFixed(2)}`
        : value.toFixed(2)
      : value.toString()
}

const getSortedUniqs = (values: EyeContactLensAttributeMapping[], sign: 'negative' | 'positive') => {
  return sortLensAttributeMapping(
    uniqBy(
      values.filter(val =>
        sign === NEGATIVE ? val?.orginalValue?.startsWith(NEGATIVE_SIGN) : val?.orginalValue?.startsWith(POSITIVE_SIGN)
      ),
      'parsedValue'
    )
  )
}

const sortLensOptions = (arr: EyeContanctLensOption[]) => {
  return arr.sort((x, y) => Number(x.value) - Number(y.value))
}

const sortLensAttributeMapping = (arr: EyeContactLensAttributeMapping[]) => {
  return arr.sort(function (a, b) {
    return Math.abs(a.parsedValue) - Math.abs(b.parsedValue)
  })
}

const createOrderedAndParsedValues = (
  negativeValues: EyeContactLensAttributeMapping[],
  positiveValues: EyeContactLensAttributeMapping[]
) => {
  const orderedAndParsedValues: EyeContactLensAttributeMapping[] = []
  const combinedValues = sortLensAttributeMapping(negativeValues.concat(positiveValues))
  const uniqueValues = uniq(combinedValues.map(i => Math.abs(i.parsedValue)))

  uniqueValues.map(i => {
    const negativeValue = negativeValues.find(neg => Math.abs(neg.parsedValue) === i) ?? {
      parsedValue: 777,
      orginalValue: '777',
    }
    const positiveValue = positiveValues.find(pos => Math.abs(pos.parsedValue) === i) ?? {
      parsedValue: 777,
      orginalValue: '777',
    }
    orderedAndParsedValues.push(negativeValue)
    orderedAndParsedValues.push(positiveValue)
  })

  return orderedAndParsedValues
}

// const isOdd = x => x % 2
//const isEven = (x) => !isOdd(x)

const orderByOddAndEven = (values: EyeContactLensAttributeMapping[]): EyeContactLensAttributeMapping[] => {
  let positiveValues: EyeContactLensAttributeMapping[] = []
  let negativeValues: EyeContactLensAttributeMapping[] = []
  negativeValues = getSortedUniqs(values, NEGATIVE)
  positiveValues = getSortedUniqs(values, POSITIVE)

  return createOrderedAndParsedValues(negativeValues, positiveValues) || []
}

export const parseNumericProductAttributeValues = (
  attributeValue?: string | string[],
  multifield?: boolean,
  productHasMTO?: boolean,
  step?: number,
  floatNumber?: boolean
): EyeContanctLensOption[] => {
  //let parsedAttrValues: number[] = []
  let orderedOptions: EyeContanctLensOption[] = []
  try {
    const parsedOriginalValMapping: EyeContactLensAttributeMapping[] =
      !!attributeValue && attributeValue !== ''
        ? Array.isArray(attributeValue)
          ? attributeValue.map(value =>
              isNaN(parseFloat(value))
                ? {
                    parsedValue: StringToNumberValuesMap[value.toLowerCase()],
                    orginalValue: value,
                  }
                : {
                    parsedValue: parseFloat(value),
                    orginalValue: value,
                  }
            )
          : isNaN(parseFloat(attributeValue || ''))
            ? [
                {
                  parsedValue: StringToNumberValuesMap[attributeValue.toLowerCase()],
                  orginalValue: attributeValue,
                },
              ]
            : [
                {
                  parsedValue: parseFloat(attributeValue),
                  orginalValue: attributeValue,
                },
              ]
        : []
    let orderedAttributes = multifield ? orderByOddAndEven(parsedOriginalValMapping) : parsedOriginalValMapping

    if (productHasMTO && step) {
      const sortedAttributes = orderedAttributes
        ?.sort(function (prev, next) {
          return prev.parsedValue - next.parsedValue
        })
        .filter(el => el.parsedValue !== 777)

      if (sortedAttributes.length > 0) {
        const min = sortedAttributes[0]
        const max = sortedAttributes[sortedAttributes?.length - 1]

        const positives: EyeContactLensAttributeMapping[] = generatePositiveValues(
          max,
          step,
          floatNumber || multifield,
          min
        )
        const negatives: EyeContactLensAttributeMapping[] = generateNegativeValues(min, step, floatNumber || multifield)

        orderedAttributes = multifield ? orderByOddAndEven(negatives.concat(positives)) : negatives.concat(positives)
      }
    }

    orderedOptions = orderedAttributes.map((val: EyeContactLensAttributeMapping, i) => {
      return {
        text: !!NumberToStringsValuesMap[val.parsedValue]
          ? NumberToStringsValuesMap[val.parsedValue]
          : formatOptionValue(val.parsedValue, val.orginalValue, multifield),
        value: isNullableOption(val.parsedValue)
          ? null
          : !!NumberToStringsValuesMap[val.orginalValue]
            ? NumberToStringsValuesMap[val.orginalValue]
            : val.orginalValue,
        index: i,
      }
    })
  } catch (e) {
    Log.error('error parsing product attributes values' + e)
  }

  return multifield ? orderedOptions : uniqBy(orderedOptions, 'value')
}

const removeDuplicateAttributeValues = (
  attributeValueArray: EyeContactLensAttributeMapping[]
): EyeContactLensAttributeMapping[] => {
  const ids: number[] = attributeValueArray.map(({ parsedValue }) => parsedValue)
  return attributeValueArray.filter(({ parsedValue }, index) => !ids.includes(parsedValue, index + 1))
}

const generatePositiveValues = (
  max: EyeContactLensAttributeMapping,
  step: number,
  floatNumber?: boolean,
  min?: EyeContactLensAttributeMapping
): EyeContactLensAttributeMapping[] => {
  const positives: EyeContactLensAttributeMapping[] = []
  const sign = floatNumber ? '+' : ''

  if (min && min.parsedValue > 0) {
    positives.push({
      parsedValue: floatNumber ? +min.parsedValue.toFixed(2) : min.parsedValue,
      orginalValue: `${sign}${floatNumber ? min.orginalValue : min.orginalValue.padStart(3, '0')}`,
    })
  }

  for (let s = step; s <= max.parsedValue; s = s + step) {
    positives.push({
      parsedValue: floatNumber ? +s.toFixed(2) : s,
      orginalValue: `${sign}${floatNumber ? s.toFixed(2) : s.toString().padStart(3, '0')}`,
    })
  }
  return removeDuplicateAttributeValues(positives)
}

const generateNegativeValues = (
  min: EyeContactLensAttributeMapping,
  step: number,
  floatNumber?: boolean
): EyeContactLensAttributeMapping[] => {
  const negatives: EyeContactLensAttributeMapping[] = []
  for (let s = 0; s >= min.parsedValue; s = s - step) {
    negatives.push({
      parsedValue: floatNumber ? +s.toFixed(2) : s,
      orginalValue: floatNumber ? s.toFixed(2) : s.toString().padStart(3, '0'),
    })
  }
  return removeDuplicateAttributeValues(negatives)
}

export const parseStringProductAttributeValues = (attributeValue?: string | string[]): EyeContanctLensOption[] => {
  const attributeValues = !!attributeValue ? (Array.isArray(attributeValue) ? attributeValue : [attributeValue]) : []
  return attributeValues.map((val, index) => {
    return {
      text: val,
      value: val,
      index,
    }
  })
}

const getDefaultOption = (options: EyeContanctLensOption[]): string => {
  return !isSelect(options)
    ? uniqBy(options, 'value')
        ?.find(option => option.value !== null)
        ?.value?.toString() || ''
    : ''
}
const getDefaultOverridedValue = (
  values: EyeContactLensOverrideMap,
  attrName: EyeContactLensAttribute,
  eye: string
): string | null => {
  try {
    const found =
      values[eye]?.find(value => {
        return value.attribute === attrName
      })?.value || ''
    return found
  } catch (e) {
    return null
  }
}

const isSelect = (options: EyeContanctLensOption[]): boolean => {
  return options.length > 1
}

const isFieldVisible = (options: EyeContanctLensOption[]): boolean => {
  return options.length > 0
}

const convertDominanceValue = (value: boolean | string): 'YES' | 'NO' => {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' || value.toLowerCase() === 'yes' ? 'YES' : 'NO'
  }

  return value ? 'YES' : 'NO'
}

const getDominanceFieldOptionsByProductAttribute = (
  t: TFunction,
  attributeValue?: boolean | string | string[]
): EyeContanctLensOption[] => {
  const arrValues = Array.isArray(attributeValue) ? Array.from(new Set(attributeValue)) : attributeValue
  let options: EyeContanctLensOption[] = []
  if (Array.isArray(arrValues)) {
    arrValues.map((value, index) => {
      options.push({
        text: t(`ContactLenses.Labels.Dominance.${value}`),
        value: convertDominanceValue(value),
        index: index + 1,
      })
    })
  } else if (typeof arrValues === 'string') {
    options.push({
      text: t(`ContactLenses.Labels.Dominance.${arrValues}`),
      value: convertDominanceValue(arrValues),
      index: 1,
    })
  } else {
    options = []
  }

  const correctOptionsOrder = getCorrectDominanceFieldOptionsOrder(options)

  return correctOptionsOrder
}

const getCorrectDominanceFieldOptionsOrder = (
  dominanceFieldOptions: EyeContanctLensOption[]
): EyeContanctLensOption[] => {
  const sortedOptionsOrderByValue = dominanceFieldOptions.sort(
    (optionA, optionB) =>
      DOMINANCE_OPTION_VALUES_ORDER.indexOf(optionA?.value ?? '') -
      DOMINANCE_OPTION_VALUES_ORDER.indexOf(optionB?.value ?? '')
  )

  return sortedOptionsOrderByValue
}

const getFieldOptionsByProductAttribute = (
  attributeValue?: string | string[],
  multifield?: boolean,
  isStringAttribute?: boolean,
  productHasMTO?: boolean,
  step?: number,
  floatNumber?: boolean
): EyeContanctLensOption[] => {
  let options: EyeContanctLensOption[] = []
  try {
    options = isStringAttribute
      ? parseStringProductAttributeValues(attributeValue)
      : parseNumericProductAttributeValues(attributeValue, multifield, productHasMTO, step, floatNumber)
  } catch (e) {
    Log.error('contact lenses options parsing error' + e)
  }
  return options
}

const getMultiPleLensAttrValues = (attrName: string, p: IProduct): Record<'x_spherePower' | 'x_diameter', string[]> => {
  const attributeValuesaArray = p.productAttributes[attrName]

  const lensAttributeNameValuesMap: Record<'x_spherePower' | 'x_diameter', string[]> = {
    x_spherePower: [],
    x_diameter: [],
  }

  try {
    Array.isArray(attributeValuesaArray)
      ? attributeValuesaArray?.map(node => {
          lensAttributeNameValuesMap.x_spherePower.push(node.split(' ')[1])
          lensAttributeNameValuesMap.x_diameter.push(node.split(' ')[2])
        })
      : null
  } catch (e) {}
  return lensAttributeNameValuesMap || null
}

export const formatDataForCart = (contactLensesData: ContactLensesData): ContactLensesData => {
  const parsedObj = {}
  try {
    Object.keys(contactLensesData).map(eye => {
      const eyeData = { ...contactLensesData[eye] }
      Object.keys(eyeData).map(fieldId => {
        const fieldValue = eyeData[fieldId]
        const reversed = !!NumberToStringsValuesMap[parseFloat(fieldValue)]
          ? NumberToStringsValuesMap[parseFloat(fieldValue)]
          : fieldValue
        eyeData[fieldId] = reversed
      })
      if (eyeData.fieldsStatus?.enabled) {
        delete eyeData.fieldsStatus
        parsedObj[eye] = eyeData
      }
    })
  } catch (e) {
    Log.error('contact lenses reverse mapping error' + e)
  }

  return parsedObj
}
const getLensConfiguration = (
  t: TFunction,
  p: IProduct,
  defaultValueOverrides?: EyeContactLensOverrideMap,
  productHasMTO?: boolean
) => {
  const config: EyeClFieldConfigMap = {
    left: [],
    right: [],
  }
  const eyesMap = ['left', 'right']

  try {
    eyesMap.map(eye => {
      attributesList.map(attr => {
        switch (attr) {
          case 'CL_LENS_RANGE':
            const multipleAttrNameValueMap = getMultiPleLensAttrValues(attr, p)
            const spOptions: EyeContanctLensOption[] = !!multipleAttrNameValueMap['x_spherePower']
              ? getFieldOptionsByProductAttribute(
                  multipleAttrNameValueMap['x_spherePower'],
                  true,
                  false,
                  productHasMTO,
                  productHasMTO ? POWER_SPHERE_STEP : undefined
                )
              : []

            const clBaseCurve = getClBaseCurve(p)
            const bcOptions: EyeContanctLensOption[] = !!clBaseCurve
              ? sortLensOptions(getFieldOptionsByProductAttribute(clBaseCurve))
              : []

            const diaOptions: EyeContanctLensOption[] = !!multipleAttrNameValueMap.x_diameter
              ? sortLensOptions(getFieldOptionsByProductAttribute(multipleAttrNameValueMap.x_diameter))
              : []

            const groupedConfigs: EyeClFieldConfig[] = [
              {
                id: 'x_baseCurve',
                label: t('ContactLenses.Labels.BaseCurve'),
                required: isFieldVisible(bcOptions),
                select: isSelect(bcOptions),
                defaultValue: defaultValueOverrides
                  ? getDefaultOverridedValue(defaultValueOverrides, 'x_baseCurve', eye) || getDefaultOption(bcOptions)
                  : getDefaultOption(bcOptions),
                visible: isFieldVisible(bcOptions),
                active: !!getClLensRange(p),
                options: bcOptions,
              },
              {
                id: 'x_diameter',
                label: t('ContactLenses.Labels.Diameter'),
                required: isFieldVisible(diaOptions),
                select: isSelect(diaOptions),
                defaultValue: defaultValueOverrides
                  ? getDefaultOverridedValue(defaultValueOverrides, 'x_diameter', eye) || getDefaultOption(diaOptions)
                  : getDefaultOption(diaOptions),
                visible: isFieldVisible(diaOptions),
                active: !!getClLensRange(p),
                options: diaOptions,
              },
              {
                id: 'x_spherePower',
                label: t('ContactLenses.Labels.PowerSphere'),
                required: isFieldVisible(spOptions),
                select: isSelect(spOptions),
                defaultValue: defaultValueOverrides
                  ? getDefaultOverridedValue(defaultValueOverrides, 'x_spherePower', eye) || getDefaultOption(spOptions)
                  : getDefaultOption(spOptions),
                visible: isFieldVisible(spOptions),
                active: !!getClLensRange(p),
                multifield: true,
                options: spOptions,
              },
            ]
            config[eye].push(...groupedConfigs)

            break
          case 'CL_AXIS_RANGE':
            const axisRangeOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(
              getClAxisRange(p),
              false,
              false,
              productHasMTO,
              productHasMTO ? AXIS_STEP : undefined
            )

            const axisConfig: EyeClFieldConfig = {
              id: 'x_axis',
              label: t('ContactLenses.Labels.Axis'),
              required: isFieldVisible(axisRangeOptions),
              select: isSelect(axisRangeOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_axis', eye) || getDefaultOption(axisRangeOptions)
                : getDefaultOption(axisRangeOptions),
              visible: isFieldVisible(axisRangeOptions),
              options: axisRangeOptions,
            }
            config[eye].push(axisConfig)

            break
          case 'CL_COLOR_RANGE':
            const colorOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(
              getClColorRange(p),
              false,
              true
            )
            const colorConfig: EyeClFieldConfig = {
              id: 'x_color',
              label: t('ContactLenses.Labels.Color'),
              required: isFieldVisible(colorOptions),
              select: isSelect(colorOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_color', eye) || getClColorRange(p) || ''
                : getClColorRange(p) || '',
              visible: isFieldVisible(colorOptions),
              active: !!getClColorRange(p),
              options: colorOptions,
            }
            config[eye].push(colorConfig)
            break
          case 'CL_DOMINANCE':
            const dominanceOptions: EyeContanctLensOption[] = getDominanceFieldOptionsByProductAttribute(
              t,
              getClDominance(p)
            )
            const dominanceConfig: EyeClFieldConfig = {
              id: 'x_dominance',
              label: t('ContactLenses.Labels.Dominance.title'),
              required: isFieldVisible(dominanceOptions) && isSelect(dominanceOptions),
              select: isSelect(dominanceOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_dominance', eye) ||
                  getDefaultOption(dominanceOptions)
                : getDefaultOption(dominanceOptions),
              visible: isFieldVisible(dominanceOptions) && isSelect(dominanceOptions),
              active: !!getClDominance(p),
              options: dominanceOptions,
            }
            config[eye].push(dominanceConfig)
            break
          case 'CL_ADDITION_RANGE':
            const additionOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(getClAdditionRange(p))
            const additionConfig: EyeClFieldConfig = {
              id: 'x_addition',
              label: t('ContactLenses.Labels.Addition'),
              required: isFieldVisible(additionOptions),
              select: isSelect(additionOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_addition', eye) ||
                  getDefaultOption(additionOptions)
                : getDefaultOption(additionOptions),
              visible: isFieldVisible(additionOptions),
              active: !!getClAdditionRange(p),
              options: additionOptions,
            }
            config[eye].push(additionConfig)
            break
          case 'CL_CYLINDER_RANGE':
            const cylinderOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(
              getClCylinderRange(p),
              false,
              false,
              productHasMTO,
              productHasMTO ? CYLINDER_STEP : undefined,
              true
            )
            const cylinderConfig: EyeClFieldConfig = {
              id: 'x_cylinder',
              label: t('ContactLenses.Labels.Cylinder'),
              required: isFieldVisible(cylinderOptions),
              select: isSelect(cylinderOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_cylinder', eye) ||
                  getDefaultOption(cylinderOptions)
                : getDefaultOption(cylinderOptions),
              visible: isFieldVisible(cylinderOptions),
              active: !!getClCylinderRange(p),
              options: cylinderOptions,
            }
            config[eye].push(cylinderConfig)
            break
          default:
            return config
        }
        return config
      })
      const quantityConfig: EyeClFieldConfig = {
        id: 'quantity',
        label: t('ContactLenses.Labels.Quantity'),
        required: true,
        select: true,
        visible: true,
        defaultValue: defaultValueOverrides
          ? getDefaultOverridedValue(defaultValueOverrides, 'quantity', eye) || '1'
          : '1',

        options: generateQuantityOptions(30, 1),
      }
      config[eye].push(quantityConfig)
    })
    return config
  } catch (e) {
    Log.error('contact lenses config error' + e)
  }

  return config
}

const getDefaultValues = (
  configuration: EyeClFieldConfigMap | null
): Record<
  'left' | 'right',
  {
    fieldsDefaultValues: EyeContactLensAttribute[]
    defaultEmptyFields: EyeContactLensAttribute[]
  }
> => {
  const defaultValuesMap: Record<
    'left' | 'right',
    {
      fieldsDefaultValues: EyeContactLensAttribute[]
      defaultEmptyFields: EyeContactLensAttribute[]
    }
  > = {
    left: {
      defaultEmptyFields: [],
      fieldsDefaultValues: [],
    },
    right: { defaultEmptyFields: [], fieldsDefaultValues: [] },
  }
  for (const eye in configuration) {
    const configValues = {}
    const requiredEmptyFields: EyeContactLensAttribute[] = []
    if (!!configuration[eye]) {
      configuration[eye]
        ?.filter(fieldConf => fieldConf.required || fieldConf.visible || fieldConf.active)
        .map(fieldConf => {
          configValues[fieldConf.id] = fieldConf.defaultValue
          if (fieldConf.required && fieldConf.defaultValue === '') {
            requiredEmptyFields.push(fieldConf.id)
          }
        })
      defaultValuesMap[eye] = {
        fieldsDefaultValues: configValues,
        defaultEmptyFields: requiredEmptyFields,
      }
    }
  }
  return defaultValuesMap
}

const getProductHasMTO = (isMtoEnabled: boolean, currentProduct?: IProduct, mtoEnabledUpc?: string): boolean => {
  return (
    !!currentProduct &&
    isMtoEnabled &&
    (mtoEnabledUpc?.toLowerCase().split(',').includes(currentProduct.partNumber.toLowerCase()) ||
      !mtoEnabledUpc ||
      mtoEnabledUpc === null ||
      mtoEnabledUpc.length === 0)
  )
}

export const useContactLensSelection = (
  currentProduct?: IProduct,
  pdpData?: IProduct,
  defaultValues?: EyeContactLensOverrideMap
) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const [contanctLensFieldsConfig, setContactLensConfig] = useState<EyeClFieldConfigMap | null>({})

  const [fieldsHaveErrors, setFieldsHaveErrors] = useState<boolean>(false)
  const [bothEyesDisabled, setBothEyesDisabled] = useState<boolean>(false)
  const [defaultEmptyFields, setDefaultEmptyFields] = useState<Record<'left' | 'right', EyeContactLensAttribute[]>>({
    left: [],
    right: [],
  })
  const [defaultFieldsValues, setDefaultFieldsValues] = useState<Record<'left' | 'right', EyeContactLensAttribute[]>>({
    left: [],
    right: [],
  })

  const initialContactLensesState: ContactLensesData = {
    left: {
      x_eye: 'LCON',
      fieldsStatus: {
        enabled: true,
        errors: [],
        dirtyFields: [],
        emptyFields: [''],
        valid: false,
      },
    },
    right: {
      x_eye: 'RCON',
      fieldsStatus: {
        enabled: true,
        errors: [],
        dirtyFields: [],
        emptyFields: [''],
        valid: false,
      },
    },
  }

  const [contactLensesData, setContactLensData] = useState<ContactLensesData>(initialContactLensesState)

  const productHasMTO = getProductHasMTO(
    mySite.xStoreCfg.isMtoEnabled || false,
    currentProduct,
    mySite.xStoreCfg.MTO_ENABLED_UPC
  )

  useEffect(() => {
    setContactLensData({
      left: {
        ...initialContactLensesState.left,
        ...defaultFieldsValues.left,
        x_productId: pdpData?.id,
        fieldsStatus: {
          ...contactLensesData.left.fieldsStatus,
          emptyFields: defaultEmptyFields.left,
        },
      },
      right: {
        ...initialContactLensesState.right,
        ...defaultFieldsValues.right,
        x_productId: pdpData?.id,
        fieldsStatus: {
          ...contactLensesData.right.fieldsStatus,
          emptyFields: defaultEmptyFields.right,
        },
      },
    })
  }, [pdpData, defaultFieldsValues, defaultEmptyFields])

  useMemo(() => {
    const configuration = !!currentProduct
      ? getLensConfiguration(t, currentProduct, defaultValues, productHasMTO)
      : null

    const defaultValuesEyeMap = getDefaultValues(configuration)
    setContactLensConfig(configuration)
    setDefaultFieldsValues({
      left: defaultValuesEyeMap['left'].fieldsDefaultValues,
      right: defaultValuesEyeMap['right'].fieldsDefaultValues,
    })
    setDefaultEmptyFields({
      left: defaultValuesEyeMap['left'].defaultEmptyFields,
      right: defaultValuesEyeMap['right'].defaultEmptyFields,
    })
  }, [currentProduct, defaultValues?.left.length, defaultValues?.right.length])

  const areBothEyesDisabled = (data: ContactLensesData): boolean => {
    return (!data['left']?.fieldsStatus?.enabled && !data['right']?.fieldsStatus?.enabled) || false
  }
  const areErrorsInFields = (): boolean => {
    return (
      Object.values(contactLensesData).filter(eye => {
        return eye?.fieldsStatus?.errors && eye?.fieldsStatus?.errors.length > 0
      }).length > 0 || false
    )
  }

  const fieldsAreNotValid = (data: ContactLensesData): boolean => {
    return (
      Object.values(data).filter(eye => {
        return eye?.fieldsStatus?.enabled && !eye?.fieldsStatus?.valid
      }).length > 0
    )
  }

  const getTotalBoxesCount = (data: ContactLensesData): number => {
    const totalBoxes = sum([
      parseInt(data['left']?.fieldsStatus?.enabled ? data['left']?.quantity || '0' : '0'),
      parseInt(data['right']?.fieldsStatus?.enabled ? data['right']?.quantity || '0' : '0'),
    ])
    return totalBoxes
  }

  useEffect(() => {
    setFieldsHaveErrors(areErrorsInFields())
  }, [contactLensesData])

  return {
    defaultFieldsValues,
    defaultEmptyFields,
    contanctLensFieldsConfig,
    setContactLensData,
    contactLensesData,
    fieldsHaveErrors,
    areBothEyesDisabled,
    bothEyesDisabled,
    setBothEyesDisabled,
    setFieldsHaveErrors,
    fieldsAreNotValid,
    StringToNumberValuesMap,
    formatDataForCart,
    getTotalBoxesCount,
  }
}

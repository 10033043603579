import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPlus = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.7186 12C18.7186 12.3692 18.4182 12.6696 18.049 12.6696H12.3881V18.3304C12.3881 18.6996 12.0878 19 11.7186 19C11.3493 19 11.049 18.6996 11.049 18.3304V12.6696H5.38815C5.01892 12.6696 4.71857 12.3692 4.71857 12C4.71857 11.6308 5.01892 11.3304 5.38815 11.3304H11.049V5.66958C11.049 5.30035 11.3493 5 11.7186 5C12.0878 5 12.3881 5.30035 12.3881 5.66958V11.3304H18.049C18.4182 11.3304 18.7186 11.6308 18.7186 12Z"
      />
    </SvgIcon>
  )
}
export default SvgPlus

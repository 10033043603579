import itLocale from 'dayjs/locale/it'
import nlLocale from 'dayjs/locale/nl'
import enLocale from 'dayjs/locale/en'
import esLocale from 'dayjs/locale/es'
import frLocale from 'dayjs/locale/fr'
import nlBELocale from 'dayjs/locale/nl-be'
import { it, enGB, enCA, nl, nlBE } from 'date-fns/locale'

export const DATE_FORMAT_PATTERN = {
  'MMMM d, yyyy': 'MMMM d, yyyy', // July 3, 2021,
  'd MMMM yyyy': 'd MMMM yyyy', // 3 July 2021,
  'yyyy-MM-dd': 'yyyy-MM-dd', // 2021-07-03
  'dd/MM/yyyy': 'dd/MM/yyyy', // 03/07/2021
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'ddd, DD MMM': 'ddd, DD MMM', // "Sat, 15 Oct"
  'DD/MM/YYYY': 'DD/MM/YYYY', // 15/10/2021
} as const

export const DATE_DAYJS_LOCALE_MAP = {
  default: enLocale,
  en_GB: enLocale,
  en_UK: enLocale,
  en_CA: enLocale,
  es_ES: esLocale,
  fr_FR: frLocale,
  it_IT: itLocale,
  nl_NL: nlLocale,
  nl_BE: nlBELocale,
  fr_BE: frLocale,
}

export const DATE_FNS_LOCALE_MAP = {
  en_gb: 'en-gb',
  // de_at: 'de-at',
  // de_de: 'de',
  en_ca: 'en-ca',
  // en_ie: 'en-in',
  // en_us: 'en-us',
  // es_es: 'es',
  // fr_fr: 'fr',
  it_it: 'it',
  // ja_jp: 'ja',
  // ko_kr: 'ko',
  // nl_nl: 'en-us',
  // pl_pl: 'pl',
  // pt_br: 'pt-br',
  // pt_pt: 'en-us',
  // ro_ro: 'ro',
  // ru_ru: 'ru',
  // zh_cn: 'zh-cn',
  // zh_tw: 'zh-tw',
}

export const REMINDER_DATE_FNS_LOCALE_MAP = {
  en_GB: enGB,
  en_CA: enCA,
  it_IT: it,
  nl_NL: nl,
  nl_BE: nlBE,
}

export interface DATE_OPTIONS {
  locale: (typeof DATE_FNS_LOCALE_MAP)[keyof typeof DATE_FNS_LOCALE_MAP]
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
  firstWeekContainsDate?: number | undefined
  useAdditionalWeekYearTokens?: boolean | undefined
  useAdditionalDayOfYearTokens?: boolean | undefined
  strictPattern?: boolean
}

export const ISO_DATE_FORMAT = 'yyyy-MM-dd'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { ORDER_CONFIGS } from '../../../configs/order'
import shippingInfoService from '../../../foundation/apis/transaction/shippingInfo.service'
import fetchCart from './fetchCart'
import { updateIsCartThresholdReachedFlag } from '../slice'
import { ORDER_TOTAL_THRESHOLD_REACHED } from '@constants/errors'

export interface IUpdateShipModeArgs {
  widget?: string
  shipModeId: string
  storeId?: string
}

const updateShipMode = createAsyncThunk<any, IUpdateShipModeArgs>(
  'order/updateShipMode',
  async (args, { dispatch }) => {
    const { shipModeId, widget, storeId } = args
    const body = {
      body: {
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        x_allocate: ORDER_CONFIGS.allocate,
        x_backorder: ORDER_CONFIGS.backOrder,
        x_remerge: ORDER_CONFIGS.remerge,
        x_check: ORDER_CONFIGS.check,
        orderId: '.',
        shipModeId: shipModeId,
        //addressId: payload.shipAddressId,
        orderItem: [], //bypass defect HC-2784,
        storeId,
      },
      widget,
      storeId,
    }

    try {
      const responseData = await shippingInfoService.updateOrderShippingInfo(body)

      await dispatch(fetchCart({})).unwrap()

      return responseData
    } catch (error: any) {
      const errorResponse = error.response
      if (errorResponse && errorResponse.data && errorResponse.data.errors) {
        const e = errorResponse.data.errors[0]
        const thresholdReached = e.errorCode === ORDER_TOTAL_THRESHOLD_REACHED

        if (thresholdReached) {
          dispatch(updateIsCartThresholdReachedFlag(thresholdReached))
        }
      }
    }
  }
)

export default updateShipMode

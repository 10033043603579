import { useTranslation } from 'next-i18next'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'

export const GetCountriesLinks = () => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const isFRorBEFR = ['fr-fr', 'fr-be'].includes(langCode.toLowerCase())

  const createCountryLink = langCode => ({
    label: `${t(`Footer.Labels.CountryList.${langCode}.label`)}`,
    url: `https://www.visiondirect.${langCode}${langCode === 'fr-be' ? '/fr/' : ''}`,
    isActive: `${t(`Footer.Labels.CountryList.${langCode}.active`)}`,
  })

  return [
    createCountryLink('be'),
    ...(isFRorBEFR ? [createCountryLink('es')] : []),
    createCountryLink('fr'),
    createCountryLink('ie'),
    createCountryLink('it'),
    createCountryLink('nl'),
    ...(!isFRorBEFR ? [createCountryLink('es')] : []),
    createCountryLink('uk'),
  ]
}

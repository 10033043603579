import { XDataSubscriptions } from '@typesApp/user'
import { ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS, SUBSCRIPTION_STATES, SubscriptionResult } from '@typesApp/subscription'
import { ParsedUrlQuery } from 'querystring'
import { setActiveSubscriptionsCountUpdate } from '@features/user/slice'
import { AppDispatch } from '@redux/store'

export const getActiveSubscriptionsQty = (subscriptions: XDataSubscriptions[]): number => {
  if (!subscriptions || subscriptions?.length === 0) {
    return 0
  }

  return subscriptions?.filter(
    subscription => subscription?.subscriptionStatus === ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS.ACTIVE
  ).length
}

export const getActiveSubscriptionsQuantityByStateName = (subscriptions: SubscriptionResult[]): number => {
  if (!subscriptions || subscriptions?.length === 0) {
    return 0
  }

  return subscriptions.filter(subscription => subscription.state === SUBSCRIPTION_STATES.ACTIVE).length
}

export const getSubscriptionId = (routerQuery: ParsedUrlQuery): string => {
  const { pageName: pageRouterParam } = routerQuery as { pageName: string[] }
  const subscriptionId = Array.isArray(pageRouterParam) ? pageRouterParam[pageRouterParam.length - 1] : ''

  return subscriptionId
}

//any type for getSubscriptionHistory function to be passed as a parameter
export const updateActiveSubscriptionsQuantity = async (
  dispatch: AppDispatch,
  getSubscriptionHistory: any,
  storeId: string
): Promise<void> => {
  const { data, error } = await getSubscriptionHistory({ storeId })

  if (!error) {
    const activeSubscriptionsQuantity = getActiveSubscriptionsQuantityByStateName(data?.resultList || [])
    dispatch(setActiveSubscriptionsCountUpdate(activeSubscriptionsQuantity))
  }
}

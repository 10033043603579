import { notUndefined } from '@utils/common'
import { useEffect, useMemo, useState } from 'react'
import { getPlacementProductData } from './helpers'
import { TGridOfProducts } from './types'
import { SuggestedProductTile } from '../SuggestedProductTile'
import { hclDTO } from '@utils/productNew'
import { StyledGridOfProductsContainer } from './GridOfProductsStyle'
import { usePageType } from '@foundation/hooks/usePageType'
import { mapMonetateProductsHome, trackProducts } from '@foundation/monetate/lib'
import { listenMonetateQ } from '@foundation/monetate/useMonetate'

const GridOfProducts: React.FC<TGridOfProducts> = ({ placement, lazy }) => {
  const [isLoadedOnceInHomePage, setIsLoadedOnceInHomePage] = useState<boolean>(false)

  const productData = useMemo(() => getPlacementProductData(placement), [placement])
  const monetateLoaded = listenMonetateQ()

  const products = productData?.map(hclDTO).filter(notUndefined)
  const pageType = usePageType()

  useEffect(() => {
    if (pageType.pageType && products.length > 0 && monetateLoaded && !isLoadedOnceInHomePage) {
      trackProducts(mapMonetateProductsHome(products), pageType.pageType)

      // This is needed to avoid duplicated monetate calls when opening search ih the home page
      if (pageType.pageType === 'home') {
        setIsLoadedOnceInHomePage(true)
      }
    }
  }, [pageType?.pageType, products.length, monetateLoaded, isLoadedOnceInHomePage])

  if (!productData.length) return null

  return (
    <StyledGridOfProductsContainer>
      {products.map((teaser, index) => (
        <SuggestedProductTile product={teaser} tileIndex={index} lazy={lazy} key={teaser.productId} />
      ))}
    </StyledGridOfProductsContainer>
  )
}

export default GridOfProducts

import { TermsAndConditions } from '@components/common/components/TermsAndConditions'
import { hasOverlay, hasTermsAndConditions, isShoppableTeaser } from '@components/common/helpers/cms'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import clsx from 'clsx'
import { CmsMedia } from '../CmsMedia'
import { ImageCrops } from '../CmsMedia/types'
import { ShoppableTeaser } from '../ShoppableTeaser'
import { TextModule } from '../TextModule'
import { LandscapeBannerContent } from './LandscapeBannerContent'

type TGenericBanner = {
  teaser?: ILXTeaser
  imageCrops?: ImageCrops
  stylesViewType: string
  customerSegments: string[]
  teaserIndex?: number
  bannerIndex?: number
  lazy?: boolean
}

const GenericBanner: React.FC<TGenericBanner> = ({
  teaser,
  imageCrops,
  stylesViewType,
  customerSegments,
  teaserIndex,
  bannerIndex,
  lazy,
}) => {
  const {
    media,
    teaserOverlay2Settings,
    teaserOverlay2Style,
    teaserOverlay2TextAlign,
    teaserLXCallToActionSettings,
    teaserBackground,
    targetsTermsAndConditions,
  } = teaser || {}
  const isShoppable = isShoppableTeaser(teaser)
  const hasTerms = hasTermsAndConditions(teaser)
  const shouldRenderBannerContent = teaser?.name?.includes('main')

  return (
    <div
      className={clsx(
        'cms-section',
        stylesViewType,
        teaserOverlay2Settings,
        teaserOverlay2Style,
        teaserOverlay2TextAlign,
        teaserBackground,
        {
          [`has-cta-${teaserLXCallToActionSettings?.length}`]: true,
          'no-cta': !teaserLXCallToActionSettings?.length,
          'is-shoppable': isShoppable,
          'has-terms': hasTerms,
        }
      )}
    >
      {imageCrops && <CmsMedia media={media} imageCrops={imageCrops} hasOverlay={hasOverlay(teaser)} lazy={lazy} />}
      {shouldRenderBannerContent ? (
        <LandscapeBannerContent teaser={teaser} />
      ) : (
        <TextModule teaser={teaser} teaserIndex={teaserIndex} />
      )}
      {isShoppable && (
        <ShoppableTeaser
          hotZonesSettings={teaser?.hotZonesSettings}
          hotZones={teaser?.hotZones}
          customerSegments={customerSegments}
        />
      )}
      {hasTerms && (
        <TermsAndConditions
          targetsTermsAndConditions={targetsTermsAndConditions}
          teaserOverlay2Style={teaserOverlay2Style}
        />
      )}
    </div>
  )
}

export default GenericBanner

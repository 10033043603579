import { createAsyncThunk } from '@reduxjs/toolkit'
import paymentInstructionService from '@foundation/apis/transaction/paymentInstruction.service'

export interface IFetchPaypalPaymentStatus {
  successUrl: string
  cancelUrl: string
  storeId: string
}

const fetchPaypalPaymentStatus = createAsyncThunk<any, IFetchPaypalPaymentStatus>(
  'order/fetchPaypalPaymentStatus',
  async args => {
    const { successUrl, cancelUrl, storeId } = args
    const response = await paymentInstructionService.getPaypalPaymentInfo(successUrl, cancelUrl, storeId)
    return response
  }
)

export default fetchPaypalPaymentStatus

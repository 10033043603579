var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"vde_3.1.70_5fb576_b73_1611230125"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import domainsList from './configuration/domains/domains-list.json'
import { storageSessionHandler } from './src/foundation/utils/storageUtil'
import { getIsUrlIgnored, getIsDomainIgnored, getIsErrorIgnored, getCustomErrorEventName } from './src/utils/sentry'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || process.env.SENTRY_DSN,
  debug: false,
  release: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  environment: process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV,
  beforeSend(event, hint: any) {
    // Bad browsers don't always have the expected internal APIs
    const localStorageIsAvailable = typeof localStorage !== 'undefined' && localStorage !== null

    // Add custom information to the event
    const url = event?.request?.url || ''
    const isIgnoredDomain = getIsDomainIgnored(url)
    const isIgnoredError = getIsErrorIgnored(event, hint)

    if (isIgnoredDomain || isIgnoredError) {
      return null
    }

    const currentDomain = domainsList.find(domainItem => url?.indexOf(domainItem.domain) !== -1)
    const currentLocale = currentDomain?.defaultLocale

    const currentUser = localStorageIsAvailable ? storageSessionHandler.getCurrentUserAndLoadAccount() : null
    const isAxiosError = hint?.originalException?.isAxiosError

    if (isAxiosError) {
      if (getIsUrlIgnored(hint.originalException.response?.config?.url)) {
        return null
      }
      if (hint.originalException.response && hint.originalException.response.data) {
        let contexts = {
          ...event.contexts,
        }

        const errorResponse = hint.originalException.response
        contexts.AxiosError = { Response: errorResponse }

        const errorData = errorResponse.data
        if (errorData.errors && errorData.errors.length) {
          const { errorMessage, errorKey } = errorData.errors[0]

          const newEventException = getCustomErrorEventName(event, errorKey, errorMessage)
          event.exception = newEventException
        }
        event.contexts = contexts
      }
    }

    if (currentUser && currentUser.details && event.tags) {
      event.tags.userId = currentUser.details?.userId
      event.tags.userEmail = currentUser.details?.email1
    }

    if (currentUser && !currentUser.details && event.tags) {
      event.tags.userIdGuest = currentUser.userId
    }

    event.tags = {
      ...event.tags,
      locale: currentLocale || process.env.DEFAULT_LOCALE,
      isClientError: true,
      productionEnv: process.env.NEXT_PUBLIC_PROD_ENV,
    }
    return event
  },

  tracesSampleRate: Number(process.env.TRACES_SAMPLE_RATE || process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE) || 0,
  replaysSessionSampleRate: 0.0, // Do not record regular session replays
  replaysOnErrorSampleRate: Number(process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE) || 0,
  integrations: [
    Sentry.replayIntegration({
      blockAllMedia: true,
      maskAllInputs: true,
    }),
    Sentry.extraErrorDataIntegration({
      depth: 10,
      captureErrorCause: true,
    }),
    Sentry.globalHandlersIntegration({
      onunhandledrejection: false,
    }),
  ],
})

if (typeof window !== 'undefined') {
  window.addEventListener('unhandledrejection', event => {
    Sentry.captureException(event.reason, {
      extra: {
        type: 'unhandledrejection',
        promise: event.promise,
        reason: event.reason,
      },
    })
  })

  window.addEventListener('error', event => {
    Sentry.captureException(event.error, {
      extra: {
        type: 'error',
        message: event.message,
        filename: event.filename,
        lineNo: event.lineno,
        colNo: event.colno,
      },
    })
  })
}

import Head from 'next/head'
import { forwardRef, useCallback } from 'react'
import { useInView } from 'react-hook-inview'
import { ConditionalWrapper } from './ConditionalWrapper'
import { getMediaQuerySrcSet } from './helpers'
import { CrossOriginAnonymous } from '@typesApp/product'

type OnLoad = JSX.IntrinsicElements['img']['onLoad']

export type ImageProps = {
  src: string | undefined
  srcSet?: string | string[]
  sizes?: string
  alt?: string
  width: number | undefined
  height: number | undefined
  lazy?: boolean
  preload?: boolean
  prefetch?: boolean
  fetchPriority?: 'auto' | 'high' | 'low'
  wrapper?: ConditionalWrapper
  style?: React.CSSProperties
  className?: string
  shouldLoad?: boolean
  onLoad?: OnLoad
  onError?: JSX.IntrinsicElements['img']['onError']
  loaderWidth?: number
  decoding?: 'async' | 'auto' | 'sync'
  srcSetMap?: Record<number, string>
}

type ImageElementProps = Omit<ImageProps, 'onLoad'> & {
  onLoad: OnLoad
}

function handleLoading(img: HTMLImageElement, onLoad: ImageElementProps['onLoad']) {
  const p = 'decode' in img ? img.decode() : Promise.resolve()
  p.catch(() => {}).then(() => {
    if (!img.parentElement || !img.isConnected) {
      // Exit early in case of race condition:
      // - onload() is called
      // - decode() is called but incomplete
      // - unmount is called
      // - decode() completes
      return
    }

    img.classList.add('loaded')

    if (onLoad) {
      // Since we don't have the SyntheticEvent here,
      // we must create one with the same shape.
      // See https://reactjs.org/docs/events.html
      const event = new Event('load')
      Object.defineProperty(event, 'target', { writable: false, value: img })
      let prevented = false
      let stopped = false
      onLoad({
        ...event,
        nativeEvent: event,
        currentTarget: img,
        target: img,
        isDefaultPrevented: () => prevented,
        isPropagationStopped: () => stopped,
        persist: () => {},
        preventDefault: () => {
          prevented = true
          event.preventDefault()
        },
        stopPropagation: () => {
          stopped = true
          event.stopPropagation()
        },
      })
    }
  })
}

export const ImageElement = forwardRef<HTMLImageElement, ImageElementProps>(
  (
    {
      src,
      srcSet,
      sizes,
      width,
      height,
      alt,
      lazy,
      fetchPriority,
      preload,
      prefetch,
      wrapper,
      style,
      className,
      shouldLoad = true,
      onLoad,
      onError,
      loaderWidth,
      decoding = 'async',
      srcSetMap,
      ...rest
    },
    forwardedRef
  ) => {
    const _srcSet = typeof srcSet === 'string' ? srcSet : srcSet?.join()

    const [setRef, inView] = useInView({
      threshold: 0,
      defaultInView: !lazy,
      unobserveOnEnter: true,
    })

    const setCombinesRefs = useCallback(
      (img: HTMLImageElement | null) => {
        setRef(img)

        if (forwardedRef) {
          if (typeof forwardedRef === 'function') forwardedRef(img)
          else if (typeof forwardedRef === 'object') {
            forwardedRef.current = img
          }
        }
        if (!img) {
          return
        }
        if (onError) {
          // If the image has an error before react hydrates, then the error is lost.
          // The workaround is to wait until the image is mounted which is after hydration,
          // then we set the src again to trigger the error handler (if there was an error).
          img.src = img.src
        }

        if (img.complete) {
          handleLoading(img, onLoad)
        } else {
          img?.classList.remove('loaded')
        }
      },
      [forwardedRef, onError, onLoad, setRef]
    )

    const mediaQueries = prefetch ? getMediaQuerySrcSet(srcSetMap, _srcSet) : []

    return (
      <>
        {preload && (
          <Head>
            <link
              key={src}
              rel="preload"
              as="image"
              href={src}
              imageSrcSet={_srcSet}
              crossOrigin={CrossOriginAnonymous}
            />
          </Head>
        )}
        {prefetch && (
          <Head>
            {mediaQueries?.length > 0 ? (
              mediaQueries.map(({ src, media }) => (
                <link key={src} rel="prefetch" as="image" href={src} media={media} />
              ))
            ) : (
              <link key={src} rel="prefetch" as="image" href={src} />
            )}
          </Head>
        )}

        <ConditionalWrapper condition={Boolean(wrapper?.condition ?? lazy)} wrapperTag={wrapper?.wrapperTag}>
          <img
            // The decoding attribute in an img tag is used to provide a hint to the browser about how to handle the loading of the image
            // In our case, the image will load asynchronously and won't block the main thread
            // img decoding='async' is not supported in Safari
            decoding={decoding}
            {...rest}
            alt={alt}
            data-src={lazy ? src : undefined}
            data-srcset={lazy ? _srcSet : undefined}
            data-sizes={lazy ? sizes : undefined}
            // In React 18.2.0 or older, we must use lowercase prop
            // to avoid "Warning: Invalid DOM property".
            fetchpriority={fetchPriority !== 'auto' ? fetchPriority : undefined}
            // It's intended to keep `loading` before `src` because React updates
            // props in order which causes Safari/Firefox to not lazy load properly.
            // See https://github.com/facebook/react/issues/25883
            loading={lazy ? 'lazy' : undefined}
            width={width}
            height={height}
            className={className ?? [lazy ? 'lazyload' : ''].join(' ')}
            style={style}
            // It's intended to keep `src` the last attribute because React updates
            // attributes in order. If we keep `src` the first one, Safari will
            // immediately start to fetch `src`, before `sizes` and `srcSet` are even
            // updated by React. That causes multiple unnecessary requests if `srcSet`
            // and `sizes` are defined.
            // This bug cannot be reproduced in Chrome or Firefox.
            sizes={lazy ? sizes : undefined}
            srcSet={shouldLoad && inView ? _srcSet : undefined}
            src={
              shouldLoad && inView
                ? src
                : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
            }
            ref={setCombinesRefs}
            onLoad={event => {
              const img = event.currentTarget
              handleLoading(img, onLoad)
            }}
            onError={event => {
              if (onError) {
                onError(event)
              }
            }}
          />
        </ConditionalWrapper>
      </>
    )
  }
)

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, forwardedRef) => {
  return (
    <>
      <ImageElement {...props} onLoad={props.onLoad} ref={forwardedRef} />
    </>
  )
})

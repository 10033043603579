import { HYPHEN, ORDER_ID } from '@constants/common'
import {
  ESTIMATED_TAXES,
  INVENTORY_STATUS,
  ORDER_STATUS,
  RX_PRODUCTS_IN_CART_LIMIT,
  TAXES_ZIP_CODE,
  getOrderItemsAttachments,
} from '@constants/order'
import { ACCOUNT } from '@foundation/constants/common'
import { localStorageUtil, sessionStorageUtil } from '@foundation/utils/storageUtil'
import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { NOT_FOUND } from '@constants/routes'
import { logout } from '@features/common/slice'
import { CreditCardFormDataType, PaymentInfoType } from '@typesApp/checkout'
import {
  Cart,
  IOrderDetails,
  IOrderItem,
  OrderItemWithRoxProps,
  ShippingChargesWithoutPromotions,
} from '@typesApp/order'
import { IProductAttributes } from '@typesApp/product'
import { getAllProductAttributes } from '@utils/productAttributes'
import { isRxProduct } from '@utils/rx'
import {
  getOrderItemsMap,
  isOrderComplete,
  orderHasPrescriptionPeriodExcedeed,
  orderHasPrescriptionUploaded,
} from '../../utils/order'
import { IOrderSliceState } from './IOrderSliceState'
import { orderApi } from './query'
import {
  addContactLenses,
  addItem,
  fetchCart,
  fetchPayMethods,
  fetchPaypalPaymentStatus,
  fetchShipInfo,
  updateItem,
} from './thunks'
import { addFrame, addLens } from './thunks/addLensItem'
import { truncate } from 'fs'

const initialState: IOrderSliceState = {
  isFetching: false,
  isFetchingOrderDetails: false,
  isFetchingShippingInfo: false,
  isAddingItem: false,
  billingFormStatus: {
    isValid: false,
  },
  isShippingUsedAsBilling: false,
  isCheckoutDisabled: false,
  isFinalizationFailed: false,
  isFinalizing: false,
  isRXProductsLimitExceeded: false,
  isRecurringOrderDisabled: false,
  numItems: 0,
  orderComplete: false,
  orderItems: [],
  parsedOrderItems: {
    rx: null,
    cl: null,
    'cl-acc': null,
    sun: null,
    default: null,
  },
  payMethods: [],
  paypalPaymentStatus: {
    isSelected: false,
    error: false,
    loading: false,
  },
  selectedPayMethodInfoList: [],
  shippingFormStatus: {
    isValid: false,
  },
  creditCardFormStatus: {
    isValid: false,
  },
  orderDetails: null,
  usePrescription: {
    selectedMacroIndex: 0,
    prescriptionFormData: {},
    prescriptionMacroGroups: [],
  },
  creditCardData: null,
  cartShippingCharges: {
    shippingCharges: null,
    error: null,
  },
  amazonCheckoutSessionId: null,
}

export type OrderApiState = Partial<IOrderSliceState>

// Find a way to execute those requests
//yield takeLatest([LOGIN_SUCCESS_ACTION, GUEST_LOGIN_SUCCESS_ACTION], WORKERS.fetchCart)
//yield takeLatest(INIT_USER_FROM_STORAGE_SUCCESS_ACTION, WORKERS.initFromStorageFetchCart)

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<any>) => {
      state.cart = action.payload
    },
    resetCart: state => {
      //! SessionStorageUtil shouldn't be used inside a reducer
      sessionStorageUtil.remove(ESTIMATED_TAXES)
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
      state.cart = initialState.cart
    },
    updateIsCartThresholdReachedFlag: (state, action: PayloadAction<boolean>) => {
      if (state.cart) state.cart.isCartThresholdReached = action.payload
    },
    updateCreditCardData: (state, action: PayloadAction<CreditCardFormDataType | null>) => {
      state.creditCardData = action.payload
    },
    toggleCartOrderComplete: (state, action: PayloadAction<boolean>) => {
      state.orderComplete = action.payload
      sessionStorageUtil.remove(ESTIMATED_TAXES)
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
    },

    toggleShippingAsBilling: (state, action: PayloadAction<boolean>) => {
      state.isShippingUsedAsBilling = action.payload
    },

    togglePaypal: (state, action: PayloadAction<boolean>) => {
      state.paypalPaymentStatus = {
        ...state.paypalPaymentStatus,
        isSelected: action.payload,
      }
    },

    setBillingFormValidStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.billingFormStatus = {
        ...state.billingFormStatus,
        isValid: payload,
      }
    },

    setPaymentMethod: (state, { payload }: PayloadAction<PaymentInfoType[]>) => {
      state.selectedPayMethodInfoList = payload
    },

    setShippingFormValidStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.shippingFormStatus = {
        ...state.shippingFormStatus,
        isValid: payload,
      }
    },

    setCreditCardFormValidStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.creditCardFormStatus = {
        ...state.creditCardFormStatus,
        isValid: payload,
      }
    },

    setIsFinalizationFailed: (state, { payload }: PayloadAction<boolean>) => {
      state.isFinalizationFailed = payload
    },
    setAmazonCheckoutSessionId(state, { payload }: PayloadAction<string | null>) {
      state.amazonCheckoutSessionId = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(logout, () => initialState)

    builder.addCase(addItem.rejected, state => {
      state.isAddingItem = false
    })

    builder.addCase(addContactLenses.rejected, state => {
      state.isAddingItem = false
    })

    builder.addCase(addItem.pending, state => {
      state.isAddingItem = true
    })

    builder.addCase(addContactLenses.pending, state => {
      state.isAddingItem = true
    })

    builder.addCase(addItem.fulfilled, state => {
      state.isAddingItem = false
    })

    builder.addCase(addContactLenses.fulfilled, state => {
      state.isAddingItem = false
    })

    builder.addCase(updateItem.pending, state => {
      state.isAddingItem = true
    })

    builder.addCase(updateItem.fulfilled, state => {
      state.isAddingItem = false
    })

    builder.addCase(fetchShipInfo.fulfilled, (state, action) => {
      state.isFetchingShippingInfo = false
      state.shipInfos = action.payload
    })

    builder.addCase(fetchShipInfo.rejected, state => {
      state.isFetchingShippingInfo = false
      state.shipInfos = undefined
    })

    builder.addCase(fetchShipInfo.pending, state => {
      state.isFetchingShippingInfo = true
    })

    builder.addCase(fetchPayMethods.fulfilled, (state, { payload }) => {
      if (payload.usablePaymentInformation) {
        const cardsList: any[] = []
        for (const payment of payload.usablePaymentInformation) {
          cardsList.push(payment)
        }

        if (cardsList.length > 0) {
          state.payMethods = cardsList
        }
      }
    })

    builder.addCase(fetchPaypalPaymentStatus.pending, state => {
      state.paypalPaymentStatus = {
        ...state.paypalPaymentStatus,
        isSelected: true,
        loading: true,
        error: false,
      }
    })

    builder.addCase(fetchPaypalPaymentStatus.rejected, state => {
      state.paypalPaymentStatus = {
        ...state.paypalPaymentStatus,
        isSelected: false,
        loading: false,
        error: true,
      }
    })

    builder.addCase(fetchPaypalPaymentStatus.fulfilled, (state, { payload }) => {
      if (payload) {
        state.paypalPaymentStatus = {
          ...state.paypalPaymentStatus,
          loading: false,
          error: false,
          redirecturl: payload.redirecturl,
        }
      }
    })

    builder.addCase(fetchCart.rejected, (state, action) => {
      //! SORRY TEAM THIS CAST TO ANY WAS NECESSARY BECAUSE TYPES ARE JUST BROKEN
      //! HOPE TO FIX THEM ALL ASAP ☮️
      const error = action.error as any
      if (error?.response?.status && error.response.status === NOT_FOUND) {
        state.cart = undefined
        state.numItems = 0
        state.orderItems = []
      }
      state.isCheckoutDisabled = true
      state.isFetching = false
    })

    builder.addCase(fetchCart.pending, state => {
      state.isFetching = true
    })

    builder.addMatcher(
      isAnyOf(
        addItem.fulfilled,
        addFrame.fulfilled,
        addLens.fulfilled,
        addItem.rejected,
        addFrame.rejected,
        addLens.rejected
      ),
      state => {
        state.isAddingItem = false
      }
    )
    builder
      .addMatcher(orderApi.endpoints.updateOrderItem.matchPending, state => {
        state.isAddingItem = true
      })
      .addMatcher(orderApi.endpoints.updateOrderItem.matchFulfilled, state => {
        state.isAddingItem = false
      })
      .addMatcher(isAnyOf(addItem.pending, addFrame.pending, addLens.pending), state => {
        state.isAddingItem = true
      })
      .addMatcher(orderApi.endpoints.updateOrderItem.matchPending, state => {
        state.isAddingItem = true
      })
      .addMatcher(orderApi.endpoints.updateOrderItem.matchFulfilled, state => {
        state.isAddingItem = false
      })
      .addMatcher(orderApi.endpoints.getShippingInfo.matchFulfilled, (state, { payload }: PayloadAction<any>) => {
        state.isFetchingShippingInfo = false
        state.shipInfos = payload
      })
      .addMatcher(orderApi.endpoints.getShippingInfoForUpc.matchFulfilled, (state, { payload }: PayloadAction<any>) => {
        state.isFetchingShippingInfo = false
        state.shipInfos = payload
      })
      .addMatcher(isAnyOf(orderApi.endpoints.finalizePreCheckoutWithCybersource.matchPending), state => {
        state.isFinalizing = true
        state.isFinalizationFailed = false
      })
      .addMatcher(isAnyOf(orderApi.endpoints.finalizePreCheckoutWithCybersource.matchRejected), state => {
        state.isFinalizing = false
        state.isFinalizationFailed = true
      })
      .addMatcher(isAnyOf(orderApi.endpoints.finalizeCheckoutWithCybersource.matchPending), state => {
        state.isFinalizing = true
        state.isFinalizationFailed = false
      })
      .addMatcher(isAnyOf(orderApi.endpoints.finalizeCheckoutWithCybersource.matchFulfilled), state => {
        state.isFinalizing = false
        state.isFinalizationFailed = false
      })
      .addMatcher(isAnyOf(orderApi.endpoints.finalizeCheckoutWithCybersource.matchRejected), state => {
        state.isFinalizing = false
        state.isFinalizationFailed = true
      })
      .addMatcher(orderApi.endpoints.getCart.matchPending, (state, payload) => {
        const { meta } = payload
        const originalArgs = meta?.arg?.originalArgs
        state.isFetchingShippingInfo = true
        state.isFetching = originalArgs?.refetch ? !originalArgs?.refetch : true
      })
      .addMatcher(orderApi.endpoints.getCart.matchRejected, state => {
        state.isFetchingShippingInfo = false
        state.isFetching = false
      })
      .addMatcher(
        orderApi.endpoints.getCart.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            cart: Cart
            catentries?: IOrderSliceState['catentries']
            shippingInfo: IOrderSliceState['shipInfos']
            checkInventory: boolean
            fetchShippingInfo: boolean
            filterPrescriptionItemType?: 'cl' | 'rx'
            filterPrescriptionNeededItems?: boolean
            updateProducts?: boolean
          }>
        ) => {
          if (payload) {
            const { catentries, checkInventory, cart, shippingInfo, fetchShippingInfo, updateProducts } = payload
            if (updateProducts) {
              const orderItems = cart?.orderItem

              const orderItemsAttachments = getOrderItemsAttachments(orderItems, catentries)
              state.catentries = catentries
              state.cart = cart || null
              state.shipInfos = fetchShippingInfo ? shippingInfo : state.shipInfos
              let count = 0
              if (orderItems) {
                count = orderItems.reduce((c: number, item: any) => +item.quantity + c, 0)
              }
              state.numItems = count
              let newOrderItems: any[] = []
              let disableRecurringOrder = false
              let disableCheckout = false
              if (orderItems && orderItems.length > 0) {
                newOrderItems = []
                orderItems.forEach((item: IOrderItem) => {
                  if (checkInventory) {
                    if (
                      item.orderItemInventoryStatus !== INVENTORY_STATUS.available &&
                      item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
                    ) {
                      disableCheckout = true
                    }
                  }
                  const obj: IOrderItem | OrderItemWithRoxProps = {
                    ...item,
                    productAttributes: getAllProductAttributes(item.attributes),
                  }

                  const catentryId = item.xitem_display_catentry_id
                  if (catentries != null) {
                    const catentry = catentries[catentryId]
                    if (catentry !== undefined) {
                      if (catentry.name !== undefined) {
                        obj['name'] = catentry.name
                      }
                      if (catentry.thumbnail !== undefined) {
                        obj['thumbnail'] = catentry.thumbnail
                      }
                      if (catentry.attributes !== undefined) {
                        obj['attributes'] = catentry.attributes
                        catentry.productAttributes = getAllProductAttributes(catentry.attributes)
                      }
                      if (catentry.seo !== undefined) {
                        obj['seo'] = catentry.seo
                      }
                      if (catentry.disallowRecurringOrder !== undefined) {
                        obj['disallowRecurringOrder'] = catentry.disallowRecurringOrder
                        if (catentry.disallowRecurringOrder === '1') {
                          disableRecurringOrder = true
                        }
                      }
                      if (catentry.parentCatalogGroupID !== undefined) {
                        obj['parentCatalogGroupID'] = catentry.parentCatalogGroupID
                      }

                      if (catentry.x_offerpriceRx !== undefined) {
                        obj['x_offerpriceRx'] = catentry.x_offerpriceRx
                      }
                      if (catentry.x_offerDiscountpriceRx !== undefined) {
                        obj['x_offerDiscountpriceRx'] = catentry.x_offerDiscountpriceRx
                      }
                      if (catentry.x_priceBadge !== undefined) {
                        obj['x_priceBadge'] = catentry.x_priceBadge
                      }
                      obj.attachments = orderItemsAttachments[obj.productId]
                    }
                  }
                  obj.prescriptionUploaded = orderHasPrescriptionUploaded(item) || false
                  newOrderItems.push(obj)
                })

                state.isCheckoutDisabled = disableCheckout
                state.isRecurringOrderDisabled = disableRecurringOrder
              }
              state.orderItems = newOrderItems
              state.parsedOrderItems = getOrderItemsMap(state.orderItems)
              state.isRXProductsLimitExceeded =
                newOrderItems.filter(item => isRxProduct(item?.orderItemExtendAttribute)).length >
                RX_PRODUCTS_IN_CART_LIMIT
            }
            state.orderComplete = !!cart ? cart.orderStatus === ORDER_STATUS.Created : true

            if (state.isRecurringOrderDisabled && state.cart && state.cart.orderId) {
              if (localStorageUtil.get(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId)) {
                const recurringOrderInfo: any[] = [false, '0', null]
                localStorageUtil.set(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId, recurringOrderInfo)
              }
            }
          }
          state.isFetching = false
          state.isFetchingShippingInfo = false
        }
      )
      .addMatcher(orderApi.endpoints.findOrderdById.matchPending, state => {
        state.isFetchingOrderDetails = true
      })
      .addMatcher(
        orderApi.endpoints.findOrderdById.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            orderData: IOrderDetails
            catentries?: IOrderSliceState['catentries']
          }>
        ) => {
          if (payload) {
            const { orderData, catentries } = payload
            const { orderStatus } = orderData

            const updatedOrderItems = orderData.orderItem?.map(oi => {
              const p = orderData?.x_data?.productDetails?.find(({ id }) => oi?.productId === id)
              const rxServices = oi?.roxableServices
              const rxServicesDetails = orderData?.x_data?.productDetails.filter(({ id }) =>
                rxServices?.some(s => s.productId === id)
              )
              const catentryId = oi.xitem_display_catentry_id
              if (catentries != null) {
                const catentry = catentries[catentryId]
                if (catentry !== undefined) {
                  if (catentry.attributes !== undefined) {
                    oi['attributes'] = catentry.attributes
                    catentry.productAttributes = getAllProductAttributes(catentry.attributes)
                  }
                }
              }

              const obj: IOrderItem | OrderItemWithRoxProps = {
                ...oi,
                productAttributes: getAllProductAttributes(oi.attributes),
              }

              if (catentries != null) {
                const catentry = catentries[catentryId]
                if (catentry !== undefined) {
                  if (catentry.seo !== undefined) {
                    obj['seo'] = catentry.seo
                  }
                }
              }

              const newOrderItem: IOrderItem = {
                ...obj,
                attachments: p?.attachments || [],
                attributes: p?.attributes || [],
                productAttributes: p ? getAllProductAttributes(p.attributes) : ({} as IProductAttributes),
                roxableServices: rxServicesDetails,
                orderItemPrice: obj?.orderItemPrice || '',
                currency: obj?.currency || '',
                productId: obj?.productId || '',
                x_offerpriceRx: p?.x_offerpriceRx,
                prescriptionUploaded: orderHasPrescriptionUploaded(obj) || false,
              }
              return newOrderItem
            })
            state.orderItems = updatedOrderItems

            const orderPaymentMethodId = orderData?.paymentInstruction?.find(pi => !!pi)?.payMethodId

            state.orderDetails = {
              ...orderData,
              orderItem: updatedOrderItems,
              orderComplete: isOrderComplete(orderStatus, orderPaymentMethodId),
              parsedOrderItems: getOrderItemsMap(updatedOrderItems),
              prescriptionPeriodExpired: orderHasPrescriptionPeriodExcedeed(orderData, 10),
            }
            state.orderComplete = isOrderComplete(orderStatus, orderPaymentMethodId)
            state.isFetchingOrderDetails = false

            if (catentries) {
              state.catentries = catentries
            }
          }
        }
      )
      .addMatcher(orderApi.endpoints.findOrderdById.matchRejected, state => {
        state.isFetchingOrderDetails = false
      })
      .addMatcher(
        orderApi.endpoints.getShippingPrice.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            shipList?: ShippingChargesWithoutPromotions[]
          }>
        ) => {
          if (
            payload?.shipList &&
            payload.shipList.length &&
            state.shipInfos?.usableShippingMode[0].dedicatedShippingMode
          ) {
            const data: any = payload?.shipList.filter(shippingMode => {
              const filterdShipingCharges = state.shipInfos?.usableShippingMode[0].dedicatedShippingMode.find(
                shipInfo => shipInfo.shipModeId === shippingMode.shipModeId
              )
              return !!filterdShipingCharges
            })
            // const data = {
            //   [ShippingsMethodsEnum.Green]: payload.shipList.find(
            //     (ship) => ship.shipModeName === ShippingsMethodsEnum.Green
            //   ),
            //   [ShippingsMethodsEnum.Standard]: payload.shipList.find(
            //     (ship) => ship.shipModeName === ShippingsMethodsEnum.Standard
            //   ),
            // }
            state.cartShippingCharges.shippingCharges = data
          }
        }
      )
      .addMatcher(orderApi.endpoints.getShippingPrice.matchRejected, (state, { payload }) => {
        state.cartShippingCharges.error = payload
      })
  },
})

export const {
  updateCart,
  resetCart,
  updateIsCartThresholdReachedFlag,
  toggleCartOrderComplete,
  toggleShippingAsBilling,
  togglePaypal,
  setBillingFormValidStatus,
  setPaymentMethod,
  setShippingFormValidStatus,
  setCreditCardFormValidStatus,
  setIsFinalizationFailed,
  updateCreditCardData,
  setAmazonCheckoutSessionId,
} = orderSlice.actions

export default orderSlice.reducer

import Typography from '@mui/material/Typography'
import Link from 'next/link'

export const componentMap: { [key: string]: React.ElementType } = {
  h1: Typography,
  h2: Typography,
  h3: Typography,
  h4: Typography,
  h5: Typography,
  h6: Typography,
  p: Typography,
  a: Link,
  ul: 'ul',
  ol: 'ol',
  li: 'li',
  span: 'span',
  div: 'div',
  section: 'section',
  article: 'article',
  header: 'header',
  footer: 'footer',
  nav: 'nav',
  aside: 'aside',
  main: 'main',
  br: 'br',
  strong: 'strong',
  em: 'em',
  i: 'i',
  b: 'b',
  u: 'u',
  s: 's',
  mark: 'mark',
  abbr: 'abbr',
  cite: 'cite',
  q: 'q',
  small: 'small',
  sub: 'sub',
  sup: 'sup',
  time: 'time',
  var: 'var',
  code: 'code',
  pre: 'pre',
  blockquote: 'blockquote',
  img: 'img',
  figure: 'figure',
  figcaption: 'figcaption',
  iframe: 'iframe',
  video: 'video',
  audio: 'audio',
  source: 'source',
  track: 'track',
  canvas: 'canvas',
  map: 'map',
  area: 'area',
  table: 'table',
  caption: 'caption',
  colgroup: 'colgroup',
  col: 'col',
  thead: 'thead',
  tbody: 'tbody',
  tfoot: 'tfoot',
  tr: 'tr',
  th: 'th',
  td: 'td',
  form: 'form',
  label: 'label',
  input: 'input',
  button: 'button',
  select: 'select',
  datalist: 'datalist',
  optgroup: 'optgroup',
  option: 'option',
  textarea: 'textarea',
  fieldset: 'fieldset',
  legend: 'legend',
  details: 'details',
  summary: 'summary',
  dialog: 'dialog',
  script: 'script',
  noscript: 'noscript',
  template: 'template',
  slot: 'slot',
  html: 'html',
  head: 'head',
  title: 'title',
  base: 'base',
  meta: 'meta',
  link: 'link',
  style: 'style',
  body: 'body',
  hr: 'hr',
}

import { useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'

import {
  SearchInput,
  SearchInputIconWrapper,
  SearchInputWrapper,
  StyledDivider,
  StyledFilterNotes,
  StyledFiltersContainer,
  StyledFiltersWrapper,
} from '../PlpFilter.style'
import { SearchIcon } from '@components/UI/Icons'
import { useRefinementList } from 'react-instantsearch'
import { useSite } from '@foundation/hooks/useSite'
import { SizeAdvisorFilter } from './SizeAdvisorFilter'
import FacetFilterPill from './FacetFilterPill'
import { Typography } from '@mui/material'

const FRAME_SIZE_DISPLAY_RANGE = 'FRAME_SIZE_DISPLAY_RANGE'
const FRAME_TYPE = 'FRAME_TYPE'
const GEOFIT = 'GEOFIT'
interface FilterListAlgoliaProps {
  facet: string
  onFacetChange: (selection: string, label: string, parent: string, setFacet: (string) => void) => void
  facetValues?: string[]
  sizeAdvisorSwitcherProps: {
    toggleHingesFacet?: () => void
    enabled: boolean
  }
  hasSuggested: boolean
}

const FilterListAlgolia = ({ facet, onFacetChange, facetValues, sizeAdvisorSwitcherProps }: FilterListAlgoliaProps) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const frameGeniusStoreconfs = mySite.xStoreCfg.frameGenius
  const isSizeAdvisorEnabled = frameGeniusStoreconfs.isSizeAdvisorEnabled

  const facetName = facet?.split('.')[1] ?? 'DISCOUNT'

  const filterRef = useRef<HTMLDivElement>(null)

  const { items, searchForItems } = useRefinementList({
    attribute: facet,
    operator: 'or',
    sortBy: ['count'],
    limit: facetValues?.length,
  })

  const [searchTerm, setSearchTerm] = useState<string>('')

  const onSearchInputChange = e => {
    const { value } = e.target
    setSearchTerm(value)
    searchForItems(value)
  }

  const renderFacetValues = (facetValues: string[], facetName: string, facet: string, searchTerm: string) => {
    return (
      <FacetFilterPill
        facetValues={facetValues}
        facetName={facetName}
        facet={facet}
        onFacetChange={onFacetChange}
        searchTerm={searchTerm}
      />
    )
  }

  return items && items.length ? (
    <>
      <StyledFiltersWrapper>
        <Typography variant="button">{t(`ProductFilter.Labels.${facetName}`)}</Typography>
        <StyledFilterNotes ref={filterRef}>
          {facetName === FRAME_SIZE_DISPLAY_RANGE || facetName === GEOFIT || facetName === FRAME_TYPE
            ? t(`ProductFilter.Notes.${facetName}`)
            : ''}
        </StyledFilterNotes>
        {facetName === 'BRAND' && (
          <SearchInputWrapper>
            <SearchInput
              type={'text'}
              placeholder={t('ProductFilter.Actions.Search')}
              value={searchTerm}
              onChange={onSearchInputChange}
            />
            <SearchInputIconWrapper>
              <SearchIcon />
            </SearchInputIconWrapper>
          </SearchInputWrapper>
        )}

        <StyledFiltersContainer>
          {facetValues && facetValues.length > 0 && renderFacetValues(facetValues, facetName, facet, searchTerm)}
          {facetName === FRAME_SIZE_DISPLAY_RANGE && isSizeAdvisorEnabled && (
            <SizeAdvisorFilter sizeAdvisorSwitcherProps={sizeAdvisorSwitcherProps} />
          )}
        </StyledFiltersContainer>
      </StyledFiltersWrapper>
      <StyledDivider />
    </>
  ) : null
}

export default FilterListAlgolia

import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { renderLink } from './renderLink'
import { LANGUAGE_CODE_MAP } from '@constants/common'
import { StyledIconTick3 } from './TopReasons.style'
import { IPlacement, IPlacementItem, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { TickMarkIcon } from '@components/UI/Icons/check'
import { IconCalendar, IconDelivery, IconMyOrders } from '@components/UI/Icons/VD/General'
import { TopReasonsData } from './TopReasons'
import HtmlRenderer from '@components/UI/HtmlRender'
import type { HtmlRendererProps } from '@components/UI/HtmlRender/HtmlRenderer'

export const renderTop3ReasonsLink = (reasonKey: string) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const prefix = '/'.concat(langCode)

  switch (reasonKey) {
    case 'reason1':
      if (
        [LANGUAGE_CODE_MAP.it_it, LANGUAGE_CODE_MAP.nl_nl, LANGUAGE_CODE_MAP.nl_be, LANGUAGE_CODE_MAP.fr_be].includes(
          langCode
        )
      ) {
        return [renderLink(prefix.concat(t('TopReasons.Reason1.link.spedizione')), 'SpedizioneLink', 'styled')]
      }

      return [renderLink(prefix.concat(t('TopReasons.Reason1.link.available')), 'AvailableLink', 'styled')]
    case 'reason2':
      if (
        [LANGUAGE_CODE_MAP.it_it, LANGUAGE_CODE_MAP.nl_nl, LANGUAGE_CODE_MAP.nl_be, LANGUAGE_CODE_MAP.fr_be].includes(
          langCode
        )
      ) {
        return [renderLink(prefix.concat(t('TopReasons.Reason2.link.contatti')), 'ContattiLink', 'styled')]
      }

      return []
    case 'reason3':
      if (
        [LANGUAGE_CODE_MAP.it_it, LANGUAGE_CODE_MAP.nl_nl, LANGUAGE_CODE_MAP.nl_be, LANGUAGE_CODE_MAP.fr_be].includes(
          langCode
        )
      ) {
        return [renderLink(prefix.concat(t('TopReasons.Reason3.link.detrazione')), 'DetrazioneLink', 'styled')]
      }

      return [
        renderLink(prefix.concat(t('TopReasons.Reason3.link.Price match Promise')), 'PriceMatchPromiseLink', 'link'),
        renderLink(t('TopReasons.Reason3.link.020 7768 5000'), 'TelLink', 'styled'),
      ]
    default:
      return []
  }
}
/**
 * Constructs top reasons data from a collection.
 *
 * @param {ICMCollection} collection - Source collection with title and teasables.
 * @param {function} [renderHtml] - Override HTML render of ILXTeaser content.
 * @param {HtmlRendererProps} [rendererProps] - Specify/override HTML renderer props.
 * @returns {TopReasonsData} - Processed collection title and item data.
 *
 * @description
 * Filters and maps teasable items to objects with an icon and content/html content.
 * Uses `getTopReasonsIconByName` for icons;
 * Renders content via `renderHtml` for manual rendering or the default `HtmlRenderer` for safety.
 */
export const buildTopReasonsData = (
  collection: ICMCollection,
  renderHtml?: (item: ILXTeaser, renderProps: HtmlRendererProps) => React.ReactNode,
  rendererProps?: HtmlRendererProps
): TopReasonsData => {
  const defaultRenderProps: HtmlRendererProps = {
    variant: 'body2',
    dataNamePrefix: 'topReason',
  }

  return {
    title: collection.collectionTitle,
    items: collection.teasableItems.filter(isLXTeaser).map(item => {
      // No content to render
      if (!item.teaserText2) {
        return {
          id: item.id,
          icon: getTopReasonsIconByName(item.teaserIcon),
          content: null,
        }
      }

      const renderProps = {
        ...defaultRenderProps,
        ...rendererProps,
        htmlString: item.teaserText2,
      }

      return {
        id: item.id,
        icon: getTopReasonsIconByName(item.teaserIcon),
        content: renderHtml ? renderHtml(item, renderProps) : <HtmlRenderer {...renderProps} />,
      }
    }),
  }
}

const ICONS_MAP = {
  'icon--check': <StyledIconTick3 />,
  'vde-icon--tick-two': <TickMarkIcon />,
  'vde-icon--delivery-reduced': <IconDelivery />,
  'vde-icon--my-orders': <IconMyOrders />,
  'vde-icon--calendar': <IconCalendar />,
}

export const getTopReasonsIconByName = (name: string): JSX.Element | null => ICONS_MAP[name] || null

/**
 * Fetches the top reasons CM collection by placement names.
 * @param {IPlacement<IPlacementItem>[]} placements - Array of placement objects to search.
 * @param {string|string[]} placementNames - Single or multiple placement names to match.
 * @returns {ICMCollection|null} - Returns matched ICMCollection or null if not found.
 *
 * @description
 * Searches placements for a match with placementNames and finds the first ICMCollection that matches the isCMCollection predicate.
 */
export const getTopReasonsCMCollection = (
  placements: IPlacement<IPlacementItem>[],
  placementNames: string | string[]
): ICMCollection | null => {
  const placementNamesArray = Array.isArray(placementNames) ? placementNames : [placementNames]
  const topReasonsPlacement = placements?.find(placement => placementNamesArray.includes(placement.name))
  const topReasonsCMCollection = topReasonsPlacement?.items.find(isCMCollection)

  return topReasonsCMCollection ?? null
}

import * as ROUTES from '../../constants/routes'

export const useCheckoutPaths = () => {
  return {
    checkout: `/${ROUTES.CHECKOUT}`,
    cart: `/${ROUTES.CART}`,
    shipping: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.SHIPPING}`,

    review: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.REVIEW}`,

    payment: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.PAYMENT}`,

    'order-confirmation': `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`,

    uploadPrescription: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.UPLOAD_PRESCRIPTION}`,
  }
}

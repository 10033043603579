import React from 'react'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '../constants/product'
import { Maybe } from './common'
import { IOrderItem, ShippingMode } from './order'

import { BaseHit, Hit } from 'instantsearch.js'
import { IPlacement, IPlacementItem } from './cmsPlacement/Placement'
import { TPrice } from '@components/common/UI/ProductPrice/index.types'
import { MEASURES_UNITS } from '@utils/productAttributes'

export type BooleanString = 'true' | 'false'

export type ProductSeo = {
  href?: Maybe<string>
}

export const PRODUCT_PRICE_USAGE_NAMES = {
  CURRENT: 'Offer', // current price of product, can be initial or discounted
  INITIAL: 'Display', // initial price of product, before applied discount
} as const

export type ProductImage = {
  name: string
  sequence?: string
}

export type Attachment = {
  identifier: string
  usage: string
  name: string
  rule: string
  url: string
  sequence: string
  attachmentAssetPathRaw: string
}

export interface IProduct {
  attachments: Attachment[]
  images: {
    sequence: string
    name: string
  }[]
  buyable: boolean
  cluster?: IProduct[]
  hasSingleSKU: boolean
  id?: string
  items?: IProduct[]
  longDescription?: string
  merchandisingAssociations?: AssociatedProduct[]
  catalogEntryTypeCode?: string
  name: string
  parentCatalogGroupID: string | string[]
  parentCatalogEntryID?: string
  partNumber: string
  x_price: {
    [key: string]: TPrice
  }
  seo?: ProductSeo
  shortDescription?: string
  numberOfSKUs?: number
  sKUs?: IProduct[]
  storeID: string
  type?: string
  uniqueID?: string
  x_offerpriceRx?: string
  resourceId?: string
  manufacturer?: string
  'relationship.item.id'?: string
  'relationship.item.sequence'?: string
  _ignored?: string
  quantity?: string
  orderItemExtendAttribute: Attribute[]
  associationType?: string
  productAttributes: IProductAttributes
}

export interface IServerProduct extends IProduct {
  attributes: IProductAttribute[]
  prices: Price[]
  cluster: IServerProduct[]
  items: IServerProduct[]
  sKUs?: IServerProduct[]
}

export interface IProductAttribute {
  identifier: string
  'attribute.natural': string
  usage: string
  values: [
    {
      sequence: string
      identifier: string
      value: string
      uniqueID: string
      unitOfMeasure?: string
      unitID?: string
    },
  ]
  displayable: BooleanString
  merchandisable: BooleanString
  searchable: BooleanString
  sequence: string
  storeDisplay?: BooleanString
  name: string
  facetable: BooleanString
  comparable: BooleanString
  key?: string
  uniqueID: string
  swatchable: BooleanString
}

export interface ProductAnalyticsRX extends IProduct {
  rxPrice?: number
  isFreeGift?: boolean
}

export type ProductSoldOutStatus = (typeof PRODUCT_SOLDOUT_STATUS)[keyof typeof PRODUCT_SOLDOUT_STATUS]

export type DefaultProductCtaStatus = Extract<ProductSoldOutStatus, typeof PRODUCT_SOLDOUT_STATUS.NONE>
export type SoldOutProductCtaStatus = Exclude<ProductSoldOutStatus, typeof PRODUCT_SOLDOUT_STATUS.NONE>

export interface AssociatedProduct extends IServerProduct {
  associationType: string
}

export interface ClusterProduct extends IProduct {
  items?: IProduct[] // sKUs?
}

export interface ClusteredProduct {
  // NO PRODUCT
  cluster: IProduct[]
}

export interface Price {
  usage: string
  contractId: string
  description: string
  currency: string
  value: string
}

export interface IProductAttributes {
  [key: string]: string
}

export type ProductType = (typeof PRODUCT_TYPES_MAP)[keyof typeof PRODUCT_TYPES_MAP]

export interface ProductRightColumnProps {
  pdpData: IProduct
  type: string | null
  partNumber: IProduct['partNumber']
  currentProduct: IProduct
  productItems: IProduct['items']
  cluster: IProduct['cluster']
  ctaRef: React.RefObject<HTMLDivElement>
  stickyBarCtaRef: React.RefObject<HTMLDivElement>
  onClusterProductClick?: (p: IProduct) => void
  soldOutStatus: ProductSoldOutStatus
  addToCartButtonFail: boolean
  productInCart?: IOrderItem
  productQuantity?: string[]
  setProductQuantity: React.Dispatch<string[]>
  attachments?: Attachment[]
  attributes?: IProductAttribute[]
  placements?: IPlacement<IPlacementItem>[]
  isSoldout?: boolean
  isComingBackSoon?: boolean
  isRoxable?: boolean
  onlyFewPieces: boolean
  shipInfos: ShippingMode | undefined
}

export interface EyeContanctLensOption {
  text: string
  value: string | null
  index: number | null
}
export interface EyeClFieldConfig {
  id: EyeContactLensAttribute
  select: boolean
  label: string
  options: EyeContanctLensOption[]
  defaultValue: string
  active?: boolean
  visible?: boolean
  required?: boolean
  multifield?: boolean
}

export type EyeClFieldConfigMap = Record<string, EyeClFieldConfig[]>

export type EyeContactLensAttribute =
  | 'x_spherePower'
  | 'x_baseCurve'
  | 'x_spherePower'
  | 'x_diameter'
  | 'x_axis'
  | 'x_color'
  | 'x_addition'
  | 'x_dominance'
  | 'x_cylinder'
  | 'quantity'

export interface EyeContanctLensStatusData {
  enabled?: boolean
  errors?: string[]
  dirtyFields?: string[]
  emptyFields?: string[]
  valid?: boolean
  touched?: boolean
}

export type Facet = {
  name: string
  entry: any[]
}

export interface ContactLensData {
  x_productId?: string
  x_eye?: LensesEyesAttributes
  x_baseCurve?: string
  x_spherePower?: string
  x_diameter?: string
  x_axis?: string
  x_cylinder?: string
  x_dominance?: string
  x_addition?: string
  x_color?: string
  quantity?: string
  fieldsStatus?: EyeContanctLensStatusData | null
}

export type ContactLensPayload = Omit<ContactLensData, 'fieldsStatus'>

export type ContactLensesData = Record<string, ContactLensData>

export interface SupplyData {
  quantity?: string
  discountAmount?: string
  originalBoxPrice?: string
  discountedBoxPrice?: string
  timePeriod?: string
}

export enum ProductTypesEnum {
  ContactLensesAccessories = 'Contact Lenses Accessories',
  Accessories = 'Accessories',
  ContactLenses = 'Contact Lenses',
}

export type Attribute = {
  attributeName: string
  attributeType: string
  attributeValue: string
}

export type LensesEyesAttributes = 'RCON' | 'LCON'

export interface EyeContactLensOverride {
  attribute: EyeContactLensAttribute
  value: string
}

export type EyeContactLensOverrideMap = Record<'left' | 'right', EyeContactLensOverride[]>

export type EyeContactLensAttributeMapping = {
  parsedValue: number
  orginalValue: string
}

export interface Size {
  size: string
  frameSize: string
  bridgeWidth: string
  sizeOrder: Sizes
  uniqueID: string
  hingeDistance: string
}

export enum Sizes {
  XXS,
  XS,
  S,
  M,
  L,
  XL,
  XXL,
}

export type PictureType =
  | 'quarter'
  | 'front'
  | 'lateral'
  | 'closed front'
  | 'back'
  | 'alternative'
  | 'folding'
  | 'folding group'
  | 'group'
  | 'OnModel'
  | 'adv'

export type ProductImageUsage = 'PDP' | 'PLP' | 'Thumbnail'

export const CrossOriginAnonymous = 'anonymous' as React.MediaHTMLAttributes<HTMLImageElement>['crossOrigin']

export interface PriceModel {
  price: string
  currency: string
}

export type IAlgoliaHit = Hit<{
  x_groupkey: string
  price?: TPrice | null
  prices: {
    [key: string]: TPrice
  }
  partnumberId: string
  parentProductId: string
  productId: string
  cluster?: IAlgoliaHit[]
  massoc?: unknown
  category_ids?: unknown
  categories_tree?: unknown
  categories_tree_translated?: unknown
}> &
  Omit<IProduct, 'price' | 'cluster'>

export type IAlgoliaBaseHit = BaseHit

export type MeasureUnit = (typeof MEASURES_UNITS)[keyof typeof MEASURES_UNITS]

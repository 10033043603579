import { Typography } from '@mui/material'
import { styled, css } from '@mui/material/styles'

export const StyledPriceWrapper = styled(Typography, {
  name: 'ProductTilePrice',
  slot: 'PriceWrapper',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
}))

export const StyledRrpPrice = styled('span', {
  name: 'ProductTilePrice',
  slot: 'RrpPrice',
  shouldForwardProp: prop => prop !== 'isOnOffer',
})<{ isOnOffer?: boolean }>`
  ${({ theme, isOnOffer }) =>
    isOnOffer &&
    css`
      text-decoration: line-through;
      color: ${theme.palette.error.main};
    `}
`

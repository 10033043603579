import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgYoutube = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <g clip-path="url(#clip0_60832_67714)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.14985 7.8744C4.35085 7.1436 4.94425 6.5508 5.66425 6.3498C7.00885 6 12.3686 6 12.3686 6C12.3686 6 17.738 6 19.0832 6.3708C19.814 6.5616 20.396 7.1436 20.5874 7.875C20.9582 9.2202 20.9474 12.0162 20.9474 12.0162C20.9474 12.0162 20.9474 14.802 20.5874 16.1364C20.3966 16.878 19.814 17.4498 19.0832 17.6508C17.7386 18.0006 12.3686 18.0006 12.3686 18.0006C12.3686 18.0006 7.02025 18.0006 5.66425 17.6406C4.93345 17.439 4.35085 16.857 4.14985 16.1262C3.80005 14.802 3.80005 12.006 3.80005 12.006C3.80005 12.006 3.80005 9.2196 4.14985 7.8744ZM15.1221 12.0054L10.6635 9.4314V14.5686L15.1221 12.0054Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_60832_67714">
          <rect width="17.4" height="12" fill="white" transform="translate(3.80005 6)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
export default SvgYoutube

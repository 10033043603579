import { addBusinessDaysToDate, addBusinessWeeksToDate, formatDate, parseDate } from '@utils/dateUtils'
import { CommerceEnvironment } from '../constants/common'
import { DATE_DAYJS_LOCALE_MAP, DATE_FORMAT_PATTERN, ISO_DATE_FORMAT } from '../constants/date'
import { initialStore } from '../redux/store'

type DatePattern = keyof typeof DATE_FORMAT_PATTERN | string

class DateService {
  isValid(date: Date | string | number): boolean {
    return date instanceof Date && !isNaN(date.getTime())
  }

  addBusinessDays(dateArg: Date | string | number, daysToAdd: number): Date | null {
    let date: number | Date

    if (this.isValid(dateArg)) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)

      if (!this.isValid(date)) {
        return null
      }
    }

    date = addBusinessDaysToDate(daysToAdd, date)

    return date
  }

  addBusinessWeeks(dateArg: Date | string | number, weeksToAdd: number): Date | null {
    let date: number | Date

    if (this.isValid(dateArg)) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)

      if (!this.isValid(date)) {
        return null
      }
    }

    date = addBusinessWeeksToDate(weeksToAdd, date)

    return date
  }

  format(
    dateArg: Date | string | number,
    pattern: DatePattern = DATE_FORMAT_PATTERN['dd/MM/yyyy'],
    optionsArg?: {
      locale?: Locale
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
      firstWeekContainsDate?: number
      useAdditionalWeekYearTokens?: boolean
      useAdditionalDayOfYearTokens?: boolean
      strictPattern?: boolean
    }
  ): string {
    let date: number | Date

    if (this.isValid(dateArg)) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)

      if (!this.isValid(date)) {
        return ''
      }
    }

    /** Store is used to automatically set locale to format method from date-fns,
     *  instead of passing a value from components on every DateService.format call */
    const localeCode = initialStore.getState().site.currentSite?.locale || CommerceEnvironment.defaultLang
    const localeObject = DATE_DAYJS_LOCALE_MAP[localeCode] || DATE_DAYJS_LOCALE_MAP.default

    const options = optionsArg ? { locale: localeObject, ...optionsArg } : { locale: localeObject }

    return formatDate(date, pattern, options)
  }

  /**
   * @returns true if date string is in ISO format yyyy-MM-dd (ignores time portion)
   */
  isISODate(date: string | undefined): boolean {
    if (!date) return false

    return /^(19|20)\d{2}\-(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])$/.test(date.substring(0, ISO_DATE_FORMAT.length))
  }

  /**
   * Convert ISO date string (yyyy-MM-dd) to a new format specified by pattern.
   * If date is not ISO format returns original dateString.
   */
  convertISODate(dateString: string, pattern: DatePattern | string = DATE_FORMAT_PATTERN['MM/dd/yyyy']): string {
    const dateToParse = dateString?.substring(0, ISO_DATE_FORMAT.length)
    if (!this.isISODate(dateToParse)) {
      return dateString
    }

    const date = parseDate(dateToParse, ISO_DATE_FORMAT, new Date(1970, 0, 1, 0, 0, 0))
    if (this.isValid(date)) {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    }
    return formatDate(date, pattern)
  }

  /**
   * Get number of days elapsed from the given date
   * @param dateArg date to check
   * @returns number of days elapsed from the given date or -1 if input is not a valid date
   */
  getElapsedDays(dateArg: Date | string | number | undefined): number {
    let date: Date | number
    if (this.isValid(dateArg || '')) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)
      if (!this.isValid(date)) {
        return -1
      }
    }
    const differenceInTime = new Date().getTime() - date.getTime()
    const days = differenceInTime / (1000 * 3600 * 24)
    return days
  }

  getMonthAndYear(date?: string): [number, number] {
    const genDate = date ? new Date(date) : new Date()
    const month = genDate.getMonth() + 1
    const year = genDate.getFullYear()

    return [month, year]
  }

  fromInvalidDateToValidDate(date: string, pattern: string): string {
    switch (pattern) {
      case 'dd-MM-yyyy':
        const splitted = date.indexOf('/') > -1 ? date.replaceAll('/', '-').split('-') : date.split('-')
        return `${splitted[2]}-${splitted[1]}-${splitted[0]}`
      default:
        return date
    }
  }

  getExtendedDate(date: string, pattern: string, langCode: string): string | null {
    let d = date

    if (!this.isValid(date)) {
      d = this.fromInvalidDateToValidDate(date, pattern)
    }
    const dateFormatter = new Intl.DateTimeFormat(langCode, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })

    return dateFormatter.format(new Date(d))
  }

  convertPriceDate(dateString: string | undefined): string {
    if (!dateString) return ''
    let newDate: string = dateString.replaceAll('-', '/')
    let newDateList: string[] = newDate.split('.')
    if (newDateList.length > 1) newDateList[1] = newDateList[1].split(' ')[1]
    return newDateList.join(' ')
  }

  isSame(originDate: string, targetDate: string, pattern: string): boolean {
    let date1 = originDate
    let date2 = targetDate

    if (!this.isValid(originDate)) {
      date1 = this.fromInvalidDateToValidDate(originDate, pattern)
    }

    if (!this.isValid(targetDate)) {
      date2 = this.fromInvalidDateToValidDate(targetDate, pattern)
    }

    return date1.valueOf() !== date2.valueOf()
  }

  lessThan(originDate: string, targetDate: string, pattern: string): boolean {
    let date1 = originDate
    let date2 = targetDate

    if (!this.isValid(originDate)) {
      date1 = this.fromInvalidDateToValidDate(originDate, pattern)
    }

    if (!this.isValid(targetDate)) {
      date2 = this.fromInvalidDateToValidDate(targetDate, pattern)
    }

    return date1.valueOf() < date2.valueOf()
  }

  /**
   * Set a date object with dynamic time (current time)
   * @param dateString The input date string
   * @param pattern The date pattern
   * @returns The date with the current time set in ISO string format
   */
  generateISODateWithCurrentTime(
    dateString: string,
    pattern: DatePattern | string = DATE_FORMAT_PATTERN['dd-MM-yyyy']
  ): string {
    const parsedDate = dateService.fromInvalidDateToValidDate(dateString, pattern)
    const dateObject = new Date(parsedDate)
    const currentTime = new Date()

    dateObject.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds(), 0)
    const isoDateString = dateObject.toISOString()

    return isoDateString
  }
}

const dateService = new DateService()
export default dateService
